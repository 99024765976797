import {animate, animateChild, group, query, state, style, transition, trigger} from '@angular/animations';

export function HintMessageTransition() {
    return trigger('continueButtonAnimate', [
        state('noHintMessage', style({
            height: '0px'
        })),
        state('withHintMessage', style({
            // this should be a wildcard (*) but for some reason angular has a bug, if it stops working return back to wildcard
            height: '!'
        })),
        transition('noHintMessage <=> withHintMessage', [
            group([
                query('@slideDown', [
                    animateChild()
                ]),
                animate(150)
            ])]),
    ]);
}


export function HintMessageTextTransition() {
    return trigger('slideDown', [
        state('void', style({
            opacity: 1
        })),
        transition('void => *', [
            style({
                opacity: 0
            }),
            animate('150ms')])
    ]);
}


