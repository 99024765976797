import {Component, Input} from '@angular/core';
import {HintMessageTextTransition} from '@core/animations/hint-message-slide-down';


type HintMessageType = 'warning' | 'info' | 'alert';

@Component({
    selector: 'app-hint-message',
    templateUrl: './hint-message.component.html',
    styleUrls: ['./hint-message.component.scss'],
    animations: [
        HintMessageTextTransition()
    ]
})
export class HintMessageComponent {

    @Input() messageType: HintMessageType;


}
