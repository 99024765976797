import {Injectable} from '@angular/core';
import {AccountDetailsResponse} from '@core/model/account-details-response';
import {RetentionsServiceEligibilityResponse} from '@core/model/retentions-service-eligibility-response';
import {Config} from '@core/model/config-response';
import {RetentionsServiceDealsResponse} from '@core/model/retentions-service-deals-response';
import {RetentionsPageObject} from '@components/views/retentions/retentions-page-object';
import {BrowserInfo} from '@core/model/browser-info';
import {RetentionsServiceDealLoansResponse} from '@core/model/retentions-service-deal-loans-response';
import {CookieService} from '@core/services/backend/cookie/cookie.service';
import { AccountValidationResponse } from './accountValidationResponse';
import { CustomerDetailsAuthenticationResponse } from './customerDetailsAuthenticationResponse';

@Injectable({
    providedIn: 'root'
})
export class CustomerJourney {

    retentionsPageObject: RetentionsPageObject;
    browserInfo: BrowserInfo;
    cookieService = new CookieService();

    private _accountDetailsResponse: AccountDetailsResponse;
    private _retentionsServiceResponse: RetentionsServiceEligibilityResponse;
    private _retentionsDealsResponse: RetentionsServiceDealsResponse;
    private _dealLoansResponse: RetentionsServiceDealLoansResponse;
    private _config: Config;
    private _minimumBalanceForSwitching: number;
    private _sortCodeAndAccountNumber: any;
    private _fullAccountNumber: any;
    private _ldapUid: string;
    private _selectedMultiLoan: boolean;
    private _selectedLoansTerm: number;
    private _retentionsFeeToggle: boolean;
    private _differentialRate: number;
    private _accountValidationResponse: AccountValidationResponse;
    private _customerDetailsAuthenticationResponse: CustomerDetailsAuthenticationResponse;
    private _hasProductFee: boolean;
    private _productsAvailableWithSteppedErcs: boolean;

    constructor() {
        this._accountDetailsResponse = new AccountDetailsResponse();
        this._retentionsServiceResponse = new RetentionsServiceEligibilityResponse();
        this._retentionsDealsResponse = new RetentionsServiceDealsResponse();
        this.retentionsPageObject = new RetentionsPageObject();
        this._config = new Config();
        this._selectedLoansTerm = 0;
        this.browserInfo = new BrowserInfo(this.cookieService);
        this._differentialRate = 0;
        this._accountValidationResponse = new AccountValidationResponse();
        this._customerDetailsAuthenticationResponse = new CustomerDetailsAuthenticationResponse();
        this._productsAvailableWithSteppedErcs = false;
    }

    public get hasProductFee(): boolean {
        return this._hasProductFee;
    }

    public set hasProductFee(value: boolean) {
        this._hasProductFee = value;
    }

    public get retentionsFeeToggle(): boolean {
        return this._retentionsFeeToggle;
    }

    public set retentionsFeeToggle(value: boolean) {
        this._retentionsFeeToggle = value;
    }

    public get sortCodeAndAccountNumber(): string {
        return this._sortCodeAndAccountNumber;
    }

    public set sortCodeAndAccountNumber(value: string) {
        this._sortCodeAndAccountNumber = value;
    }

    public get fullAccountNumber(): string {
        return this._fullAccountNumber;
    }

    public set fullAccountNumber(value: string) {
        this._fullAccountNumber = value;
    }

    public get selectedMultiLoan(): boolean {
        return this._selectedMultiLoan;
    }

    public set selectedMultiLoan(value: boolean) {
        this._selectedMultiLoan = value;
    }

    public get selectedLoansTerm(): number {
        return this._selectedLoansTerm;
    }

    public set selectedLoansTerm(value: number) {
        this._selectedLoansTerm = value;
    }

    public set minimumBalanceForSwitching(minimumBalanceForSwitching: number) {
        this._minimumBalanceForSwitching = minimumBalanceForSwitching;
    }

    public get minimumBalanceForSwitching() {
        return this._minimumBalanceForSwitching;
    }

    public set differentialRate(differentialRate: number) {
        this._differentialRate = differentialRate;
    }

    public get differentialRate() {
        return this._differentialRate;
    }


    public set accountDetailsResponse(accountDetailsResponse: AccountDetailsResponse) {
        this._accountDetailsResponse = accountDetailsResponse;
    }

    public get accountDetailsResponse(): AccountDetailsResponse {
        return this._accountDetailsResponse;
    }
    public get productsAvailableWithSteppedErcs(): boolean {
        return this._productsAvailableWithSteppedErcs;
    }

    public set productsAvailableWithSteppedErcs(value: boolean) {
        this._productsAvailableWithSteppedErcs = value;
    }

    get retentionsServiceResponse(): RetentionsServiceEligibilityResponse {
        return this._retentionsServiceResponse;
    }

    set retentionsServiceResponse(value: RetentionsServiceEligibilityResponse) {
        this._retentionsServiceResponse = value;
    }

    get retentionsDealsResponse(): RetentionsServiceDealsResponse {
        return this._retentionsDealsResponse;
    }

    set retentionsDealsResponse(value: RetentionsServiceDealsResponse) {
        this._retentionsDealsResponse = value;
    }

    get config(): Config {
        return this._config;
    }

    set config(value: Config) {
        this._config = value;
    }

    get ldapUid(): string {
        return this._ldapUid;
    }

    set ldapUid(value: string) {
        this._ldapUid = value;
    }

    // isPopulated(): boolean {
    //     if (this.regularPaymentForm === undefined) {
    //         return false;
    //     }
    //     return true;
    // }

    // isAmountPopulated(): boolean {
    //     if (this.regularPaymentForm.regularInputAmount === undefined) {
    //         return false;
    //     }
    //     return true;
    // }

    destroy() {
        this.retentionsPageObject = new RetentionsPageObject();
        this.retentionsDealsResponse = new RetentionsServiceDealsResponse();
        // this.regularPaymentForm = new RegularPaymentForm();
        this.retentionsFeeToggle  = false;
    }

    get dealLoansResponse(): RetentionsServiceDealLoansResponse {
        return this._dealLoansResponse;
    }

    set dealLoansResponse(value: RetentionsServiceDealLoansResponse) {
        this._dealLoansResponse = value;
    }

    get accountValidationResponse(): AccountValidationResponse {
        return this._accountValidationResponse;
    }

    set accountValidationResponse(value: AccountValidationResponse) {
        this._accountValidationResponse = value;
    }

    get customerDetailsAuthenticationResponse(): CustomerDetailsAuthenticationResponse {
        return this._customerDetailsAuthenticationResponse;
    }

    set customerDetailsAuthenticationResponse(value: CustomerDetailsAuthenticationResponse) {
        this._customerDetailsAuthenticationResponse = value;
    }
}

