import {animate,  state, style, transition, trigger} from '@angular/animations';

export function ShowCompareFooterAnimation() {
    return trigger('showFooterAnimate', [
        state('hideCompareFooter', style({
            height: '0'
        })),
        state('showCompareFooter', style({
            height: '!'
        })),
        transition('hideCompareFooter <=> showCompareFooter', [animate('350ms')])
    ]);
}



