import {Pipe, PipeTransform} from '@angular/core';
import {MonthFromDatePipe} from '@core/pipes/month-from-date/month-from-date.pipe';
import {OrdinalPipe} from '@core/pipes/ordinal/ordinal.pipe';

@Pipe({
    name: 'fullDate'
})
export class FullDatePipe extends OrdinalPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        const monthFromDatePipe = new MonthFromDatePipe;
        let dayString;
        let monthString;
        let yearString;
        try {
            const parts = value.split('/');
            dayString = super.transform(parts[0]);

            if (args !== 'shortMonth') {
                monthString = monthFromDatePipe.transform(value);
            } else {
                monthString = monthFromDatePipe.transform2(value);
            }
            if (args === 'withoutYear') {
                return dayString + ' ' + monthString;
            } else {
                yearString = parts[2];
                return dayString + ' ' + monthString + ' ' + yearString;
            }

        } catch (e) {
            // For when the app start async
        }

    }

}
