import { Component, Input } from '@angular/core';
import {TitleBase} from '@components/shared/collapsible-table/types/title-base';
import {Title} from '@components/shared/collapsible-table/types/title';
@Component({
  selector: 'app-collapsible-table-title',
  templateUrl: './collapsible-table-title.component.html',
  styleUrls: ['./collapsible-table-title.component.scss']
})
export class CollapsibleTableTitleComponent {

  @Input() titleElement: TitleBase;

  public getLoanTitle(): string {
    return (this.titleElement as Title).title;
}

}
