import { Component } from '@angular/core';

@Component({
  selector: 'app-input-frame',
  templateUrl: './input-frame.component.html',
  styleUrls: ['./input-frame.component.scss']
})
export class InputFrameComponent {


}
