import { Component } from '@angular/core';

@Component({
  selector: 'app-want-help-choosing',
  templateUrl: './want-help-choosing.component.html',
  styleUrls: ['./want-help-choosing.component.scss']
})
export class WantHelpChoosingComponent {


}
