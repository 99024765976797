import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {saveAs} from 'file-saver';
import {DatePipe} from '@angular/common';
import {Subject} from 'rxjs';
import {RetentionsServiceDealsResponse} from '@core/model/retentions-service-deals-response';
import {RetentionsServiceDealLoansResponse} from '@core/model/retentions-service-deal-loans-response';
import {RetentionsServiceDealLoansRequest} from '@core/model/retentions-service-deal-loans-request';
import {RetentionsServiceTransferResponse} from '@core/model/retentions-service-transfer-response';
import {BrowserInfo} from '@core/model/browser-info';
import {CookieService} from '@core/services/backend/cookie/cookie.service';
import NativeUtils from '@core/utils/native';
import {ErrorHandler} from '@core/error-handler/error-handler';

@Injectable({
    providedIn: 'root'
})
export class RetentionsService {

    private browserInfo: BrowserInfo;

    private isDownloadPdfFinished = new Subject<boolean>();

    public isDownloadPdfFinished$: Observable<boolean> = this.isDownloadPdfFinished.asObservable();

    constructor(
        private http: HttpClient,
        private datePipe: DatePipe,
        private errorHandler: ErrorHandler) {
        this.browserInfo = new BrowserInfo(new CookieService());
    }

    fetchDealDataASync(retentionsServiceBaseUrl, accountNumber, request, clientId, token): Observable<RetentionsServiceDealsResponse> {

        let retentionsUrl: string = retentionsServiceBaseUrl.replace('{account}', accountNumber);

        retentionsUrl = retentionsUrl + '/deals';

        const headers = this.createHeadersWithToken(clientId, token);
        const retentionsServiceHeaders = {headers: headers};

        console.log('Request sent to retentions-deals-list. Endpoint: ' + retentionsUrl);
        console.log('Request sent to retentions-deals-list end point: ' + JSON.stringify(request));

        return this.http.post<RetentionsServiceDealsResponse>(retentionsUrl, request, retentionsServiceHeaders);
    }

    submitTransferRequest(retentionsServiceBaseUrl, accountNumber, request, clientId, token): Observable<RetentionsServiceTransferResponse> {
        let retentionsUrl: string = retentionsServiceBaseUrl.replace('{account}', accountNumber);

        retentionsUrl = retentionsUrl + '/case';

        const headers = this.createHeadersWithToken(clientId, token);
        const retentionsServiceHeaders = {headers: headers};


        return this.http.post<RetentionsServiceTransferResponse>(retentionsUrl, request, retentionsServiceHeaders);
    }

    fetchDealPDFAsync(retentionsServiceBaseUrl, accountNumber, request, clientId, token) {

        let retentionsPDFUrl: string = retentionsServiceBaseUrl.replace('{account}', accountNumber);

        retentionsPDFUrl = retentionsPDFUrl + '/deals-pdf';

        const headers = this.createHeadersPdfWithToken(clientId, token);


        return this.http.post(retentionsPDFUrl, request, {headers: headers, responseType: 'blob'})
            .subscribe(res => {

                    let blob;

                    if (this.browserInfo.isSafari && this.browserInfo.isMobile) {
                        blob = new Blob([res], {type: 'application/octet-stream'});
                    } else {
                        blob = new Blob([res], {type: 'application/pdf'});
                    }

                    const fileName = this.createFileName();
                    if (this.browserInfo.isNative()) {
                        NativeUtils.downloadPdfNative(blob, fileName);
                    } else {
                        saveAs(blob, fileName);
                    }
                    this.completeDownloadActions();
                }, 
                err => {
                    console.log('Error in pdf service:');
                    if (err.status == '406') {
                        this.errorHandler.redirectToHomePage();
                    } else {
                        this.errorHandler.redirectToErrorView();
                    }
                }
            );
    }

    fetchDealLoansDataASync(
        retentionsServiceBaseUrl: string,
        accountNumber: string,
        productCode: string,
        request: RetentionsServiceDealLoansRequest,
        clientId: string,
        token: string
    ): Observable<RetentionsServiceDealLoansResponse> {

        let retentionsUrl: string = retentionsServiceBaseUrl.replace('{account}', accountNumber);

        retentionsUrl = retentionsUrl + `/deal/${productCode}/loans`;

        const headers = this.createHeadersWithToken(clientId, token);
        const retentionsServiceHeaders = {headers: headers};


        return this.http.post<RetentionsServiceDealLoansResponse>(retentionsUrl, request, retentionsServiceHeaders);
    }

    private createFileName(): string {
        const today = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
        return `YourDealsAsOf-${today}.pdf`;
    }

    private createHeadersWithToken(clientId: string, token: string): HttpHeaders {
        return new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', token)
            .set('x-ibm-client-id', clientId)
            .set('Skip-Auth-Interceptor', 'Skip-Auth-Interceptor');
    }

    private createHeadersPdfWithToken(clientId: string, token: string): HttpHeaders {
        return new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', token)
            .set('x-ibm-client-id', clientId)
            .set('Accept', 'application/pdf')
            .set('Skip-Auth-Interceptor', 'Skip-Auth-Interceptor');
    }

    private completeDownloadActions() {
        this.isDownloadPdfFinished.next(true);
    }
}
