import {Component} from '@angular/core';

@Component({
  selector: 'app-data-review-frame',
  templateUrl: './data-review-frame.component.html',
  styleUrls: ['./data-review-frame.component.scss']
})
export class DataReviewFrameComponent {


}
