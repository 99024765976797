import {Component, Input} from '@angular/core';
import {HintMessageTextTransition} from '@core/animations/hint-message-slide-down';

type HintMessageType = 'warning' | 'info' | 'alert';


@Component({
    selector: 'app-hint-message-header-body',
    templateUrl: './hint-message-header-body.component.html',
    styleUrls: ['./hint-message-header-body.component.scss'],
    animations: [
        HintMessageTextTransition()
    ]
})
export class HintMessageHeaderBodyComponent {

    @Input() messageType: HintMessageType;
    @Input() hintHeader = false;



}
