import { Component, EventEmitter, HostListener, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { StickyHeaderService } from '@core/services/common/sticky-header.service';
import { Deal } from '@core/model/retentions-service-deals-response';
import { CustomerJourney } from '@core/model/customer-journey.model';
import { PercentPipe } from '@angular/common';
import { AdobeAnalyticsService } from '@core/services/backend/analytics/adobe-analytics.service';
import { CrossFadingElementComponent } from '@components/shared/cross-fading-element/cross-fading-element.component';
import { BrowserInfo } from '@core/model/browser-info';
import { CookieService } from '@core/services/backend/cookie/cookie.service';
import { ModalService } from '@core/services/modal-service/modal.service';
import { CommonService } from '@core/services/common/common.service';

@Component({
    selector: 'app-comparable-table',
    templateUrl: './comparable-table.component.html',
    styleUrls: ['./comparable-table.component.scss']
})
export class ComparableTableComponent implements OnInit {

    private previousOffsetTop: number;
    isMenuHeaderStuck: boolean;
    private lowestOffset = 5555;
    dealsSelectedForCompare: Deal[];
    feesToggleClickedManually = false;
    countoMonthlyPayment0 : {};
    countoMonthlyPayment1 : {};
    countoMonthlyPayment2 : {};
    countoMonthlyPayments: any;
    countToProductTermCost0 : {};
    countToProductTermCost1 : {};
    countToProductTermCost2 : {};
    countToProductTermCosts : any;
    allLifetimeDeals: boolean;
    hidestickyHeader: boolean = false;

    showHeaderToggle: boolean = false;
    private cookieService = new CookieService();
    private browserInfo = new BrowserInfo(this.cookieService);

    showRemainingBalance: boolean;

    @ViewChildren('crossFadingElementProductFee') crossFadingElementProductFeeList: QueryList<CrossFadingElementComponent>;
    private feeToggleStatusWhenLoaded: boolean;


    @HostListener('window:resize', ['$event'])
    onresize() {
        this.lowestOffset = 9999;
        if (this.showFeeToggle()) {
            this.updateHeaderToggleStatus();
        }
    }


    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll() {

        const stickyModel = this.stickyHeaderService.identifyIfStuckToTop('sticky-header-product-term-title', this.lowestOffset, this.previousOffsetTop);
        this.previousOffsetTop = stickyModel.offsetTop;
        this.lowestOffset = stickyModel.lowestOffset;
        this.isMenuHeaderStuck = stickyModel.isMenuHeaderStuck;
        if (this.showFeeToggle()) {
            this.updateHeaderToggleStatus();
        }

    }

    @Output() goToViewDeal = new EventEmitter<number>();


    constructor(
        private stickyHeaderService: StickyHeaderService,
        private customerJourney: CustomerJourney,
        private percentPipe: PercentPipe,
        private adobeAnalyticsService: AdobeAnalyticsService,
        private modalService: ModalService,
        private commonService: CommonService

    ) {
    }

    ngOnInit() {
        this.countoMonthlyPayments = [this.countoMonthlyPayment0, this.countoMonthlyPayment1, this.countoMonthlyPayment2];
        this.countToProductTermCosts = [this.countToProductTermCost0, this.countToProductTermCost1, this.countToProductTermCost2];
        this.dealsSelectedForCompare = this.customerJourney.retentionsDealsResponse.response.deals
            .filter(deal => deal.compareCheckboxSelected);
        this.feeToggleStatusWhenLoaded = this.feesToggleState();
        this.updateCrossFadingElementProductFee();
        this.allLifetimeDeals = this.isAllLifetime();
        this.showRemainingBalance = this.customerJourney.config.featureFlags.showRemainingBalance;
        }

    public getErcValue(ercSteps) {
         const ercValue: any = this.commonService.calculateStepErcRate(ercSteps);
        return this.percentPipe.transform(ercValue /100, '1.2-2');
    }

    public clickViewDeal(number: number): void {
        this.goToViewDeal.emit(number);
    }

    buildSecondaryRateText(deal: Deal): string {
        if (deal.product.type.toLowerCase() === 'tracker' || deal.product.type.toLowerCase() === 'variable') {
            return 'Current interest rate';
        }
        return 'Interest rate';
    }

    isTrackerProduct(deal: Deal): boolean {
        return deal.product.type.toLowerCase() === 'tracker';
    }

    buildTrackingByText(deal: Deal): string {
        return 'Tracking Bank of England base rate ('
            + this.percentPipe.transform(deal.product.bankOfEnglandRate / 100, '1.2-2')
            + ') +' + this.percentPipe.transform(deal.product.bankOfEnglandRateDifference / 100, '1.2-2');
    }

    buildElementName(i: number): string {
        return 'element' + i;
    }

    showThird(): boolean {
        return this.dealsSelectedForCompare.length > 2;
    }

    isMultiLoan() {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.length > 1;
    }

    public getSelectedMultiLoan(): string {
        if (this.customerJourney.selectedMultiLoan) {
            return 'loans';
        } else {
            return 'loan';
        }
    }

    feesToggleState(): boolean {
        return this.customerJourney.retentionsFeeToggle;

    }

    toggleProductFee() {
        this.adobeAnalyticsService.sendCTAAnalyticsEvent('retentions-compare fee toggle');
        this.customerJourney.retentionsFeeToggle = !this.customerJourney.retentionsFeeToggle;
        this.feesToggleClickedManually = true;

        this.updateCrossFadingElementProductFee();

    }

    calculateIncreasedBalance(deal: Deal) {
        return deal.calculations.remainingBalanceWithoutFee + deal.product.productFee;
        // return this.customerJourney.accountDetailsResponse.data.outstandingBalance + deal.product.productFee;
    }

    getMonthlyPaymentStartValue(deal: Deal): number {
        if (this.feesToggleClickedManually) {
            if (this.customerJourney.retentionsFeeToggle) {
                return deal.calculations.monthlyPaymentWithoutFee;
            } else {
                return deal.calculations.monthlyPaymentWithFee;
            }
        } else {
            if (this.customerJourney.retentionsFeeToggle) {
                return deal.calculations.monthlyPaymentWithFee;
            } else {
                return deal.calculations.monthlyPaymentWithoutFee;
            }
        }
    }

    getMonthlyPaymentEndValue(deal: Deal): number {
        
        if (this.feesToggleClickedManually && this.customerJourney.retentionsFeeToggle) {
            return deal.calculations.monthlyPaymentWithFee;
        } else if (this.feesToggleClickedManually && !this.customerJourney.retentionsFeeToggle) {
            return deal.calculations.monthlyPaymentWithoutFee;
        } else if (!this.feesToggleClickedManually && this.customerJourney.retentionsFeeToggle) {
            return deal.calculations.monthlyPaymentWithFee;
        } else {
            return deal.calculations.monthlyPaymentWithoutFee;
        }
    }

    getProductTermCostStartValue(deal: Deal): number {

        const productTermCostWithoutFee = this.getProductTermCostWithoutFee(deal);
        const productTermCostWithFee = this.getProductTermCostWithFee(deal);

        if (this.feesToggleClickedManually) {
            if (this.customerJourney.retentionsFeeToggle) {
                return productTermCostWithoutFee;
            } else {
                return productTermCostWithFee;
            }
        } else {
            if (this.customerJourney.retentionsFeeToggle) {
                return productTermCostWithFee;
            } else {
                return productTermCostWithoutFee;
            }
        }
    }

    getProductTermCostEndValue(deal: Deal): number {

        const productTermCostWithoutFee = this.getProductTermCostWithoutFee(deal);
        const productTermCostWithFee = this.getProductTermCostWithFee(deal);

        if (this.feesToggleClickedManually && this.customerJourney.retentionsFeeToggle) {
            return productTermCostWithFee;
        } else if (this.feesToggleClickedManually && !this.customerJourney.retentionsFeeToggle) {
            return productTermCostWithoutFee;
        } else if (!this.feesToggleClickedManually && this.customerJourney.retentionsFeeToggle) {
            return productTermCostWithFee;
        } else {
            return productTermCostWithoutFee;
        }
    }

    private getProductTermCostWithFee(deal: Deal) {
        return deal.product.termInMonths * deal.calculations.monthlyPaymentWithFee;
    }

    private getProductTermCostWithoutFee(deal: Deal) {
        return (deal.product.termInMonths * deal.calculations.monthlyPaymentWithoutFee) + deal.product.productFee;
    }

    getTermCost(deal: Deal) {
        if (this.customerJourney.retentionsFeeToggle) {
            return deal.product.termInMonths * deal.calculations.monthlyPaymentWithFee;
        } else {
            return (deal.product.termInMonths * deal.calculations.monthlyPaymentWithoutFee) + deal.product.productFee;
        }
    }

    getTermCostByYear(deal: Deal) {
        if (this.customerJourney.retentionsFeeToggle) {
            return (deal.product.termInMonths * deal.calculations.monthlyPaymentWithFee) / (deal.product.termInMonths / 12);
        } else {
            return ((deal.product.termInMonths * deal.calculations.monthlyPaymentWithoutFee) + deal.product.productFee) / (deal.product.termInMonths / 12);
        }
    }

    getTermInYearsAndMonths(termInMonths: number) {

        const monthsRemainder = termInMonths % 12;
        const years = Math.floor(termInMonths / 12);
        const monthString = monthsRemainder === 1 ? ' month' : ' months';
        const yearString = years === 1 ? ' year' : ' years';

        if (monthsRemainder === 0) {
            return years + yearString;
        } else {
            return years + yearString + ' ' + monthsRemainder + monthString;
        }
    }

    getRemainingBalance(deal: Deal) {
        if (this.customerJourney.retentionsFeeToggle) {
            return deal.calculations.remainingBalanceWithFee;
        } else {
            return deal.calculations.remainingBalanceWithoutFee;
        }
    }

    getAnnualOverpaymentAllowance(deal: Deal) {
        return deal.product.annualOverpaymentAllowancePercentage / 100;
    }

    isUnlimitedAnnualOverpaymentCharge(deal: Deal) {
        return deal.product.annualOverpaymentAllowancePercentage === 0;
    }

    hasEarlyRepaymentCharge(deal: Deal) {
        return deal.product.ercApplicable === true;
    }

    isFixedInterest(type: string): boolean {
        return type.toLocaleLowerCase() === 'fixed';
    }

    public getCountoMonthlyPayment(index: number) {
        return this.countoMonthlyPayments[index];
    }

    setCountoMonthlyPayment(index: number, event: {}) {
        this.countoMonthlyPayments[index] = event;
    }

    public getCountoProductTermCosts(index: number) {
        return this.countToProductTermCosts[index];
    }

    setCountoProductTermCosts(index: number, event: {}) {
        this.countToProductTermCosts[index] = event;
    }

    openModal(name: string) {
        if (name === 'compare-santander-for-or-svr') {
            name = this.getReversionSectionTitle().toLowerCase() === 'standard variable rate' ? 'compare-santander-svr' : 'compare-santander-for';
        }
        this.adobeAnalyticsService.sendCTAAnalyticsEvent('click on i button of: ' + name);
        this.modalService.open(name);
    }

    openErcModal(name: string) {
        this.adobeAnalyticsService.sendCTAAnalyticsEvent('click on i button of: ' + name);
        this.modalService.open('early-repayment-charge');
    }

    public getReversionSectionTitle() {
        let reversionSectionTitle = 'Santander\'s Follow-on Rate';
        this.dealsSelectedForCompare.forEach(deal => {
            if (deal.product.reversionProduct === 'SVR') {
                reversionSectionTitle = 'Standard Variable Rate';
            }
        })
        return reversionSectionTitle;
    }

    public showFeeToggle(): boolean {
        const fees = this.dealsSelectedForCompare.reduce(function (feeTotal, deal) {
            return feeTotal + deal.product.productFee;
        }, 0);
        return fees > 0;
    }

    isLifetimeProduct(deal: Deal): boolean {
        return deal.product.term === 'Lifetime';
    }

    public getRemainingBalanceSubtext(deal: Deal): string {
        const mortgageTermInMonthsAfterProduct = deal.product.mortgageTermInMonthsAfterProduct;
        if (mortgageTermInMonthsAfterProduct === 0 && deal.calculations.remainingBalanceWithFee === 0) {
            return 'Mortgage free';
        } else {
            return this.getTermInYearsAndMonths(mortgageTermInMonthsAfterProduct) + ' remaining';
        }
    }


    private updateHeaderToggleStatus(): void {
        
        const stickyHeader: HTMLElement = document.getElementById('sticky-header-product-term-title');
        const productFeeToggle: HTMLElement = document.getElementById('product-fee-toggle');
        const  stickyFooterViewDeal: HTMLElement= document.getElementById('sticky-footer-vew-deal');
        const productFeeToogleRectangle = productFeeToggle.getBoundingClientRect();
        const dealFooter = stickyFooterViewDeal.getBoundingClientRect();
      
        const viewDealBottom = dealFooter.top;
        const stickyHeaderHeight = stickyHeader.offsetHeight;
        const productFeeToogleRectangleTop = productFeeToogleRectangle.top;
        const productFeeToogleRectangleBottom = productFeeToogleRectangle.bottom;

        if( viewDealBottom < stickyHeaderHeight) {
            this.hidestickyHeader = true;
        } else {
            this.hidestickyHeader = false;
        }

        if (this.browserInfo.isSafari) {
            if (!this.showHeaderToggle && productFeeToogleRectangleBottom < stickyHeaderHeight) {
                this.showHeaderToggle = true;
            } else if (this.showHeaderToggle && productFeeToogleRectangleBottom > stickyHeaderHeight) {
                this.showHeaderToggle = false;
            }
        } else {
            if (!this.showHeaderToggle && productFeeToogleRectangleTop < stickyHeaderHeight) {
                this.showHeaderToggle = true;
            } else if (this.showHeaderToggle && productFeeToogleRectangleBottom > stickyHeaderHeight) {
                this.showHeaderToggle = false;
            }
        }
    }

    private updateCrossFadingElementProductFee(): void {
        try {
            this.feesToggleState()
                ? this.showElementFroToogleOnInProductFee()
                : this.showElementFroToogleOffInProductFee();
        } catch (e) {
        }
    }

    private showElementFroToogleOnInProductFee(): void {
        if (this.feeToggleStatusWhenLoaded) {
            this.crossFadingElementProductFeeList.forEach(element => element.showElement1());
        } else {
            this.crossFadingElementProductFeeList.forEach(element => element.showElement2());
        }
    }

    private showElementFroToogleOffInProductFee(): void {
        if (this.feeToggleStatusWhenLoaded) {
            this.crossFadingElementProductFeeList.forEach(element => element.showElement2());
        } else {
            this.crossFadingElementProductFeeList.forEach(element => element.showElement1());
        }
    }

    protected isAllLifetime(): boolean {

        let result = true;
        this.dealsSelectedForCompare.forEach(selectedDeal => {
            if (selectedDeal.product.term !== 'Lifetime') {
                const difference = (selectedDeal.product.santanderRevisionaryRate -
                    selectedDeal.product.bankOfEnglandRate) / 100;
                this.customerJourney.differentialRate = difference;
                result = false;
            }
        });

        return result;
    }

    public formatDealDescription(term: string, type: string): string {
        if (term.toLowerCase() === 'lifetime' && type.toLowerCase() === 'variable') {
            return 'Standard Variable Rate';
        } else {
            return `${term} ${type}`;
        }
    }
}
