import {Component, OnInit , ElementRef , ViewChild} from '@angular/core';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {CurrencyPipe} from '@angular/common';


@Component({
    selector: 'app-current-deal-single-loan',
    templateUrl: './current-deal-single-loan.component.html',
    styleUrls: ['../current-deal.component.scss', './current-deal-single-loan.component.scss' ]
})
export class CurrentDealSingleLoanComponent implements OnInit {

    imgSource: string;
    public collapsed: boolean;

    @ViewChild('currentDealSingle', {static: false})
    private currentDealSingle: ElementRef;

    constructor(
        private customerJourney: CustomerJourney,
        private currencyPipe: CurrencyPipe) {
    }

    ngOnInit() {
        this.collapsed = true;
        this.imgSource = 'assets/expand-button.png';
    }

    public getCurrentDeal(): string {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans[0].productDescription;
    }

    public getCurrentMonthlyPayment(): string {
        const monthlyPayment = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans[0].monthlyPayment;
        return this.currencyPipe.transform(monthlyPayment, 'GBP');
    }

    
    public getCurrentDealInterest(): string {
        return `${this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans[0].interestRate }%`;
    }

    getCurrentDealInterestLabel() {
        if (this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans[0].telephonyOnly) {
            return 'Current interest rate';
        } else {
            return 'Interest rate';
        }
    }

    public getMortgageBalance(): string {
        const outstandingBalance = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans[0].loanBalance;
        return this.currencyPipe.transform(outstandingBalance, 'GBP');
    }
 
    public click(): void {
        this.updateCollapedStatus();
    }

    private updateCollapedStatus(): void {
        setTimeout(() => {
            try {
                this.collapsed = this.currentDealSingle.nativeElement.classList.contains('collapsed');
                this.updateChevronIcon();
            } catch (e) {
            }
        }, 200);
    }

    public updateChevronIcon(): void {
        if (this.collapsed) {
            this.imgSource = 'assets/expand-button.png';
        } else {
            this.imgSource = 'assets/collapse-button.png';
        }
    }


}
