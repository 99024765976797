import {animate, keyframes, style, transition, trigger} from '@angular/animations';

export function ViewTransition() {
    return trigger('viewTransition', [
      transition('* => coming-from-right', [
        animate('300ms ease-out', keyframes([
          style({transform: 'translateX(75px)', opacity: 0, offset: 0.0}),
          style({transform: 'translateX(0%)', opacity: 1, offset: 1.0})
        ]))
      ]),
      transition('* => coming-from-left', [
        animate('300ms ease-out', keyframes([
          style({transform: 'translateX(-75px)', opacity: 0, offset: 0.0}),
          style({transform: 'translateX(0%)', opacity: 1, offset: 1.0})
        ]))
      ]),
      transition('* => going-to-left', [
        animate('300ms ease-out', keyframes([
          style({transform: 'translateX(0%)', opacity: 1, offset: 0.0}),
          style({transform: 'translateX(-75px)', opacity: 0, offset: 1.0})
        ]))
      ]),
      transition('* => going-to-right', [
        animate('300ms ease-out', keyframes([
          style({transform: 'translateX(0%)', opacity: 1, offset: 0.0}),
          style({transform: 'translateX(75px)', opacity: 0, offset: 1.0})
        ]))
      ]),
      transition('* => fade-content-out', [
          animate('300ms ease-in', keyframes([
              style({opacity: 0, offset: 1.0})
          ]))
      ]),
      transition('* => fade-content-in', [
        animate('300ms ease-in', keyframes([
            style({opacity: 0, offset: 0.0}),
            style({opacity: 1, offset: 1.0})
        ]))
    ])]);
}
