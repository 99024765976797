import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'smallerPence'
})
export class SmallerPencePipe implements PipeTransform {

    transform(value: string): string {
        try {
          const tokens = value.split('.');
                return tokens[0] + '.<span class="smaller-font">' + tokens[1] + '</span>';
        } catch (e) {
            return '0.<span class="smaller-font">00</span>';
        }
    }

}
