import {BodyLineBase} from '@components/shared/collapsible-table/types/body-line-base';
import {BodyLine} from '@components/shared/collapsible-table/types/body-line';

export class BodyLineCompound extends BodyLineBase {
    compoundValues: Array<BodyLine>;
    result: BodyLine;


    constructor(compoundValues?: Array<BodyLine>, result?: BodyLine)
    constructor(compoundValues: Array<BodyLine>, result: BodyLine) {
        super();
        this.compoundValues = compoundValues;
        this.result = result;
    }
}
