import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorComponent} from '@components/views/error/error.component';
import {OnBoardingComponent} from '@components/views/retentions/on-boarding/on-boarding.component';
import {LoanSelectionComponent} from '@components/views/retentions/loan-selection/loan-selection.component';
import {DealsListComponent} from '@components/views/retentions/deals-list/deals-list.component';
import {DealDetailsComponent} from '@components/views/retentions/deal-details/deal-details.component';
import {CompareDealsComponent} from '@components/views/retentions/compare-deals/compare-deals.component';
import {ThingsToKnowComponent} from '@components/views/retentions/things-to-know/things-to-know.component';
import {ProductReviewComponent} from '@components/views/retentions/product-review/product-review.component';
import {ContactDetailsComponent} from '@components/views/retentions/contact-details/contact-details.component';
import {InSessionOfferComponent} from '@components/views/retentions/in-session-offer/in-session-offer.component';
import { AccountSearchComponent } from '@components/views/retentions/account-search/account-search.component';
import { CustomerDetailsComponent } from '@components/views/retentions/customer-details/customer-details.component';

const routes: Routes = [
    {path: '', redirectTo: 'home', pathMatch: 'full'},

    // Retentions
    // {path: 'retentions/on-boarding', component: OnBoardingComponent},
    {path: 'home', component: OnBoardingComponent},
    {path: 'retentions/account-search', component: AccountSearchComponent},
    {path: 'retentions/customer-details', component: CustomerDetailsComponent},
    {path: 'retentions/loan-selection', component: LoanSelectionComponent},
    {path: 'retentions/deals-list', component: DealsListComponent},
    {path: 'retentions/compare-deals', component: CompareDealsComponent},
    {path: 'retentions/deal-details', component: DealDetailsComponent},
    {path: 'retentions/things-to-know', component: ThingsToKnowComponent},
    {path: 'retentions/contact-details', component: ContactDetailsComponent},
    {path: 'retentions/product-review', component: ProductReviewComponent},
    {path: 'retentions/view-offer', component: InSessionOfferComponent},

    // Error component
    {path: 'error', component: ErrorComponent},
    {path: 'error/:id', component: ErrorComponent},

    // Any not defined route
    {path: '**', redirectTo: 'home', pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
})],
    exports: [RouterModule],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
})
export class AppRoutingModule {
}
