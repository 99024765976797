import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Loan} from '@core/model/retentions-service-eligibility-response';
import {CustomerJourney} from "@core/model/customer-journey.model";

@Component({
    selector: 'app-loan-part',
    templateUrl: './loan-part.component.html',
    styleUrls: ['./loan-part.component.scss']
})
export class LoanPartComponent implements OnInit, AfterViewInit {


    @Input() loan: Loan;
    @Input() listType: string;
    @Input() i: number;

    endDate: string;

    @Output() selectLoan = new EventEmitter<boolean>();

    open: boolean;
    imgSource: string = 'assets/chevron-down-grey.svg';


    constructor(private customerJourney: CustomerJourney) {
    }

    ngOnInit() {

        if (this.loan.view.headerView === 'NOT_FIXED') {
            this.endDate = this.loan.loanEndDate;
        } else {
            this.endDate = this.loan.productEndDate;
        }
    }

    ngAfterViewInit(): void {
        if (this.loan.selected) {
            const checkBox = <HTMLInputElement>document.getElementById(`loan-choice-${this.listType}-` + this.i);;
            checkBox.checked = true;
            this.selectLoan.emit(true);
        }
    }


    toggleSelectedLoanOnBoxClick(i: number) {
        const checkBox = <HTMLInputElement>document.getElementById(`loan-choice-${this.listType}-` + i);

        checkBox.checked = !checkBox.checked;

        this.loan.selected = checkBox.checked;

        this.selectLoan.emit(true);
        this.customerJourney.retentionsDealsResponse = null;

    }

    toggleChevron() {
        this.open = !this.open;

        if (this.open) {
            this.imgSource = 'assets/collapse-button.png';
        } else {
            this.imgSource = 'assets/chevron-down-grey.svg';
        }
    }

    getEndDateLabel() {
        return this.loan.view.headerView === 'FIXED' ? 'Product ends' : 'Loan ends';
    }

    getCurrentInterestRateLabel() {
        return this.loan.view.bodyView === 'TRACKING' || this.loan.productDescription === 'Standard Variable Rate' || this.loan.productDescription === 'Santander Follow-on Rate' ? 'Current interest rate' : 'Interest rate';
    }
}
