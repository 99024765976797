import {Component, Input, OnInit} from '@angular/core';
import {TransferHeaderType} from '@components/views/retentions/commons/transfer-header/transfer-header-type';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {CurrencyPipe} from '@angular/common';

@Component({
    selector: 'app-transfer-header',
    templateUrl: './transfer-header.component.html',
    styleUrls: ['./transfer-header.component.scss']
})
export class TransferHeaderComponent implements OnInit {

    @Input() type: TransferHeaderType;
    @Input() qaAffix: string;
    feeAmount: string;

    constructor(
        private customerJourney: CustomerJourney,
        private currencyPipe: CurrencyPipe
    ) {
    }

    ngOnInit() {
       if(this.customerJourney.retentionsPageObject.selectedDeal!== undefined) {
        this.feeAmount = this.currencyPipe.transform(this.customerJourney.retentionsPageObject.selectedDeal.product.productFee, 'GBP', 'symbol', '1.0-0');
       }
    }

    public getIcon(): string {
        switch (this.type) {
            case TransferHeaderType.THINGS_TO_KNOW:
                return 'assets/retentions/retentions-things-to-know.svg';
            case TransferHeaderType.REVIEW:
                return 'assets/retentions/review.svg';
            case TransferHeaderType.CONTACT_DETAILS:
                return 'assets/retentions/customer.svg';
            case TransferHeaderType.OFFER:
                return 'assets/retentions/mortgage-offer.svg';
            case TransferHeaderType.CARD_PAYMENT:
                return 'assets/retentions/payment-icon.png';
            default:
                return '';
        }
    }
}
