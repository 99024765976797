import {Injectable} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from '@components/shared/modal/modal.component';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    private modalConfiguration: NgbModalOptions = {
        centered: true,
        keyboard: true
    };

    constructor(
        private ngbModal: NgbModal,
        private ngbActiveModal: NgbActiveModal
    ) {}

    public open(type: string): void {
        const modalRef = this.ngbModal.open(ModalComponent, this.modalConfiguration);
        modalRef.componentInstance.type = type;
    }

    public close(): void {
        this.ngbActiveModal.close()
    }
}
