import { Component } from '@angular/core';

@Component({
  selector: 'app-deal-details-need-help',
  templateUrl: './deal-details-need-help.component.html',
  styleUrls: ['./deal-details-need-help.component.scss']
})
export class DealDetailsNeedHelpComponent {



}
