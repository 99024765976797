import { Injectable } from '@angular/core';
import { TransferRequest } from '@core/model/retentions-service-transfer-request';
import {CustomerJourney} from '@core/model/customer-journey.model';
import { SelectedLoan } from '@core/model/retentions-service-deals-request';
import { RetentionsService } from '@core/services/backend/retentions/retentions.service';
import {Router} from '@angular/router';
import { ErrorHandler } from '@core/error-handler/error-handler';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductReviewService {

    constructor(
        private customerJourney: CustomerJourney,
        private retentionsService: RetentionsService,
        private router: Router,
        private errorHandler: ErrorHandler) {
    }

    public buildCaseRequest(): TransferRequest {
        const selectedLoans = [];
            this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.forEach(function (loan) {
                if (loan.selected) {
                    const selectedLoan = new SelectedLoan();
                    selectedLoan.loanScheme = loan.scheme;
                    selectedLoan.sequenceNumber = loan.sequenceNumber;
                    selectedLoans.push(selectedLoan);
                }
            });
       

        const transferRequest = new TransferRequest();
        transferRequest.productCode = this.customerJourney.retentionsPageObject.selectedDeal.product.productCode;
        transferRequest.loansSelected = selectedLoans;
        if (this.customerJourney.hasProductFee) {
            transferRequest.feeAddedInMortgage = this.customerJourney.retentionsFeeToggle;
        } else {
            transferRequest.feeAddedInMortgage = true;
        }
        transferRequest.mobileNumber = this.customerJourney.accountDetailsResponse.data.borrowers[0].mobileNumber;
        transferRequest.emailAddress = this.customerJourney.accountDetailsResponse.data.borrowers[0].emailId;
        return transferRequest;
    }

    public submitTransferCase(): Observable<void> {
        const request = this.buildCaseRequest();
        return new Observable<void>((observer) => {
            this.retentionsService.submitTransferRequest(this.customerJourney.config.retentionsServiceBaseUrl,
                this.customerJourney.fullAccountNumber,
                request,
                this.customerJourney.config.clientId,
                this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.access_token)
                .subscribe(
                    response => {
                        console.log(response);
                        this.customerJourney.retentionsPageObject.isCTA = true;
                        this.router.navigate(['/retentions/view-offer']);
                        observer.next();
                        observer.complete();
                    },
                    err => {
                        if (err.status == '406') {
                            this.errorHandler.redirectToHomePage();
                        } else {
                            this.errorHandler.redirectToErrorView();
                        }
                    }
                );
        });
    }

}