import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-data-review-title',
  templateUrl: './data-review-title.component.html',
  styleUrls: ['./data-review-title.component.scss']
})
export class DataReviewTitleComponent {

    @Input() title: string;
    @Input() qaAffix: string;


}
