import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {
    private subject = new Subject<any>();

    callModal(modalType: string) {
        this.subject.next( modalType );
    }

    clearModals() {
        this.subject.next(null);
    }

    getModal(): Observable<any> {
        return this.subject.asObservable();
    }
}
