import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {BrowserInfo} from '@core/model/browser-info';
import {CookieService} from "@core/services/backend/cookie/cookie.service";

@Component({
    selector: 'app-compare-footer',
    templateUrl: './compare-footer.component.html',
    styleUrls: ['./compare-footer.component.scss']
})
export class CompareFooterComponent {

    @Output() navigateToCompareScreen = new EventEmitter();
    @Input() dealsLoaded: boolean;
    @Input() showWarning: boolean;
    private cookieService = new CookieService();
    private browserInfo = new BrowserInfo(this.cookieService);

    public MAXIMUM_ALLOWED = 3;
    public MAXIMUM_ALLOWED_MOBILE = 2;

    constructor(
        private customerJourney: CustomerJourney) {
    }

    public clickCompareButton(): void {
        this.navigateToCompareScreen.emit();
    }

    public enableCompareButton(): boolean {
        return this.numberOfDealsSelected() > 1;
    }

    private numberOfDealsSelected(): number {
        return this.customerJourney.retentionsDealsResponse.response.deals
            .filter(deal => deal.compareCheckboxSelected).length;
    }

    public isMobile(): boolean {
        return this.browserInfo.isMobile;
    }

    public maximumNumberOfDealsAllowed(): number {
        if (this.isMobile()) {
            return this.MAXIMUM_ALLOWED_MOBILE;
        } else {
            return this.MAXIMUM_ALLOWED;
        }
    }
}
