export class AccountData {
accountNumber: string;
dateOfBirth?: string;
surname?: string;
postCode?: string;

constructor(accountNumber: string){
    this.accountNumber = accountNumber;
}

}