import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { CustomerJourney } from '@core/model/customer-journey.model';
import { Router } from '@angular/router';
import { ViewTransition } from '@core/animations/view-transition/view-transition';
import { ProgressIndicatorItem } from '@components/shared/progress-indicator/progress-indicator.component';
import { Deal } from '@core/model/retentions-service-deals-response';
import moment from 'moment';
import { RetentionConstant } from "src/app/constants/retention-constant";
import {AdobeAnalyticsService} from '@core/services/backend/analytics/adobe-analytics.service';
import { CommonService } from '@core/services/common/common.service';
import { CallUsSideBarComponent } from '@components/shared/call-us-side-bar/call-us-side-bar.component';
import { PercentPipe } from '@angular/common';

type AnimationStates = 'coming-from-left' | 'coming-from-right' | 'going-to-left' | 'going-to-right';

@Component({
    selector: 'app-in-session-offer',
    templateUrl: './in-session-offer.component.html',
    styleUrls: ['./in-session-offer.component.scss'],
    animations: [ViewTransition()]
})
export class InSessionOfferComponent implements OnInit {

    traker: string[] = ['life timer tracker', 'fixed term tracker', 'follow on rate', 'SVR' ];
    animationState: AnimationStates = 'coming-from-right';
    progressIndicator: ProgressIndicatorItem[];
    selectedDeal: Deal;
    accountDetails: string;
    isMultiLoan: boolean;
    isUpfrontPaid: boolean;
    configData: any;

    @ViewChild('callUsSideBar')
    callUsSideBarComponent: CallUsSideBarComponent;

    constructor(private customerJourney: CustomerJourney,
        private router: Router, private adobeAnalyticsService: AdobeAnalyticsService, private commonService: CommonService, private percentagePipe: PercentPipe) {
        this.accountDetails = this.customerJourney.fullAccountNumber;
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        this.commonService.setHideCallusSideBarFlag(false);
        this.callUsSideBarComponent.onClick();
      }

    ngOnInit() {
        this.decorateProgressIndicator();

        this.configData = this.customerJourney.config;

        this.selectedDeal = this.customerJourney.retentionsPageObject.selectedDeal;

        this.isMultiLoan = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.length > 1;
        this.checkIsUpfrontpayment();
        this.adobeAnalyticsService.sendPageViewEvent('confirmation', this.isPendingSwitch(), true, this.isMultiLoan);
    }

    public isPendingSwitch() {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.accountStatus === "PENDING_SWITCH";
      }

    public getConfirmationTitle(): string {
        //console.log(this.selectedDeal.product.productFee + "*******" + JSON.stringify(this.customerJourney.retentionsFeeToggle));
        if (this.isUpfrontPaid) {
            return "Keep an eye on the post, your mortgage offer will soon be on its way";
        }
        return 'Great, check your email! \n Your mortgage offer is on its way';
        //break;
    }

    public getConfirmationSubTitle(): string {
        //console.log("sub title");
        const emailId = this.customerJourney.accountDetailsResponse.data.borrowers[0].emailId;
        if (this.isUpfrontPaid) {
            return `We'll send an email to ${emailId} once the offer has been posted`;
        }
        return `We've sent an email to ${emailId}`;
    }

    public getMortgageOfferTitle(): string {
        //console.log("offer title");
        const productRate = this.percentagePipe.transform(this.selectedDeal.product.rate / 100, '1.2-2');
        return this.formatDealDescription(this.selectedDeal.product.term, this.selectedDeal.product.type)  + (this.isTrackerOrSVRProduct() ? ' currently' : '') + ' at ' + productRate;
    }

    public getMortgageOfferText(): string {
        //console.log("offer text: " +this.isUpfrontPaid);
        let message = 'Product fee £' + this.selectedDeal.product.productFee + ' ' ;
        if(this.isUpfrontPaid) {
            message = message + 'paid upfront ';
        } else if(this.selectedDeal.product.productFee > 0 && this.customerJourney.retentionsFeeToggle) {
            message = message + 'added to loan ';
        }
        message = message + '| Expires in';
        return  message;
    }

    public getNextStepDetails(): string {
        return `You can accept to change to this deal at any point in the next 14 days. Simply follow the instructions in the email to access and accept your mortgage offer online. After ${moment(new Date()).add(RetentionConstant.OFFER_VALIDATION_DAYS, 'days').format(RetentionConstant.MORTGAGE_OFFER_DATE_FORMAT)}, this mortgage offer will expire.`;
    }

    public getNoMailHeader(): string {
        if(this.isUpfrontPaid) {
            return `If you don't receive your email confirmation`;
        } else {
            return `If you don't receive your email`;
        }
    }

    public getNoMailDetail(): string {
        if(this.isUpfrontPaid) {
            return `If your email doesn't arrive within 1 working day call us on 0800 028 6063. You'll need your mortgage account number ${this.customerJourney.fullAccountNumber} and we'll get you sorted.`;
        } else {
            return `If you can't see your email, check your junk folder. If you still don't see it please call our Mortgage customer service line on 0800 028 6063, you'll need your account number ${this.customerJourney.fullAccountNumber} and we'll get you sorted.`;
        }
    }



    public navigateBack(): void {
        this.animationState = 'going-to-right';
    }

    public navigate(): void {
        if (this.animationState === 'going-to-right') {
            this.router.navigate(['/retentions/product-review'], { queryParams: { from: 'back' } });
        } else if (this.animationState === 'going-to-left') {
            this.router.navigate(['/retentions/accept-offer']);
        }
    }

    private decorateProgressIndicator() {
        this.progressIndicator = [
            { label: 'Contact details', status: 'ok' },
            { label: 'Transfer review', status: 'ok' },
            { label: 'Confirmation', status: 'third-selected' }
        ];
    }

    public navigateForward(): void {
        this.animationState = 'going-to-left';
    }

    public isTrackerOrSVRProduct(): boolean {
        return this.traker.indexOf(this.selectedDeal.product.type) >= 0 || this.selectedDeal.product.type.toLowerCase().indexOf("tracker") >= 0 || this.selectedDeal.product.type.toLowerCase() === 'variable';
    }

    public checkIsUpfrontpayment(): void {
        this.isUpfrontPaid = this.selectedDeal.product.productFee > 0 && !this.customerJourney.retentionsFeeToggle;
    }

    public homeInsuranceUrl(): string {
        return this.configData.urls.homeInsurance;
    }

    public lifeInsuranceUrl(): string {
        return this.configData.urls.lifeInsurance;
    }

    public printFullPage() {
        window.print();
    }

    public goToSantanderHome() {
        console.log("Going to " + this.configData.urls.santanderHome);
        window.open(this.configData.urls.santanderHome, '_blank');
  }

    public formatDealDescription(term: string, type: string): string {
        if (term.toLowerCase() === 'lifetime' && type.toLowerCase() === 'variable') {
            return 'Standard Variable Rate';
        } else {
            return `${term} ${type}`;
        }
    }
}
