import {Info} from '@core/model/info';

export class CustomerDetailsAuthenticationResponse {
    customerDetailsAuthenticationResponse: AuthenticationResponse;
    serviceInfo: Info;
}


export class AuthenticationResponse {
    authenticationStatus: AuthenticationStatus;
    eligibilityResponseView: CustomerDetailsAuthenticationData;
}

export class AuthenticationStatus {
    access_token: string;
    accountStatus: string;
    numberOfFailedLoginAttempts: number;
    maximumNumberOfLoginAttempts: number;
}

class Blockers {
    accountIneligible: boolean;
    allLoansIneligible: boolean;
    allLoansERC: boolean;
    notOnline: boolean;
    financialDifficulties: boolean;
    accountBalanceBelowThreshold: boolean;
    flexi: boolean;
    outsideDealWindow: boolean;
    termRemaining: boolean;
    consentToLet: boolean;
    eligibleInArrears: boolean;
}

export class CustomerDetailsAuthenticationData {
    blockers: Blockers;
    loans: Loan[];
    minimumBalanceForSwitching: number;
    bandId: string;
    nextPaymentDate: string;
    productCompletionDate: string;
    pendingSwitchDate: string;
}

export class LoanBlockers {
    termRemaining: boolean;
    outsideDealWindow: boolean;
}

export class Loan {
    loanId: number;
    loanBalance: number;
    loanType: string;
    remainingTerm: number;
    monthlyPayment: number;
    productEndDate: string;
    loanEndDate: string;
    isOnReversionRate: boolean;
    productDescription: string;
    blockers: LoanBlockers;
    selected: boolean;
    eligibleToTransfer: boolean;
    interestRate: number;
    followOnRate: number;
    sequenceNumber: number;
    scheme: string;
    view: View;
    telephonyOnly: boolean;
    regularOverpayment: boolean;
    ercApplicable: boolean;
    productSwitchInfo: ProductSwitchInfo;
}

export class View {
    headerView: string;
    bodyView: string;
}

export class ProductSwitchInfo {
    newProductFee: number;
    newProductDescription: string;
    newInterestRate: number;
    newProductCode: string;
    productSwitchDate: string;
    pendingProductSwitch: boolean;
}
