import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Loan} from '@core/model/retentions-service-eligibility-response';

@Component({
    selector: 'app-loan-list',
    templateUrl: './loan-list.component.html',
    styleUrls: ['./loan-list.component.scss']
})
export class LoanListComponent {

    @Input() loans: Loan[];
    @Input() index: number;
    @Input() qaId: string;
    @Input() listType: string;

    @Output() emitLoanToggle = new EventEmitter<boolean>();

    public loanToggleClicked(){
        this.emitLoanToggle.emit();
    }

}
