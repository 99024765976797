import {TitleBase} from "@components/shared/collapsible-table/types/title-base";

export class Title extends TitleBase {

    title: string;

    constructor(title: string) {
        super();
        this.title = title;
    }
}
