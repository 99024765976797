import {  Component,  OnInit,  ViewChild,  ViewEncapsulation, OnDestroy, HostListener} from '@angular/core';
import { AdobeAnalyticsService } from '@core/services/backend/analytics/adobe-analytics.service';
import { Router, ActivatedRoute } from '@angular/router';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import { CustomerJourney } from '@core/model/customer-journey.model';
import {ViewTransition} from '@core/animations/view-transition/view-transition';
import { AccountValidationResponse } from '@core/model/accountValidationResponse';
import { AccountService } from '@core/services/backend/retentions/account.service';
import {ErrorHandler} from '@core/error-handler/error-handler';
import { Config } from '@core/model/config-response';
import { AccountData } from '@core/model/account-data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import {ModalService} from '@core/services/modal-service/modal.service';
// Recaptcha
import { InvisibleReCaptchaComponent } from 'ngx-captcha';
import { CallUsSideBarComponent } from '@components/shared/call-us-side-bar/call-us-side-bar.component';
import { CommonService } from '@core/services/common/common.service';

type AnimationStates = 'coming-from-left' | 'coming-from-right' | 'going-to-left' | 'going-to-right';

//TODO This component needs to be refactored for google captcha things based on https://github.com/Enngage/ngx-captcha/blob/master/demo/src/invisible-recaptcha-demo.component.ts.
// Need to remove mutation observer as well.
@Component({
  selector: 'app-account-search',
  templateUrl: './account-search.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./account-search.component.scss', '../../../../../scss/common.scss'],
  animations: [ViewTransition()]
})
export class AccountSearchComponent implements OnInit, OnDestroy {

  // Recaptch Variables
  public recaptchaKey: string;
  private readonly timeOutInterval = 600;

  @ViewChild('callUsSideBar')
  callUsSideBarComponent: CallUsSideBarComponent;


  public recaptchaForm: FormGroup = this.fb.group({
    recaptcha: ['', Validators.required]
  });
  private recaptchaOpen: boolean;

  @ViewChild(InvisibleReCaptchaComponent) public recaptcha: InvisibleReCaptchaComponent;
  @ViewChild('tooltipAccountSearch', {static: false}) accountSearchTooltip: NgbTooltip;

  public recaptchaElement: HTMLElement;

  private recaptchaSubscription = new Subscription();

  private observer: MutationObserver = new MutationObserver((mutationArray, obs) => { this.findChallenge(mutationArray, obs); });
  private challengeObserver: MutationObserver = new MutationObserver(() => { this.trackChallengeVisibility(); });

  public loading = false;

  accountNumber: string;
  anmf_search_heading = 'Search for a mortgage account';
  anmf_search_help = ['Use 5 to 9 digit account numbers', 'Non numeric characters will be treated as invalid'];
  public _validAnmf: boolean;
  public _accountLength: boolean;
  public _validateAnmfOnClick: boolean;
  public _validAccountStatus: string;
  validAnmfAccount: boolean;
  validationFeedback = '';
  showSpinner = false;
  validAnmfPattern: RegExp = /^(.*[1-9])\d{5,9}.*/;
  traceId: string;
  fNumberKey = 'fnumber';
  inactiveWarning: string;
  accountValidationResp: AccountValidationResponse;
  config: Config;
  accountLocked: boolean = false;
  clickedSubmit: boolean = false;
  animationState: AnimationStates = 'coming-from-right';
  isLoader: boolean = false;

    constructor(
        private adobeAnalyticsService: AdobeAnalyticsService,
        private router: Router,
        private route: ActivatedRoute,
        private accountService: AccountService,
        private customerJourney: CustomerJourney,
        private errorHandler: ErrorHandler,
        private fb: FormBuilder,
        private modalService: ModalService,
        private commonService: CommonService
        ) {
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        this.commonService.setHideCallusSideBarFlag(false);
        this.callUsSideBarComponent.onClick();
      }

  ngOnInit() {

    this.customerJourney.destroy();
    window.scroll(0, 0);
    this.setUpNavigation();
    this.loadValues();
    this.adobeAnalyticsService.sendPageViewEvent('account-search', false);
  }

  public ngOnDestroy(): void {
      console.log('destroy')
    if(this.config?.featureFlags?.isCaptchaLoad) {
      this.destroyRecaptcha();
    }
  }

  setUpNavigation() {
    this.route.queryParams.subscribe(params => {
        if (params.from !== null && params.from === 'back') {
            this.animationState = 'coming-from-left';
        }
    });
  }

  public clickBackButton(): void {
    this.animationState = 'going-to-right';
  }

  private loadValues() {

    this.recaptchaKey = this.customerJourney.config.recaptchaKey;
    this.config = this.customerJourney.config;
    this.validAnmfAccount = true;
    if (this.config.recaptchaKey !== undefined) {
      this.initialiseRecaptcha();
    }

}

  validateAnmf() {
    this.clickedSubmit = false;
    this.validationFeedback = "";
    this.inactiveWarning = null;
    this.validAnmfAccount = true;
    this.accountLocked = false;
    if (!this.accountNumber || this.accountNumber.length < 6) {
      this._accountLength = false;
      this._validAnmf = false;
    } else {
        this._validAnmf = this.validAnmfPattern.test(this.accountNumber);
        this._accountLength = this._validAnmf;
    }
  }

  validateAnmfOnClick() {
    this.inactiveWarning = null;
    if (!this.accountNumber) {
      this.validationFeedback = 'Please tell us your mortgage account number.';
      this._validAnmf = false;
      this._validateAnmfOnClick = false;
    } else if (!this._accountLength) {
      this.validationFeedback = 'Your account number must contain at least 6 numbers.';
      this._validAnmf = false;
      this._validateAnmfOnClick = false;
    } else {
      if (this.accountNumber.includes('#')) {
        this.validAnmfAccount = false;
        this.adobeAnalyticsService.sendCTAAnalyticsEvent("Account validation failure");
      } else {
        this._validateAnmfOnClick = true;
      }
  }
}

  submitForm() {
    if(this.accountNumber) {
      this.accountNumber = this.accountNumber.replace(/[/]/g, '');
      this.accountNumber = this.accountNumber.replace(/[\\]/g, '');
    }
    console.log('I am in submit form, acc no: ' + this.accountNumber);
    this.validateAnmfOnClick();
    if (this._validateAnmfOnClick) {
      if (this.clickedSubmit) {
        return;
      }
      this.clickedSubmit = true;
      if(this.config?.featureFlags?.isCaptchaLoad) {
        this.recaptcha.execute();
      } else {
        this.validateAccountNumber("dummy-token");
      }
    }

  }

  get getValidateAnmfOnClick(): boolean {
    return this._validateAnmfOnClick;
  }

  get validAnmf(): boolean {
    return this._validAnmf;
  }

  get accountLength(): boolean {
      return this._accountLength;
  }

  get validAccountStatus(): string {
    return this._validAccountStatus;
  }

  set validAccountStatus(validAccountStatus: string) {
    this._validAccountStatus = validAccountStatus;
  }

  public navigate() {
    if (this.animationState === 'going-to-left') {
        this.router.navigate(['retentions/customer-details']);
    } else if (this.animationState === 'going-to-right') {
        this.router.navigate(['/home'], {queryParams: {from: 'back'}});
    }
}

// Recaptcha Functions
public handleSuccess(token): void {
  this.recaptchaOpen = false;
  console.log('About to call send validation');
  this.validateAccountNumber(token);

  this.accountService.googleTokenSub.next(token);

}
  validateAccountNumber(token: any) {
    console.log('We have success and validationUrl is :' + this.config.urls.accountValidationURL);
    this.isLoader = true;
    this.accountService.validateAccountNumber(this.config.urls.accountValidationURL, this.accountNumber, token, this.config.recaptchaKey, this.config.clientId)
    .subscribe(
      response => {
        console.log('I am here 1!!');
        this.isLoader = false;
        this._validAccountStatus = response['body'].accountValidationResponse.accountValidationStatus;
        this.customerJourney.accountValidationResponse = response['body'];
        console.log('I am here 2!!!');
        this.errorHandler.checkErrorsToHandle();
        console.log('This is the value of the eligible account1 ' + this._validAccountStatus);
        if (this._validAccountStatus === 'VALID') {
          this.validationFeedback = 'Yippe we have success';
          this.accountService.setValidatedAccount(new AccountData(response['body'].accountValidationResponse.evaluatedAccountNumber));
          this.customerJourney.fullAccountNumber = response['body'].accountValidationResponse.evaluatedAccountNumber;
          this.animationState = 'going-to-left';
        } else if (this._validAccountStatus === 'CONSENT_TO_LET') {
          this.accountService.setValidatedAccount(new AccountData(response['body'].accountValidationResponse.evaluatedAccountNumber));
          this.customerJourney.fullAccountNumber = response['body'].accountValidationResponse.evaluatedAccountNumber;
          this.modalService.open("consentToLet");
        } else if (this._validAccountStatus === 'LOCKED') {
          this.accountLocked = true;
          this.validAnmfAccount = false;
        } else {
          this.validationFeedback = '';
          this.validAnmfAccount = false;
          this.adobeAnalyticsService.sendCTAAnalyticsEvent("Account validation failure");
        }

        this.config?.featureFlags?.isCaptchaLoad && this.recaptcha?.resetCaptcha();
      },
      () => {
        console.log("Error on account search service:");
        this.errorHandler.redirectToErrorView();
      }
    );
}

public handleRecaptchaError(number?: number, error?: { message: any; }): void {
  if (this.loading) {
    this.adobeAnalyticsService.updateErrorMessages({ recaptcha: `${number}: ${error.message ? error.message : 'unknown error'}` });
  }
}

public makeValidationCall(): void {
  console.log('In Make validation call method');
  this.loading = true;
}

private destroyRecaptcha(): void {
  this.recaptchaSubscription.unsubscribe();
  this.observer.disconnect();
  this.challengeObserver.disconnect();
  if (this.recaptchaElement) {
    document.body.removeChild(this.recaptchaElement);
  }
}

private initialiseRecaptcha(): void {

  this.recaptchaSubscription = this.accountService.executeRecaptchaCall.subscribe(() => {
    console.log('init recaptcha')
    try {
      if (this.recaptcha.getResponse()) {
        this.recaptcha.reloadCaptcha();
      }
      this.recaptchaOpen = true;
      console.log('Done the recaptcha');

      this.recaptcha.execute();
      this.observer.observe(document, {
        childList: true,
        subtree: true
      });
    } catch (e) {
      this.handleRecaptchaError(1, e);
    }
  });
}

private findChallenge(_mutationArray, obs: MutationObserver): void {
  const framesArray = Array.from(document.querySelectorAll('iframe')) as HTMLElement[];
  let captcha = framesArray ? framesArray.find((el) => el.title.toLowerCase().includes('recaptcha')) : null;
  captcha = captcha ? captcha : framesArray ? framesArray.find((el) => el.outerHTML.includes(this.config.urls.recaptchaUrl)) : null;
  if (captcha) {
    this.recaptchaElement = captcha.parentElement.parentElement;
    if (this.recaptchaElement) {
      this.challengeObserver.observe(this.recaptchaElement, {
        attributes: true,
      });
      obs.disconnect();
      return;
    }
  }
}

private trackChallengeVisibility(): void {
  if (this.recaptchaOpen && this.recaptchaElement.style.visibility === 'hidden') {
    setTimeout(() => {
      try {
         if (!this.recaptcha.getResponse()) {
           console.log('Done the recaptcha 2');
        this.recaptcha.execute();
        }
      } catch (e) {
        this.handleRecaptchaError(2, e);
      }
    }, this.timeOutInterval);
  }
}
}
