import {Component, Input, HostListener, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {phoneIcon} from '@core/animations/phone-icon-transition';
import {AdobeAnalyticsService} from '@core/services/backend/analytics/adobe-analytics.service';
import { CommonService } from '@core/services/common/common.service';

@Component({
    selector: 'app-call-us-side-bar',
    templateUrl: './call-us-side-bar.component.html',
    styleUrls: ['./call-us-side-bar.component.scss'],
    animations: [
        trigger('helpSideBar', [
            state('true', style({
                right: '0'
            })),
            transition('* <=> *', [
                animate('300ms ease-out')
            ])
        ]), phoneIcon()
    ]
})
export class CallUsSideBarComponent implements OnInit {


    @Input() contentType: string;
    @Input() state: any;

    helpSideBarVisible: boolean;
    phoneIconAnimation: any;
    phoneNumber: string;

    constructor(private adobeAnalyticsService: AdobeAnalyticsService, private commonService: CommonService) {
    }

    ngOnInit() {
        this.helpSideBarVisible = false;
        this.phoneIconAnimation = this.state;

        if (this.contentType === undefined) {
            throw new TypeError('The input ‘contentType’ is required for showing the correct sidebar content');
        }

        this.commonService.getCallusSideBarFlag.subscribe(res => { // getting the response to show the phone number
            this.phoneNumber = res ? '0800 028 6063' : '0800 068 6462';
        });
    }

    @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
        this.hideSideBar();
    }

    onClick() {
        this.commonService.getHideCallusSideBarFlag.subscribe(res => { // getting the response to show or hide the call us side bar
            if (res) {
                this.helpSideBarVisible = true;
            } else {
                this.helpSideBarVisible = false;
            }
        });

    }

    showSideBar($event: any) {
        this.adobeAnalyticsService.sendCTAAnalyticsEvent('call-us-side-bar');
        this.helpSideBarVisible = true;
        this.commonService.setHideCallusSideBarFlag(true);
        $event.stopPropagation();
    }

    hideSideBar() {
        this.helpSideBarVisible = false;
    }

    public pageClick($event: any) {
        $event.stopPropagation();
    }
}
