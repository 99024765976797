import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-static-content-list',
  templateUrl: './static-content-list.component.html',
  styleUrls: ['./static-content-list.component.scss']
})
export class StaticContentListComponent {

    @Input() content: string[];
    @Input() title: string;


}
