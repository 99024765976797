import {FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import { ElementRef, Input, OnDestroy, OnInit, Directive } from '@angular/core';
import {FormValidationService} from '../form-validation.service';

@Directive()
export abstract class ErrorBaseComponent implements OnDestroy, OnInit {
    @Input() errorCode: string;
    @Input() control: FormControl;
    @Input() errorControl: string;
    @Input() qa: string;
    @Input() forAttr: string;
    @Input() childControls: FormControl[];
    private submitted: boolean;

    private subscriptions: Subscription[] = [];

    protected constructor(protected elRef: ElementRef, protected formValidationService: FormValidationService) {
    }

    ngOnInit() {
        if (!this.errorControl) {
            throw new Error(`errorControl attribute is mandatory for error message: ${this.qa} ${this.forAttr}`);
        }
        this.subscriptions.push(this.formValidationService.formSubmits().subscribe((value: any) => {

            if (this.controlHasError()) {
                this.submitted = true;

                this.callBack(value);
            }
        }));
    }

    private callBack(errorObject: any) {
        if (!this.formValidationService.isCalledBack()) {

            if (!errorObject || !errorObject.errorControl || errorObject.errorControl === this.errorControl) {
                this.formValidationService.setCalledBack();
                this.elRef.nativeElement.scrollIntoView({behavior: 'smooth'});
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    private controlHasError() {
        return this.control.hasError(this.errorCode);
    }

    hasError() {
        return this.controlHasError() && (this.isTouched() || this.submitted);
    }

    isTouched(): boolean {
        if (this.childControls && this.childControls.length > 0) {
            let touched = true;
            if (this.childControls.filter(control => !control.touched).length > 0) {
                touched = false;
            }
            return touched;
        }
        return this.control.touched;
    }

}
