import {Component, Input, OnInit} from '@angular/core';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {DatePipe} from '@angular/common';
import {FullDatePipe} from '@core/pipes/full-date/full-date.pipe';
import {Deal} from '@core/model/retentions-service-deals-response';

@Component({
    selector: 'app-mortgage-illustration-info',
    templateUrl: './mortgage-illustration-info.component.html',
    styleUrls: ['./mortgage-illustration-info.component.scss']
})
export class MortgageIllustrationInfoComponent implements OnInit {

    currentDate: any;
    selectedDeal: Deal;
    @Input() page: String;


    constructor(private customerJourney: CustomerJourney,
                private fullDatePipe: FullDatePipe,
                private datePipe: DatePipe) {
    }

    ngOnInit() {

        this.selectedDeal = this.customerJourney.retentionsPageObject.selectedDeal;
        this.setCurrentDate();
    }

    private setCurrentDate(): void {
        const timestamp = this.customerJourney.retentionsDealsResponse.info.timestamp;
        const date = this.datePipe.transform(timestamp, 'dd/MM/yyyy');
        this.currentDate = this.fullDatePipe.transform(date, 'withoutYear');
    }

    isMultiLoan() {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.length > 1;
    }

}
