import { Component } from '@angular/core';
import { BrowserInfo } from '@core/model/browser-info';
import { CookieService } from '@core/services/backend/cookie/cookie.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Mortgage Centre';
  browserInfo: BrowserInfo;
  showCorinthian = true;

  constructor() {
    this.browserInfo = new BrowserInfo(new CookieService());
  }

  public isNotNative(): boolean {
    return !this.browserInfo.isNative();
  } 

  onActivate() {
    window.scroll(0, 0);
  }

}
