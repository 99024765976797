import {Pipe, PipeTransform} from '@angular/core';
import {MonthFromDatePipe} from '@core/pipes/month-from-date/month-from-date.pipe';

@Pipe({
  name: 'monthAndYearFromDate'
})
export class MonthYearFromDatePipe implements PipeTransform {

  transform(value: any): any {
    const monthFromDatePipe = new MonthFromDatePipe;
    let monthString;
    let yearString;
    try {
      const parts = value.split('/');
      monthString = monthFromDatePipe.transform(value);
      yearString = parts[2];
    } catch (e) {
      // For when the app start async
    }
    return monthString + ' ' + yearString;
  }

}
