import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {AdobeAnalyticsService} from '@core/services/backend/analytics/adobe-analytics.service';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {Deal} from '@core/model/retentions-service-deals-response';
import {ProgressIndicatorItem} from '@components/shared/progress-indicator/progress-indicator.component';
import {ModalService} from '@core/services/modal-service/modal.service';
import {AccountService} from "@core/services/backend/retentions/account.service";

type AnimationStates = 'coming-from-left' | 'coming-from-right' | 'going-to-left' | 'going-to-right';

@Component({
    selector: 'app-ready-to-change-your-mortgage',
    templateUrl: './ready-to-change-your-mortgage.component.html',
    styleUrls: ['./ready-to-change-your-mortgage.component.scss']
})
export class ReadyToChangeYourMortgageComponent implements OnInit {

    progressIndicator: ProgressIndicatorItem[];
    selectedDeal: Deal;
    accountDetails: string;
    isUpfrontPaid: boolean;

    @Output() animationState = new EventEmitter<AnimationStates>();

    constructor(private customerJourney: CustomerJourney,
                private adobeAnalyticsService: AdobeAnalyticsService,
                private accountService: AccountService,
                private modalService: ModalService
    ) {
        this.selectedDeal = this.customerJourney.retentionsPageObject.selectedDeal;
        this.accountDetails = this.accountService.getValidatedAccount().accountNumber;
    }

    ngOnInit() {
        this.progressIndicator = [{label: 'Summary', status: 'selected'},
            {label: 'Reminder', status: null},
            {label: 'Review', status: null},
            {label: 'Confirm', status: null},
            {label: 'Mortgage Offer', status: null}];
    }

    public clickOnReadyToChangeContinueButton(): void {
        this.checkIsUpfrontpayment();
        if (this.customerJourney.retentionsPageObject.unselectedTransferringLoans?.length > 0) {
            this.adobeAnalyticsService.sendPageViewEvent('deal-details-page-not-all-transferring-loans-selected', this.anyPending(), true, this.isMultiLoan());
            this.modalService.open('secondModalCustomerNotSelectingAllTransferringLoans');
        } else if (this.isUpfrontPaid) {
            this.adobeAnalyticsService.sendPageViewEvent('begin-transfer');
            this.modalService.open("productFeeUpfront");
        } else {
            this.adobeAnalyticsService.sendPageViewEvent('Final decision fee is added on deal summary');
            this.animationState.emit('going-to-left');
        }
    }
    public checkIsUpfrontpayment(): void {
        this.isUpfrontPaid = this.selectedDeal.product.productFee > 0 && !this.customerJourney.retentionsFeeToggle;
    }

    private anyPending(): boolean {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.accountStatus === "PENDING_SWITCH";
    }

    private isMultiLoan() {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.length > 1;
    }
}
