import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-deal-details-more-info-expandable',
    templateUrl: './deal-details-more-info-expandable.component.html',
    styleUrls: ['./deal-details-more-info-expandable.component.scss']
})
export class DealDetailsMoreInfoExpandableComponent implements OnInit {

    @Input() id: string;

    imgSource: any;
    open: boolean;

    ngOnInit() {
        this.open = false;
        this.imgSource = 'assets/chevron-down-grey.svg';
    }

    toggleIcon() {
        this.open = !this.open;
        if (this.open) {
            this.imgSource = 'assets/collapse-button.png';
        } else {
            this.imgSource = 'assets/chevron-down-grey.svg';
        }
    }

    public onMouseOver(): void {
        if (! this.open) {
            this.imgSource = 'assets/chevron-down-red.svg';
        }
    }

    public onMouseLeave(): void {
        if (! this.open) {
            this.imgSource = 'assets/chevron-down-grey.svg';
        }
    }
}
