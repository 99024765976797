import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'monthFromDate'
})
export class MonthFromDatePipe implements PipeTransform {

  transform (value: any): any {
    try {
      const numberOfFirstMonth = value.split('/')[1];
      switch (numberOfFirstMonth) {
        case '01': return 'January';
        case '02': return 'February';
        case '03': return 'March';
        case '04': return 'April';
        case '05': return 'May';
        case '06': return 'June';
        case '07': return 'July';
        case '08': return 'August';
        case '09': return 'September';
        case '10': return 'October';
        case '11': return 'November';
        case '12': return 'December';
      }
    } catch (e) {
      // Only for when the app is loading async
    }
  }


    transform2 (value: any): any {
        try {
            const numberOfFirstMonth = value.split('/')[1];
            switch (numberOfFirstMonth) {
                case '01': return 'Jan';
                case '02': return 'Feb';
                case '03': return 'Mar';
                case '04': return 'Apr';
                case '05': return 'May';
                case '06': return 'Jun';
                case '07': return 'Jul';
                case '08': return 'Aug';
                case '09': return 'Sep';
                case '10': return 'Oct';
                case '11': return 'Nov';
                case '12': return 'Dec';
            }
        } catch (e) {
            // Only for when the app is loading async
        }
    }
}
