export class TransferRequest {

    productCode: string;
    loansSelected: SelectedLoan[];
    feeAddedInMortgage: boolean;
    mobileNumber: string;
    emailAddress: string;
}

export class SelectedLoan {
    loanScheme: string;
    sequenceNumber: number;
}
