import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {animate, AnimationBuilder, AnimationFactory, group, query, style} from '@angular/animations';

@Component({
  selector: 'app-cross-fading-element',
  templateUrl: './cross-fading-element.component.html',
  styleUrls: ['./cross-fading-element.component.scss']
})
export class CrossFadingElementComponent {

    @Input() dataQaId = '' + this.getRandomInt(100); // To avoid clashes in ids if not dataQaId added

    @ViewChild('crossFadeHolder', {static: false}) crossFadeHolder: ElementRef;
    @ViewChild('crossFadeElement1', {static: false}) crossFadeElement1: ElementRef;
    @ViewChild('crossFadeElement2', {static: false}) crossFadeElement2: ElementRef;

    private state = true;

    public holderId = `cross-fade-holder-${this.dataQaId}`;
    public element1Id = `cross-fade-element-1-${this.dataQaId}`;
    public element2Id = `cross-fade-element-2-${this.dataQaId}`;


    constructor(private animationBuilder: AnimationBuilder) {

        this.holderId = `cross-fade-holder-${this.dataQaId}`;
        this.element1Id = `cross-fade-element-1-${this.dataQaId}`;
        this.element2Id = `cross-fade-element-2-${this.dataQaId}`;
    }

    public toggleState(): void {
        this.state = !this.state;
        this.buildAndPlayAnimation();
    }

    public showElement1(): void {
        this.state = true;
        this.buildAndPlayAnimation();
    }

    public showElement2(): void {
        this.state = false;
        this.buildAndPlayAnimation();
    }

    private buildAndPlayAnimation() {

        const animationFactory: AnimationFactory = this.animationBuilder.build([
            group([
                query(this.state ? `#${this.element2Id}` : `#${this.element1Id}`, [
                    style({
                        position: 'absolute',
                        opacity: 1,
                        top: 0,
                        right: 0,
                        left: 0,
                        height: Math.max(this.state ? this.crossFadeElement1.nativeElement.clientHeight : this.crossFadeElement2.nativeElement.clientHeight, this.crossFadeHolder.nativeElement.clientHeight),
                    }),
                    animate('.200s', style({
                        opacity: 0,
                        visibility: 'hidden',
                    })),
                    style({
                        top: 'auto',
                        bottom: 'auto',
                        right: 'auto',
                        height: '*',
                    })
                ]),
                query(this.state ? `#${this.element1Id}` : `#${this.element2Id}`, [
                    style({
                        position: 'static',
                        opacity: 0,
                        height: this.crossFadeHolder.nativeElement.clientHeight,
                        visibility: 'visible',
                    }),
                    animate('.400s', style({
                        opacity: 1,
                        height: '*',
                    })),
                ])
            ])
        ]);

        animationFactory.create(this.crossFadeHolder.nativeElement).play();
    }

    private getRandomInt(max): number {
        return Math.floor(this.getRandomNumber() * Math.floor(max));
    }

    private getRandomNumber(): number {
        const crypto = window.crypto;
        const randomIntegerResults = new Uint16Array(1);
        crypto.getRandomValues(randomIntegerResults);
        const randomInteger = randomIntegerResults[0];
        const randomNumString = '0.' + randomInteger;
        return +randomNumString;
    }
}
