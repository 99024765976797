import {Component, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Subscription} from 'rxjs';
import {MessageService} from '@core/services/backend/modal-comms';
import 'rxjs-compat/add/operator/take';
import {BrowserInfo} from '@core/model/browser-info';
import {CookieService} from '@core/services/backend/cookie/cookie.service';
import {ModalService} from '@core/services/modal-service/modal.service';
import {ConfigService} from '@core/services/backend/config/config.service';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    adobeUrlRendered = false;
    configData: any = {};
    subscription: Subscription;
    browserInfo: BrowserInfo;

    constructor(@Inject(DOCUMENT) private doc: any,
                private messageService: MessageService,
                private modalService: ModalService,
                private configService: ConfigService,
    ) {
        this.browserInfo = new BrowserInfo(new CookieService());

    }

    ngOnInit() {

        this.subscription = this.messageService.getModal().subscribe(modalType => {
            this.modalService.open(modalType);
        });

        this.configService.fetchConfigASync().subscribe(configResponse => {
            this.configData = configResponse;

            this.initialize().then(() => {
                this.addPageBottom();
            });

        });
    }

    addPageBottom() {
        const adobePageBottomElement = this.doc.createElement('script');
        adobePageBottomElement.type = 'text/javascript';
        adobePageBottomElement.text = 'try{_satellite.pageBottom()}catch(e){}';

        const adobeContextData = this.doc.createElement('script');
        adobeContextData.type = 'text/javascript';
        adobeContextData.text = '        var adobeContextData = {\n' +
            '        \'errorCode\': null,\n' +
            '        \'errorMsg\': {},\n' +
            '        \'channel\': \'\',\n' +
            '        \'subsection1\': \'\',\n' +
            '        \'subsection2\': \'\',\n' +
            '        \'pageIdentifier\': \'\',\n' +
            '        \'productIdentifier\': \'103\',\n' +
            '        \'transactionName\': \'\'\n' +
            '        };';

        const body = this.doc.getElementsByTagName('body')[0];

        body.appendChild(adobePageBottomElement);
        body.appendChild(adobeContextData);

    }

    initialize(): Promise<string> {
        return new Promise((resolve, reject) => {
            this.addAdobeAnalyticsUrl().then(() => {
                (<any>window)._satellite.pageBottom();
                resolve('Adobe URL Script added');
            }).catch(() => {
                reject('Adobe URL Script failed to add to page');
            });
        });
    }

    addAdobeAnalyticsUrl(): Promise<string> {

        return new Promise((resolve, reject) => {

            if (this.adobeUrlRendered) {
                resolve('already loaded');
            } else {
                const script = this.doc.createElement('script');
                script.type = 'text/javascript';
                script.src = this.configData.adobeAnalyticsUrl;

                if (script.readyState) {
                    script.onreadystatechange = () => {
                        if (script.readyState === 'loaded' || script.readyState === 'complete') {
                            script.onreadystatechange = null;
                            this.adobeUrlRendered = true;
                            resolve('loaded');
                        }
                    };
                } else {
                    script.onload = () => {
                        this.adobeUrlRendered = true;
                        resolve('loaded');
                    };
                }
                script.onerror = () => {
                    this.adobeUrlRendered = false;
                    reject('not loaded');
                };
                const head = this.doc.getElementsByTagName('head')[0];
                head.appendChild(script);
            }
        });
    }
}
