import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Loan} from '@core/model/retentions-service-eligibility-response';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {CurrencyPipe, DecimalPipe} from '@angular/common';

@Component({
    selector: 'app-current-deal-multi-loan',
    templateUrl: './current-deal-multi-loan.component.html',
    styleUrls: ['./current-deal-multi-loan.component.scss', '../current-deal.component.scss']
})
export class CurrentDealMultiLoanComponent implements OnInit {

    public eligibleLoans: Loan[];
    public ineligibleLoans: Loan[];
    public collapsed: boolean;
    public imgSource: string;

    @ViewChild('currentDealMulti', {static: false})
    private currentDealMulti: ElementRef;

    constructor(
        private customerJourney: CustomerJourney,
        private currencyPipe: CurrencyPipe,
        private decimalPipe: DecimalPipe) {
    }

    public ngOnInit(): void {
        // on page load, scroll to top.
        window.scroll(0, 0);
        this.collapsed = true;
        this.initializeChevron();
        this.instantiateLoanArrays();
    }

    private initializeChevron(): void {
        this.imgSource = 'assets/expand-button.png';
    }

    private instantiateLoanArrays(): void {

        const localEligibleLoans = [];
        const localIneligibleLoans = [];

        this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.forEach(function (loan) {
            if (loan.eligibleToTransfer) {
                localEligibleLoans.push(loan);
            } else {
                localIneligibleLoans.push(loan);
            }
        });

        this.eligibleLoans = localEligibleLoans;
        this.ineligibleLoans = localIneligibleLoans;
    }

    public getNumberOfLoans(): string {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.length + ' loans';
    }


    public onMouseOver(): void {
        this.imgSource = this.collapsed
            ? 'assets/chevron-down-red.svg'
            : 'assets/collapse-button.png';
    }

    public onMouseLeave(): void {
        if (this.collapsed) {
            this.imgSource = 'assets/expand-button.png';
        }
    }

    public getAccountOutstandingBalance(): string {
        let outstandingBalance = 0;
        try {
            outstandingBalance = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans
                           .map(loan => loan.loanBalance)
                           .reduce((totalIteration: number, currentValue: number) => totalIteration + currentValue, 0);
        // const outstandingBalance = this.customerJourney.accountDetailsResponse.data.outstandingBalance;
        } catch (e) {}
        
        return this.currencyPipe.transform(outstandingBalance, 'GBP');
    }

    public getAccountMonthlyPayment(): string {
        let total = 0;
        try {
            total = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans
                .map(loan => loan.monthlyPayment)
                .reduce((totalIteration: number, currentValue: number) => totalIteration + currentValue, 0);
        } catch (e) {}
        return this.currencyPipe.transform(total, 'GBP');
    }

    public isEligibleLoanSelected(i: number): string {
        return this.eligibleLoans[i].selected ? 'Yes' : 'No';
    }

    public getEligibleLoanInterestRate(i: number): string {
        return this.decimalPipe.transform(this.eligibleLoans[i].interestRate, '1.2-2');
    }

    public getEligibleLoanBalance(i: number): number {
        return this.eligibleLoans[i].loanBalance;
    }

    public getEligibleMonthlyPayment(i: number): number {
        return this.eligibleLoans[i].monthlyPayment;
    }

    public getNotEligibleLoanInterestRate(i: number): string {
        return this.decimalPipe.transform(this.ineligibleLoans[i].interestRate, '1.2-2');
    }

    public getNotEligibleLoanBalance(i: number): number {
        return this.ineligibleLoans[i].loanBalance;
    }

    public getNotEligibleMonthlyPayment(i: number): number {
        return this.ineligibleLoans[i].monthlyPayment;
    }

    public getNumberOfSelectedLoans(): number {
        return this.eligibleLoans.filter(loan => loan.selected).length;
    }

    public getNumberOfSelectedLoansHeader(): string {
        const numberOfSelectedloan = this.eligibleLoans.filter(loan => loan.selected).length;
        if (numberOfSelectedloan > 1) {
            return numberOfSelectedloan + ' loans';
        } else {
            return numberOfSelectedloan + ' loan';
        }
    }

    public click(): void {
        this.updateCollapedStatus();
    }

    private updateCollapedStatus(): void {
        setTimeout(() => {
            try {
                this.collapsed = this.currentDealMulti.nativeElement.classList.contains('collapsed');
                this.updateChevronIcon();
            } catch (e) {
            }
        }, 200);
    }

    public updateChevronIcon(): void {
        if (this.collapsed) {
            this.imgSource = 'assets/expand-button.png';
        } else {
            this.imgSource = 'assets/collapse-button.png';
        }
    }
}
