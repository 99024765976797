import {Pipe, PipeTransform} from '@angular/core';
import {OrdinalPipe} from '@core/pipes/ordinal/ordinal.pipe';

@Pipe({
    name: 'dayOfMonthFromDate'
})
export class DayOfMonthFromDatePipe extends OrdinalPipe implements PipeTransform {

    transform(date: string): string {
        const day: number  = +date.split('/')[0].replace(/^0+/, '');
        return super.transform(day);
    }
}
