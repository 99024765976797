import {Component, OnInit, ViewChild, HostListener} from '@angular/core';
import {ViewTransition} from '@core/animations/view-transition/view-transition';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomerJourney} from "@core/model/customer-journey.model";
import {AdobeAnalyticsService} from '@core/services/backend/analytics/adobe-analytics.service';
import { CallUsSideBarComponent } from '@components/shared/call-us-side-bar/call-us-side-bar.component';
import { CommonService } from '@core/services/common/common.service';

type AnimationStates = 'coming-from-left' | 'coming-from-right' | 'going-to-left' | 'going-to-right';

@Component({
    selector: 'app-compare-deals',
    templateUrl: './compare-deals.component.html',
    styleUrls: ['./compare-deals.component.scss'],
    animations: [ViewTransition()]
})
export class CompareDealsComponent implements OnInit {

    animationState: AnimationStates = 'coming-from-right';

    @ViewChild('callUsSideBar')
    callUsSideBarComponent: CallUsSideBarComponent;

    constructor(
        private router: Router,
        private customerJourney: CustomerJourney,
        private route: ActivatedRoute,
        private adobeAnalyticsService: AdobeAnalyticsService,
        private commonService: CommonService) {
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        this.commonService.setHideCallusSideBarFlag(false);
        this.callUsSideBarComponent.onClick();
      }

    ngOnInit() {
        window.scroll(0, 1);
        this.setUpNavigation();
        this.adobeAnalyticsService.sendPageViewEvent('compare', this.isPendingSwitch(), true, this.isMultiLoan());
    }

    public isPendingSwitch(): boolean {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.accountStatus === "PENDING_SWITCH";
    }

    private setUpNavigation(): void {
        this.route.queryParams.subscribe(params => {
            if (params.from !== null && params.from === 'back') {
                this.animationState = 'coming-from-left';
            }
        });
    }

    public clickBackButton(): void {
        this.animationState = 'going-to-right';
    }

    public navigate() {
        if (this.animationState === 'going-to-right') {
            this.router.navigate(['/retentions/deals-list'], {queryParams: {from: 'back'}});
        } else if (this.animationState === 'going-to-left') {
            this.router.navigate(['/retentions/deal-details']);
        }
    }

    isMultiLoan() {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.length > 1;
        // return this.customerJourney.accountDetailsResponse.data.loans.length > 1;
    }

    // public showThird(): boolean {
    //   return false;
    // }
    public goToViewDeal(columnSelected: number) {
        console.log('In compare-deals:' + columnSelected);
        this.updateSelectedDeal(columnSelected);
        this.customerJourney.retentionsPageObject.comingFromCompareDeals = true;
        this.animationState = 'going-to-left';
    }

    private updateSelectedDeal(columnSelected: number) {
        const dealsWithCheckBoxSelected = this.customerJourney.retentionsDealsResponse.response.deals
            .filter(deal => deal.compareCheckboxSelected);
        this.customerJourney.retentionsPageObject.selectedDeal = dealsWithCheckBoxSelected[columnSelected - 1];
    }

    public showThird(): boolean {
        return this.customerJourney.retentionsDealsResponse.response.deals
            .filter(deal => deal.compareCheckboxSelected)
            .length > 2;
    }
}
