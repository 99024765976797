import {Component, Input} from '@angular/core';
import {BodyLineBase} from '@components/shared/collapsible-table/types/body-line-base';
import {BodyLine} from '@components/shared/collapsible-table/types/body-line';
import {BodyLineCompound} from '@components/shared/collapsible-table/types/body-line-compound';
import {BodyLineIndented} from '@components/shared/collapsible-table/types/body-line-indented';
import {ModalService} from '@core/services/modal-service/modal.service';

@Component({
    selector: 'app-collapsible-table-body',
    templateUrl: './collapsible-table-body.component.html',
    styleUrls: ['./collapsible-table-body.component.scss']
})
export class CollapsibleTableBodyComponent {

    @Input() qaAffix: string;
    @Input() bodyElements: Array<BodyLineBase>;

    show: boolean = false;

    constructor(private modalService: ModalService) {
    }


    isBodyLine(element: BodyLineBase): boolean {
        return element instanceof BodyLine;
    }

    isBodyLineCompound(element: BodyLineBase) {
        return element instanceof BodyLineCompound;
    }

    isBodyLineIndented(element: BodyLineBase) {
        return element instanceof BodyLineIndented;
    }

    getCompoundValues(element: BodyLineBase) {
        return (element as BodyLineCompound).compoundValues;
    }

    getResult(element: BodyLineBase) {
        return (element as BodyLineCompound).result;
    }

    loadModal(info: string) {
        if (info) {
            this.modalService.open(info);
        }
    }
}
