import {Component, Input, OnInit} from '@angular/core';
import {CollapsibleTableAnimation} from "@components/shared/collapsible-table/collapsible-table-animation";
import {CollapsibleTableData} from "@components/shared/collapsible-table/types/collapsible-table-data";

@Component({
    selector: 'app-collapsible-table',
    templateUrl: './collapsible-table.component.html',
    styleUrls: ['./collapsible-table.component.scss'],
    animations: [CollapsibleTableAnimation()]
})
export class CollapsibleTableComponent implements OnInit {

    @Input() open = false;
    @Input() collapsible = true;
    @Input() data: CollapsibleTableData;
    @Input() qaAffix: string;

    openOnPageLoad: boolean;

    ngOnInit() {
        this.openOnPageLoad = this.open;
    }

    public getShowHideAdditionalText(): string {
        return this.open
            ? 'Show less'
            : 'Show more';
    }

    public getShowHideAdditionalImg(): string {
        return this.open
            ? 'assets/components/shared/collapsible-table/icon-show-less.svg'
            : 'assets/components/shared/collapsible-table/icon-show-more.svg';
    }
}
