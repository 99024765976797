import { Component } from '@angular/core';

@Component({
  selector: 'app-full-separator',
  templateUrl: './full-separator.component.html',
  styleUrls: ['./full-separator.component.scss']
})
export class FullSeparatorComponent {


}
