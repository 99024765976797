import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BrowserInfo} from "@core/model/browser-info";
import {CookieService} from "@core/services/backend/cookie/cookie.service";

/*
  Breadcrumbs component.
  How to use:
  - Param: animationEnabled -> boolean for enablind o disabling the component
  - Param: items         -> array of arrays.
        + Each array has two items
            1 - Text to be shown in the element
            2 - Url for the routerLink to have in that element. If url is not provided,
                only text without linkable element will be created.

  Example:
  <app-breadcrumbs
    [animationEnabled]="true"       <-- The animation is enabled
    [items]=
    "[['My Mortgage', '/olb/app/mymortgage],  <-- With URL
    ['Hub', '#']] ,            <-- With hash
    ['Overpayments', '']],     <-- No url so it will not be a link
    >
  </app-breadcrumbs>
*/

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    animations: [
        trigger('slideDown', [
            state('void', style({
                transform: 'translateY(-40%)'
            })),
            transition('void => *', animate('600ms'))
        ])
    ]
})
export class BreadcrumbsComponent implements OnInit {

    private _animationEnabled = true;
    private _items: string[][];
    private browserInfo: BrowserInfo;

    constructor() {
        this.browserInfo = new BrowserInfo(new CookieService());
    }


    ngOnInit() {

        if (this.browserInfo.isNative()) {
            this._items = this._items.slice(1);
            if (this._items.length === 1) {
                this._items = null;
            }
        }
    }

    @Input() set items(properties: string[][]) {
        this._items = properties;
    }

    get items(): string[][] {
        return this._items;
    }

    @Input() set animationEnabled(animationEnabled: boolean) {
        this._animationEnabled = animationEnabled;
    }

    get animationEnabled(): boolean {
        return this._animationEnabled;
    }
}
