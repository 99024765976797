import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-data-review-field-highlight',
    templateUrl: './data-review-field-highlight.component.html',
    styleUrls: ['./data-review-field-highlight.component.scss']
})
export class DataReviewFieldHighlightComponent {

    @Input() label: string;
    @Input() value: string;
    @Input() editOption: string;
    @Input() qaAffix: string;
    @Input() highlightLabel: boolean;

    @Output() panelClickedChange = new EventEmitter<string>();


    public goTo(): void {
        this.panelClickedChange.emit(this.editOption);
    }
}
