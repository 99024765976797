import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CookieService {
    accountNumber: any;


    isOpenedFromNativeApp(): boolean {
        const mhOrigin = this.getCookieValue('MHORIGIN');
        return mhOrigin !== null;
    }

    getAccountNumber(): string {
        let accountNumber = null;
        const accountCookie = this.getCookieValue('ESCECTX');

        if (accountCookie !== null) {
            accountNumber = accountCookie.slice((accountCookie.lastIndexOf(':')) + 7);
        }
        return accountNumber;
    }

    getSortCodeAndAccountNumber(): string {
        const accountCookie = this.getCookieValue('ESCECTX');
        if (accountCookie !== null) {
            return accountCookie.slice((accountCookie.lastIndexOf(':')) + 1);
        }
        return null;
    }

    getFullAccountNumber(): string {
        const accountCookie = this.getCookieValue('ESCECTX');
        if (accountCookie !== null) {
            return accountCookie.slice((accountCookie.lastIndexOf(':')) + 1);
        }
        return null;
    }

    public getFullAccountCookie(): string {
        return this.getCookieValue('ESCECTX');
    }

    public getCookieValue(name: string): string | null {
        const namePlusEquals = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            const c = ca[i].trim();
            if (c.indexOf(namePlusEquals) === 0) {
                return c.substring(namePlusEquals.length);
            }
        }
        return null;
    }

    setPageViewCookie(pageName: string) {

        const value = parseInt(this.getCookieValue(pageName), 10);
        if (!value) {
            document.cookie = pageName + '=1; path=/';
        } else {
            document.cookie = pageName + '=' + (value + 1) + '; path=/';
        }
    }

    getPageViewCookieValue(pageName: string): number {
        return parseInt(this.getCookieValue(pageName), 10);
    }

    public writeCookie(cookie: string, value: string): void {
        document.cookie = `${cookie}=${value}`;
    }
    public writeCookieInDomain(cookie: string, value: string, domain: string): void {
        document.cookie = `${cookie}=${value}; domain=${domain}; path=/;`;
    }

    public deleteCookie(cookie: string): void {
        document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }

    public createContractVariationCookies() {
        const cookieDomain = this.getContractVariationCookieDomain();
        this.writeCookieInDomain('MORTGAGE_CENTRE_contract_variation_account', this.getSortCodeAndAccountNumber(), cookieDomain);
        this.writeCookieInDomain('MORTGAGE_CENTRE_contract_variation_native_flag', this.isOpenedFromNativeApp().toString(), cookieDomain);
    }

    public getCurrentDomain(domain: string): string {
        const domainTokens: string[] = domain.split('.');
        const numberOfDomainParts: number = domainTokens.length;
        if (numberOfDomainParts > 3) {
            return '.' + domainTokens[numberOfDomainParts - 3] + '.' + domainTokens[numberOfDomainParts - 2] + '.' + domainTokens[numberOfDomainParts - 1];
        } else {
            return domain;
        }
    }

    public getContractVariationCookieDomain() {
        return this.getCurrentDomain(window.location.hostname);
    }

    getSortCode(): string {
        const accountCookie = this.getCookieValue('ESCECTX');
        if (accountCookie !== null) {
            return accountCookie.slice((accountCookie.lastIndexOf(':')) + 1, (accountCookie.lastIndexOf(':')) + 7);
        }
        return null;
    }

}
