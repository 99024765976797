import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewTransition } from '@core/animations/view-transition/view-transition';
import { CustomerJourney } from '@core/model/customer-journey.model';
import { TransferHeaderType } from '@components/views/retentions/commons/transfer-header/transfer-header-type';
import { ProgressIndicatorItem } from '@components/shared/progress-indicator/progress-indicator.component';
import { Deal } from '@core/model/retentions-service-deals-response';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AdobeAnalyticsService } from '@core/services/backend/analytics/adobe-analytics.service';
import { AccountDetailsData, Borrower } from '@core/model/account-details-response';
import { CommonService } from '@core/services/common/common.service';
import { CallUsSideBarComponent } from '@components/shared/call-us-side-bar/call-us-side-bar.component';
type AnimationStates = 'coming-from-left' | 'coming-from-right' | 'going-to-left' | 'going-to-right';

@Component({
    selector: 'app-contact-details',
    templateUrl: './contact-details.component.html',
    styleUrls: ['./contact-details.component.scss'],
    animations: [ViewTransition(),
    trigger('openClose', [
        // ...
        state('open', style({
            height: '!'
        })),
        state('closed', style({
            height: 0,
            paddingBottom: 0,
            paddingTop: 0
        })),
        transition('open => closed', [
            animate('0.25s')
        ]),
        transition('closed => open', [
            animate('0.25s')
        ]),
    ]),
    trigger('openCloseO', [
        // ...
        state('openO', style({
            opacity: 1
        })),
        state('closedO', style({
            opacity: 0
        })),
        transition('openO => closedO', [
            animate('0.25s')
        ]),
        transition('closedO => openO', [
            animate('0.25s')
        ]),
    ])]
})

export class ContactDetailsComponent implements OnInit {

    animationState: AnimationStates = 'coming-from-right';

    progressIndicator: ProgressIndicatorItem[];

    selectedDeal: Deal;

    hasEmail: boolean;
    emailHeader: string;
    emailAddress: string;
    emailBody: string;

    mobileHeader: string;
    mobileBody: string;

    emailInput: string;
    confirmEmailInput: string;
    phoneInput: string;
    showMessageEmailEmpty = false;
    showMessageEmailInvalid = false;
    showMessageConfirmEmailEmpty = false;
    showMessageConfirmEmailDontMatch = false;
    showValidationTick = false;
    showPhoneMsgError = false;
    showMessageEmailEmptyAnimationOn = false;
    topPosToStartShowing = 100;
    isMultiLoan = false;

    editEmailImage = 'assets/edit-icon-inside-circle-grey.svg';

    @ViewChild('callUsSideBar')
    callUsSideBarComponent: CallUsSideBarComponent;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private customerJourney: CustomerJourney,
        private adobeAnalyticsService: AdobeAnalyticsService,
        private commonService: CommonService
    ) {
        this.selectedDeal = this.customerJourney.retentionsPageObject.selectedDeal;
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        this.commonService.setHideCallusSideBarFlag(false);
        this.callUsSideBarComponent.onClick();
      }

    ngOnInit() {
        // on page load, scroll to top.
        window.scroll(0, 1);

        // this.hasEmail = this.customerJourney.accountDetailsResponse.data.borrowers[0].emailId !== null &&
        //     this.customerJourney.accountDetailsResponse.data.borrowers[0].emailId !== undefined &&
        //     this.customerJourney.accountDetailsResponse.data.borrowers[0].emailId !== '';

        this.setUpNavigation();
        this.decorateProgressIndicator();
        this.emailHeader = this.hasEmail ? 'Please check your email address' : 'Please provide your email address';
        this.emailAddress = "bob@hotmail.com";
        this.emailBody = `We'll keep you up-to-date with your change of deal by email. We'll only use the email address you give here to keep you up-to-date on your deal change.`;

        this.mobileHeader = 'Would you like text updates?';
        this.mobileBody = 'If you\'d also like to be kept up-to-date with your product transfer via text messages, please provide ' +
            'your mobile number below. We\'ll only use these details for updates of your transfer.';
        this.emailInput = '';
        this.confirmEmailInput = '';

        // const contactDetailsPhoneUpdated = this.customerJourney.retentionsPageObject.contactDetailsPhoneUpdated;
        this.phoneInput = '';

        this.isMultiLoan = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.length > 1;
        this.adobeAnalyticsService.sendPageViewEvent('contact-details', this.isPendingSwitch(), true, this.isMultiLoan);
    }

    public isPendingSwitch(): boolean {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.accountStatus === "PENDING_SWITCH";
    }

    setUpNavigation() {
        this.route.queryParams.subscribe(params => {
            if (params.from !== null && params.from === 'back') {
                this.animationState = 'coming-from-left';
            }
        });
    }

    public clickBackButton(): void {
        this.animationState = 'going-to-right';
    }

    public navigate() {

        if (this.animationState === 'going-to-left') {
            this.router.navigate(['/retentions/product-review']);
        } else if (this.animationState === 'going-to-right') {
            this.router.navigate(['/retentions/things-to-know'], { queryParams: { from: 'back' } });
        }
    }

    public goToOfferInformation(): void {
        this.isEmailValid();


        // this.isEmailValid();

        // this.isConfirmEmailValid();
        // this.isPhoneTextValid();
        //

        if (this.noValidationErrors()) {
            if (this.emailInput !== '') {
                this.prepareBorrowers();
                this.customerJourney.accountDetailsResponse.data.borrowers[0].emailId = this.emailInput;
                //     this.customerJourney.retentionsPageObject.contactDetailsEmailUpdated = true;
            }
            // this.customerJourney.retentionsPageObject.contactDetailsPhoneUpdated = true;
            // this.customerJourney.accountDetailsResponse.data.borrowers[0].mobileNumber = this.phoneInput;

            this.customerJourney.retentionsPageObject.isCTA = true;
            if (this.showValidationTick) {
                this.animationState = 'going-to-left';
            }
        } else {
            this.checkScroll();
        }
    }

    private prepareBorrowers() {
        if (this.customerJourney.accountDetailsResponse.data == undefined) {
            const borrower = new Borrower();
            const accountDetailsData = new AccountDetailsData();
            accountDetailsData.borrowers = [borrower];
            this.customerJourney.accountDetailsResponse.data = accountDetailsData;
        }
    }

    private noValidationErrors() {
        // return this.showMessageConfirmEmailEmpty === false &&
        //     this.showMessageConfirmEmailDontMatch === false &&
        //     this.showMessageEmailInvalid === false &&
        //     this.showMessageEmailEmpty === false &&
        //     this.showPhoneMsgError === false;
        return true;
    }

    public getType(): TransferHeaderType {
        return TransferHeaderType.CONTACT_DETAILS;
    }

    // public getTextForReadyToConfirm(): string {
    //     const term = this.customerJourney.retentionsPageObject.selectedDeal.product.term;
    //     const type = this.customerJourney.retentionsPageObject.selectedDeal.product.type;
    //     const rate = this.customerJourney.retentionsPageObject.selectedDeal.product.rate;
    //
    //     return `${term} ${type} at ${rate}%`;
    // }

    public isConfirmEmailValid() {
        console.log(this.emailInput.toString().length);
        if (this.confirmEmailInput === '' && !this.hasEmail) {
            this.showMessageConfirmEmailEmpty = true;
            this.showMessageConfirmEmailDontMatch = false;
        } else {
            this.showMessageConfirmEmailEmpty = false;
        }
        if (!(this.confirmEmailInput === '')) {
            this.confirmEmailInput.toLowerCase() === this.emailInput.toLowerCase() ?
                this.showMessageConfirmEmailDontMatch = false :
                this.showMessageConfirmEmailDontMatch = true;
        }

        if (this.showMessageConfirmEmailDontMatch ||  this.showMessageConfirmEmailEmpty) {
            this.adobeAnalyticsService.sendCTAAnalyticsEvent('Email address does not match');
        }
    }

    onKeyConfirmEmail(event: any) {
        event.stopImmediatePropagation();
        this.showMessageConfirmEmailEmpty = false;
        this.showValidationTick = false;
        this.showMessageEmailEmpty = false;
        this.showMessageConfirmEmailDontMatch = false;
        if (!(event.target.value === '') && !(this.emailInput === '') && !(this.confirmEmailInput === '')) {
            if (this.confirmEmailInput.toLowerCase() === this.emailInput.toLowerCase()) {
                this.emailInput.toString().length === this.confirmEmailInput.toString().length ?
                    this.showValidationTick = true :
                    this.showValidationTick = false;
                    if(this.showValidationTick){
                    this.adobeAnalyticsService.sendCTAAnalyticsEvent('email-entered');
                }
            }
        }

    }


    isEmailValid() {

        // const regex = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
        const regex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        if (this.emailInput === '' && !this.hasEmail) {
            this.showMessageEmailEmpty = true;
            this.showMessageEmailInvalid = false;
            this.showMessageEmailEmptyAnimationOn = true;
            this.adobeAnalyticsService.sendCTAAnalyticsEvent("Email address not entered");
        } else {
            this.showMessageEmailEmpty = false;
        }


        if (!(this.emailInput === '')) {
            regex.test(this.emailInput.toLowerCase()) ? this.showMessageEmailInvalid = false : this.showMessageEmailInvalid = true;
            if (this.emailInput.length <= 320) {
                const emailFirst = this.emailInput.substring(0, this.emailInput.indexOf("@"));
                const emailLast = this.emailInput.substring(this.emailInput.indexOf('@') + 1);
                if (emailFirst.length < 65 && emailLast.length < 256) {
                    regex.test(this.emailInput.toLowerCase()) ? this.showMessageEmailInvalid = false : this.showMessageEmailInvalid = true;
                } else {
                    this.showMessageEmailInvalid = true;
                }
            } else {
                this.showMessageEmailInvalid = true;
            }
        }

        if (!(this.confirmEmailInput === '')) {
            this.confirmEmailInput.toLowerCase() === this.emailInput.toLowerCase() ?
                this.showMessageConfirmEmailDontMatch = false :
                this.showMessageConfirmEmailDontMatch = true;
        }
        if(this.showMessageEmailInvalid){
            this.adobeAnalyticsService.sendCTAAnalyticsEvent("Email address entered incorrectly");
        }
    }

    isPhoneTextValid() {
        const numberRegex = /^[0-9]*$/g;
        if (this.phoneInput.length > 0) {
            if (this.phoneInput.length < 11) {
                this.showPhoneMsgError = true;
            } else if (this.phoneInput.substring(0, 2) !== '07' && this.phoneInput.length > 0) {
                this.showPhoneMsgError = true;
            } else if (this.phoneInput.indexOf(' ') > -1) {
                this.showPhoneMsgError = true;
            } else if (!numberRegex.test(this.phoneInput)) {
                this.showPhoneMsgError = true;
            } else {
                this.showPhoneMsgError = false;
            }
        }
    }

    showEmptyConfirmEmail() {
        return this.showMessageConfirmEmailEmpty;
    }

    showEmailDontMatch() {
        return this.showMessageConfirmEmailDontMatch;
    }

    showValidIcon() {
        return this.showValidationTick;
    }

    showValidationMessageEmailEmpty() {
        return this.showMessageEmailEmpty;
    }

    showValidationMessageEmailInvalid() {
        return this.showMessageEmailInvalid;
    }

    showPhoneValidationError() {
        return this.showPhoneMsgError;
    }

    resetPhoneMsgError() {
        this.showPhoneMsgError = false;
    }

    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= this.topPosToStartShowing) {
            this.gotoTop();
        }
    }

    gotoTop() {
        const el: HTMLElement = document.getElementById('test');
        const currentOffset = el.offsetTop;
        window.scroll({
            top: currentOffset - 40,
            left: 0,
            behavior: 'smooth'
        });
    }

    editEmail() {
        this.hasEmail = false;
    }

    public updateEditEmailImage(action: string): void {
        if (action == 'enter') {
            this.editEmailImage = 'assets/edit-icon-inside-circle-blue.svg';
        } else {
            this.editEmailImage = 'assets/edit-icon-inside-circle-grey.svg';
        }
    }

    private decorateProgressIndicator() {

        this.progressIndicator = [{ label: 'Contact details', status: 'selected' },
        { label: 'Transfer review', status: 'second' },
        { label: 'Confirmation', status: 'third' }];
        // if (!this.customerJourney.retentionsFeeToggle && this.selectedDeal.product.productFee > 0) {
        //     this.progressIndicator = [{ label: 'Contact details', status: 'selected' },
        //     { label: 'Review', status: null },
        //     { label: 'Payment', status: null },
        //     { label: 'Mortgage Offer', status: null }];
        // }
        // else {
        //     this.progressIndicator = [{ label: 'Contact details', status: 'selected' },
        //     { label: 'Transfer review', status: 'second' },
        //     { label: 'Confirmation', status: 'third' }];
        // }
    }
    public enableContinueButton() {
        if (this.showValidationTick) {
            return true;
        } else {
            return false;
        }
    }
    public formatDealDescription(term: string, type: string): string {
        if (term.toLowerCase() === 'lifetime' && type.toLowerCase() === 'variable') {
            return 'Standard Variable Rate';
        } else {
            return `${term} ${type}`;
        }
    }


}
