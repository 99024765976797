import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { CustomerJourney } from '@core/model/customer-journey.model';
import { Deal, ErcStep, Product } from '@core/model/retentions-service-deals-response';
import { ViewTransition } from '@core/animations/view-transition/view-transition';
import { FullDatePipe } from '@core/pipes/full-date/full-date.pipe';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { AdobeAnalyticsService } from '@core/services/backend/analytics/adobe-analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@core/services/modal-service/modal.service';
import { CallUsSideBarComponent } from '@components/shared/call-us-side-bar/call-us-side-bar.component';
import { CommonService } from '@core/services/common/common.service';
import { Loan, CustomerDetailsAuthenticationData } from '@core/model/customerDetailsAuthenticationResponse';
type AnimationStates = 'coming-from-left' | 'coming-from-right' | 'going-to-left' | 'going-to-right';

@Component({
    selector: 'app-deal-details',
    templateUrl: './deal-details.component.html',
    styleUrls: ['./deal-details.component.scss'],
    animations: [ViewTransition()]
})
export class DealDetailsComponent implements OnInit {
    loans: Loan[];
    public eligibilityResponseView: CustomerDetailsAuthenticationData;
    selectedDeal: Deal;
    product: Product;
    animationState: AnimationStates = 'coming-from-right';

    productSubHeading: string;
    fixedProduct: boolean;
    lifetime: boolean;
    monthlyPaymentPounds: string;
    monthlyPaymentPence: string;

    initialTermCostPounds: string;
    initialTermCostPence: string;

    interestRateLabel: string;
    hasProductFee: boolean;
    feesToggleOn = false;
    feesToggleClickedManually = false;
    currentDate: string;
    tracker: boolean;
    counttoRemainingBalance: {};
    counttoDealCost: {};
    counttoMonthlyFee: {};
    showRemainingBalance: boolean;
    interestOnly : boolean;

    @ViewChild('callUsSideBar')
    callUsSideBarComponent: CallUsSideBarComponent;

    constructor(private customerJourney: CustomerJourney,
        private fullDate: FullDatePipe,
        private percentagePipe: PercentPipe,
        private fullDatePipe: FullDatePipe,
        private adobeAnalyticsService: AdobeAnalyticsService,
        private datePipe: DatePipe,
        private router: Router,
        private route: ActivatedRoute,
        private currencyPipe: CurrencyPipe,
        private modalService: ModalService,
        private commonService: CommonService
    ) {
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        this.commonService.setHideCallusSideBarFlag(false);
        this.callUsSideBarComponent.onClick();
      }

    ngOnInit() {

        // on page load, scroll to top.
        window.scroll(0, 1);

        this.setUpNavigation();
        if(this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse!= undefined && this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView != undefined) {
            this.eligibilityResponseView = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView;
            this.loans = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans;
        }
        if(this.customerJourney.retentionsPageObject.selectedDeal!= undefined) {
            this.selectedDeal = this.customerJourney.retentionsPageObject.selectedDeal;

        // if(this.selectedDeal.product.productFee === 0){
        //     this.customerJourney.retentionsFeeToggle = true;
        // }
        this.feesToggleOn = this.customerJourney.retentionsFeeToggle;

        if (this.selectedDeal.product.type === 'Fixed') {
            this.fixedProduct = true;
        }
        if (this.selectedDeal.product.term === 'Lifetime') {
            this.lifetime = true;
        }
        if (this.selectedDeal.product.type === 'Tracker') {
            this.tracker = true;
        }

        let monthlyPayment = this.selectedDeal.calculations.monthlyPaymentWithoutFee;
        if (this.feesToggleOn) {
            monthlyPayment = this.selectedDeal.calculations.monthlyPaymentWithFee;
        }

        let count = 0;
        this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.forEach((loans) => {
           if (loans.loanType.includes('I')) {
               count++;
           }
       });
        this.interestOnly = count >= 1;

        this.createSubHeading();
        this.setPoundsAndPenceMonthlyPayment(monthlyPayment);
        this.setPoundsAndPenceInitialTermCost(this.selectedDeal.product.initialTermCost);
        this.fixedProduct ? this.interestRateLabel = 'Initial interest rate' : this.interestRateLabel = 'Current interest rate';
        this.hasProductFee = this.selectedDeal.product.productFee > 0;
        this.customerJourney.hasProductFee = this.hasProductFee;
        this.setCurrentDate();
        this.adobeAnalyticsService.sendPageViewEvent('deal-summary', this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.accountStatus === "PENDING_SWITCH", true, this.isMultiLoan());

        if (this.hasProductFee) {
            this.adobeAnalyticsService.sendCTAAnalyticsEvent('retentions-journey:' +
                (this.customerJourney.retentionsFeeToggle ? 'fee-added' : 'fee-upfront'));
        } else {
            this.adobeAnalyticsService.sendCTAAnalyticsEvent('retentions-journey:no-product-fee');
        }

        this.showRemainingBalance = this.customerJourney.config.featureFlags.showRemainingBalance;
        }
    }

    public getSelectedMultiLoan(): string {
        if (this.customerJourney.selectedMultiLoan) {
            return 'loans';
        } else {
            return 'loan';
        }
    }

    setUpNavigation() {
        this.route.queryParams.subscribe(params => {
            if (params.from !== null && params.from === 'back') {
                this.animationState = 'coming-from-left';
            }
        });
    }

    isMultiLoan() {
        return this.loans?.length > 1;
    }

    getTotalLoans() {
        return this.loans.length;
    }

    getMessageFromFixedProduct() {
        if (this.isMultiLoan()) {
            return 'month for all '+ this.getTotalLoans()+ ' loan parts, including any not transferring today,';
        } else {
            return 'monthly';
        }
    }

    public clickBackButton(): void {
        this.animationState = 'going-to-right';
    }

    public navigate() {

        if (this.animationState === 'going-to-left') {
            this.router.navigate(['/retentions/things-to-know']);
        } else if (this.animationState === 'going-to-right') {
            if (this.customerJourney.retentionsPageObject.comingFromCompareDeals) {
                this.router.navigate(['/retentions/compare-deals'], { queryParams: { from: 'back' } });
            } else {
                this.router.navigate(['/retentions/deals-list'], { queryParams: { from: 'back' } });
            }
        }
    }

    private createSubHeading() {

        this.productSubHeading = 'Until ' + this.fullDate.transform(this.selectedDeal.product.chargeEndDate);
    }

    private setPoundsAndPenceMonthlyPayment(monthlyPayment: number) {
        const poundsAndPence: string = '' + monthlyPayment;
        const tokens: string[] = poundsAndPence.split('.');
        this.monthlyPaymentPounds = tokens[0];
        this.monthlyPaymentPence = this.padRightZerosToPences(tokens[1], 2);

    }

    private setPoundsAndPenceInitialTermCost(intialTermCost: number) {
        const poundsAndPence: string = '' + intialTermCost;
        const tokens: string[] = poundsAndPence.split('.');
        this.initialTermCostPounds = tokens[0];
        this.initialTermCostPence = this.padRightZerosToPences(tokens[1], 2);
    }

    openLearnMoreModal(modalName: string) {
        this.modalService.open(modalName);
    }

    toggleMortgageFees() {
        if (this.feesToggleOn) {
            this.feesToggleOn = false;
            this.setPoundsAndPenceMonthlyPayment(this.selectedDeal.calculations.monthlyPaymentWithoutFee);
            this.adobeAnalyticsService.sendCTAAnalyticsEvent('Retentions deal summary details toggle fee-upfront');
        } else {
            this.feesToggleOn = true;
            this.adobeAnalyticsService.sendCTAAnalyticsEvent('Retentions deal summary details toggle fee-added');
            this.setPoundsAndPenceMonthlyPayment(this.selectedDeal.calculations.monthlyPaymentWithFee);
        }
        // this.customerJourney.retentionsPageObject.selectedDeal.feesToggle = this.feesToggleOn;
        this.customerJourney.retentionsFeeToggle = this.feesToggleOn;
        this.feesToggleClickedManually = true;
    }

    private padRightZerosToPences(penceAmount: string, numberOfZeros: number) {
        penceAmount = penceAmount || '';
        while (penceAmount.length < numberOfZeros) {
            penceAmount += '0';
        }
        return penceAmount;
    }

    getSantanderRevisionaryRate() {
        return this.percentagePipe.transform(this.selectedDeal.product.santanderRevisionaryRate / 100, '1.2-2');
    }

    dealRevertingFORorSVR() {
         return this.selectedDeal.product.reversionProduct === 'FoR' || this.selectedDeal.product.reversionProduct === 'SVR';
    }


    showSFoRMoreInfo() {
        return this.selectedDeal.product.reversionProduct === 'FoR';
    }


    public showSVRMoreInfo() {
         return this.selectedDeal.product.reversionProduct === 'SVR';
    }



    getERCMoreInfoValue() {
        if (this.selectedDeal.product.ercApplicable === false) {
            return 'No charge';
        } else {
            const ercSteps: ErcStep[] = this.selectedDeal.product.ercSteps;
            return  this.percentagePipe.transform(this.commonService.calculateStepErcRate(ercSteps) / 100, '1.2-2');
        }
    }

    showTextWithERCCharge() {
        return this.selectedDeal.product.ercApplicable === true;
    }

    steppedERC() {
        return this.selectedDeal.product.ercSteps.length > 1;
    }

    getAnnualOverpaymentValue() {
        return this.selectedDeal.product.annualOverpaymentAllowancePercentage === 0 ? 'Unlimited' :
            this.percentagePipe.transform(this.selectedDeal.product.annualOverpaymentAllowancePercentage / 100);
    }

    isUnlimitedAnnualOverpaymentCharge() {
        return this.selectedDeal.product.annualOverpaymentAllowancePercentage === 0;
    }

    public setCurrentDate() {
        const timestamp = this.customerJourney.retentionsDealsResponse.info.timestamp;
        const date = this.datePipe.transform(timestamp, 'dd/MM/yyyy');
        this.currentDate = this.fullDatePipe.transform(date);
    }

    public goToNextPage(event: AnimationStates): void {
        this.animationState = event;
    }

    public getProductEndDate(): string {
        return this.fullDatePipe.transform(this.selectedDeal.product.chargeEndDate);
    }

    public getProductTerm(): string {
        return this.selectedDeal.product.term.toLowerCase();
    }

    public getRemainingBalanceStartValue(): number {
        if (!this.feesToggleClickedManually) {
            if (this.feesToggleOn) {
                return this.selectedDeal.calculations.remainingBalanceWithFee;
            } else {
                return this.selectedDeal.calculations.remainingBalanceWithoutFee;
            }
        }

        if (this.feesToggleOn) {
            return this.selectedDeal.calculations.remainingBalanceWithoutFee;
        } else {
            return this.selectedDeal.calculations.remainingBalanceWithFee;
        }
    }

    public getRemainingBalanceFinalValue(): number {
        if (!this.feesToggleClickedManually) {
            if (this.feesToggleOn) {
                return this.selectedDeal.calculations.remainingBalanceWithFee;
            } else {
                return this.selectedDeal.calculations.remainingBalanceWithoutFee;
            }
        }

        if (this.feesToggleOn) {
            return this.selectedDeal.calculations.remainingBalanceWithFee;
        } else {
            return this.selectedDeal.calculations.remainingBalanceWithoutFee;
        }
    }

    private getProductTermCostWithFee(deal: Deal) {
        return deal.product.termInMonths * deal.calculations.monthlyPaymentWithFee;
    }

    private getProductTermCostWithoutFee(deal: Deal) {
        return (deal.product.termInMonths * deal.calculations.monthlyPaymentWithoutFee) + deal.product.productFee;
    }

    public getDealCostStartValue(): number {


        if (!this.feesToggleClickedManually) {
            if (this.feesToggleOn) {
                return this.getProductTermCostWithFee(this.selectedDeal);
                // return this.selectedDeal.calculations.productTermCostWithFee;
            } else {
                return this.getProductTermCostWithoutFee(this.selectedDeal);
                // return this.selectedDeal.calculations.productTermCostWithoutFee;
            }
        }

        if (this.feesToggleOn) {
            return this.getProductTermCostWithoutFee(this.selectedDeal);
            // return this.selectedDeal.calculations.productTermCostWithoutFee;
        } else {
            return this.getProductTermCostWithFee(this.selectedDeal);
            // return this.selectedDeal.calculations.productTermCostWithFee;
        }
    }

    public getDealCostFinalValue(): number {
        if (!this.feesToggleClickedManually) {
            if (this.feesToggleOn) {
                return this.getProductTermCostWithFee(this.selectedDeal);
                // return this.selectedDeal.calculations.productTermCostWithFee;
            } else {
                return this.getProductTermCostWithoutFee(this.selectedDeal);
                // return this.selectedDeal.calculations.productTermCostWithoutFee;
            }
        }

        if (this.feesToggleOn) {
            return this.getProductTermCostWithFee(this.selectedDeal);
            // return this.selectedDeal.calculations.productTermCostWithFee;
        } else {
            return this.getProductTermCostWithoutFee(this.selectedDeal);
            // return this.selectedDeal.calculations.productTermCostWithoutFee;
        }
    }

    public getMonthlyFeeStartValue(): number {
        if (!this.feesToggleClickedManually) {
            if (this.feesToggleOn) {
                return this.selectedDeal.calculations.monthlyPaymentWithFee;
            } else {
                return this.selectedDeal.calculations.monthlyPaymentWithoutFee;
            }
        }

        if (this.feesToggleOn) {
            return this.selectedDeal.calculations.monthlyPaymentWithoutFee;
        } else {
            return this.selectedDeal.calculations.monthlyPaymentWithFee;
        }
    }

    public getMonthlyFeeFinalValue(): number {
        if (!this.feesToggleClickedManually) {
            if (this.feesToggleOn) {
                return this.selectedDeal.calculations.monthlyPaymentWithFee;
            } else {
                return this.selectedDeal.calculations.monthlyPaymentWithoutFee;
            }
        }

        if (this.feesToggleOn) {
            return this.selectedDeal.calculations.monthlyPaymentWithFee;
        } else {
            return this.selectedDeal.calculations.monthlyPaymentWithoutFee;
        }
    }

    public getMonthlyPayment(): string {

        const monthyPaymentWithoutFee = this.currencyPipe.transform(this.selectedDeal.calculations.monthlyPaymentWithoutFee, 'GBP', 'symbol', '1.2-2');
        const productFee = this.currencyPipe.transform(this.selectedDeal.product.productFee, 'GBP', 'symbol', '1.0-0');
        const monthlyPaymentWithFee = this.currencyPipe.transform(this.selectedDeal.calculations.monthlyPaymentWithFee, 'GBP', 'symbol', '1.2-2');

        if (!this.feesToggleOn && this.hasProductFee) {
            return `${monthyPaymentWithoutFee} + ${productFee}`;
        } else {
            return monthlyPaymentWithFee;
        }
    }

    public formatDealDescription(term: string, type: string): string {
        if (term.toLowerCase() === 'lifetime' && type.toLowerCase() === 'variable') {
            return 'Standard Variable Rate';
        } else {
            return `${term} ${type}`;
        }
    }

    public isRevertingToSVR(reversionProduct: string) {
          return reversionProduct === 'SVR';
      }
}
