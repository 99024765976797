import {Deal} from '@core/model/retentions-service-deals-response';
import {Loan} from '@core/model/customerDetailsAuthenticationResponse';

export type SortingMethod = 'INTEREST_RATE' | 'MONTHLY_PAYMENT' | 'TERM';

export class RetentionsPageObject {
    selectedDeal: Deal;
    isCTA: boolean;
    previousPage: string;
    sortingMethod: SortingMethod;
    unselectedTransferringLoans: Loan[];
    comingFromCompareDeals: boolean;
}
