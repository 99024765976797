import {animate, keyframes, style, transition, trigger} from '@angular/animations';

export function phoneIcon() {
    return trigger('phoneIcon', [
        transition('void => slide-in-from-right', [
            animate('1000ms ease-out',  keyframes([
                style({transform: 'translateX(175px)', opacity: 1}),
                style({transform: 'translateX(0)', opacity: 1})
            ]))
        ])
    ]);
}
