import { Component, OnInit, ViewEncapsulation, ViewChild, HostListener } from '@angular/core';
import { ViewTransition } from '@core/animations/view-transition/view-transition';
import { HintMessageTransition } from '@core/animations/hint-message-slide-down';
import { CustomerJourney } from '@core/model/customer-journey.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AdobeAnalyticsService } from '@core/services/backend/analytics/adobe-analytics.service';
import { ModalService } from '@core/services/modal-service/modal.service';
import moment from 'moment';
import { CurrencyPipe } from '@angular/common';
import { CallUsSideBarComponent } from '@components/shared/call-us-side-bar/call-us-side-bar.component';
import { CommonService } from '@core/services/common/common.service';
import {  Loan } from '@core/model/customerDetailsAuthenticationResponse';
import { FullDatePipe } from '@core/pipes/full-date/full-date.pipe';

type AnimationStates = 'coming-from-left' | 'coming-from-right' | 'going-to-left' | 'going-to-right';

@Component({
    selector: 'app-loan-selection',
    templateUrl: './loan-selection.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./loan-selection.component.scss'],
    animations: [ViewTransition(),
    HintMessageTransition()]
})
export class LoanSelectionComponent implements OnInit {

    continueButtonEnabled = false;
    animationState: AnimationStates = 'coming-from-right';
    loans: Loan[];
    transferringLoans: Loan[];
    eligibleLoans: Loan[];
    notEligibleLoans: Loan[];
    transferringHeader: string;
    transferringDesc: string;
    availableToTransferHeader: string;
    availableToTransferDesc: string;
    totalLoanCountTitle: string;
    notEligibleLoanSubtitle: string;
    displayTransferring = true;
    displayEligible = true;
    displayNotEligible = true;
    componentName: string;
    belowThreshold: boolean;
    minimumBalanceSwitching: number;
    selectedLoanCountMessage: string;
    totalSelectedBalance: number;

    displayMessages: boolean;
    totalLoanBalance: number = 0;
    totalMonthlyPayment: number = 0;
    overallRemainingTerm;
    remainingTerm: any;
    isMultiLoan = false;
    isTransferringLoans = false;


    loanSelectionModified: boolean = false;

    selectedTransferringLoans: number;

    @ViewChild('callUsSideBar')
    callUsSideBarComponent: CallUsSideBarComponent;

    constructor(
        private customerJourney: CustomerJourney,
        private adobeAnalyticsService: AdobeAnalyticsService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private currencyPipe: CurrencyPipe,
        private commonService: CommonService,
        private fullDatePipe: FullDatePipe,

    ) {
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        this.commonService.setHideCallusSideBarFlag(false);
        this.callUsSideBarComponent.onClick();
      }

    ngOnInit() {
        // on page load, scroll to top.
        window.scroll(0, 1);
        this.setUpNavigation();
        this.minimumBalanceSwitching = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.minimumBalanceForSwitching;
        this.componentName = 'loan-selection';
        // on page load, scroll to top.
        window.scroll(0, 0);
        this.transferringLoans = [];
        this.eligibleLoans = [];
        this.notEligibleLoans = [];
        this.totalSelectedBalance = 0;

        this.loans = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans;

        this.loans.forEach(loan => {
            this.totalLoanBalance += loan.loanBalance;
            this.totalMonthlyPayment += loan.monthlyPayment;
        });

        const moments = this.loans.map(d => moment(d.loanEndDate));
        this.remainingTerm = moment.max(moments);
        console.log(this.remainingTerm);

        const todaysDate = moment(new Date(), 'DD/MM/YYYY').format('YYYY-MM-D');
        const formatRemainingTerm = moment(this.remainingTerm._i, 'DD/MM/YYYY').format('YYYY-MM-DD');

        const date1 = moment(formatRemainingTerm);
        const date2 = moment(todaysDate);

        const years = date1.diff(date2, 'year');
        date2.add(years, 'years');

        const months = date1.diff(date2, 'months');
        date2.add(months, 'months');

        this.overallRemainingTerm = years + ' years ' + months + ' months ';

        this.isMultiLoan = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.length > 1;
        if (this.loans.length === 1) {
            this.redirectToDealsList();
        } else {
            this.setUpLoanSelection();
        }
    }

    getLoanPart() {
        if (this.loans.length === 1) {
            return '1 loan part'
        } else {
            return this.loans.length +' ' +'loan parts'
        }
    }

    getTotalBalance() {
        return this.currencyPipe.transform(this.totalLoanBalance, 'GBP');
    }

    getTotalMonthlyPayment() {
        return this.currencyPipe.transform(this.totalMonthlyPayment, 'GBP');
    }

    getOverallRemainingTerm() {
        return this.overallRemainingTerm;
    }
    setUpNavigation() {
        this.route.queryParams.subscribe(params => {
            if (params.from !== null && params.from === 'back') {
                this.animationState = 'coming-from-left';
            }
        });
    }

    private setUpLoanSelection() {
        this.buildLoanEligibiltyLists(this.loans);

        const allLoansCount = this.loans.length;
        const elligibleLoanCount = this.eligibleLoans.length;

        this.adobeAnalyticsService.sendPageViewEvent('loan-selection', this.isPendingSwitch(), true, this.isMultiLoan);
        this.adobeAnalyticsService.sendCTAAnalyticsEvent('Retentions loan-selection page. Eligible loans: '
            + elligibleLoanCount + '. Ineligible loans: ' + (allLoansCount - elligibleLoanCount));

        if (allLoansCount > 1 && elligibleLoanCount === 1 && this.transferringLoans.length === 0) {
            this.eligibleLoans[0].selected = true;
        }
        this.setTotalLoanCountTitle();
        this.setTitlesWithCounts();

        this.customerJourney.retentionsPageObject.previousPage = 'loan-selection';
        this.customerJourney.retentionsPageObject.isCTA = false;
    }

    private redirectToDealsList() {
        this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans[0].selected = true;
        this.router.navigate(['/retentions/deals-list']);

        this.applyRedirects();
    }

    onAnyCheckBoxClicked() {
        this.displayMessages = true;
        this.totalSelectedBalance = 0;
        this.customerJourney.retentionsPageObject.unselectedTransferringLoans = [];

        // for each eligible loan, if selected add loan amount to total selected balance


        let selectedLoanCount = 0;
        this.selectedTransferringLoans = 0;

        if (this.eligibleLoans.length > 0) {
            for (const loan of this.eligibleLoans) {
                if (loan.selected) {
                    selectedLoanCount++;
                    this.totalSelectedBalance += loan.loanBalance;
                }
            }
        }

        if (this.transferringLoans.length > 0) {
            for (const loan of this.transferringLoans) {
                if (loan.selected) {
                    this.selectedTransferringLoans++;
                    selectedLoanCount++;
                    this.totalSelectedBalance += loan.loanBalance;
                } else {
                    this.customerJourney.retentionsPageObject.unselectedTransferringLoans.push(loan);
                }
            }
        }

        this.belowThreshold = !(this.totalSelectedBalance >= this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.minimumBalanceForSwitching);
        this.continueButtonEnabled = this.totalSelectedBalance >= this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.minimumBalanceForSwitching;

        if (selectedLoanCount > 1) {
            this.selectedLoanCountMessage = selectedLoanCount + ' loans';
        } else if (selectedLoanCount === 1) {
            this.selectedLoanCountMessage = '1 loan';
        } else {
            this.selectedLoanCountMessage = '';
        }

    }

    private setTitlesWithCounts() {
        const transferringLoanCount = this.transferringLoans.length;
        const eligibleLoanCount = this.eligibleLoans.length;
        const notEligibleCount = this.notEligibleLoans.length;

        if (transferringLoanCount === 0) {
            this.displayTransferring = false;
        } else if (transferringLoanCount === 1) {
            this.transferringHeader = 'Transferring (1)';
            this.transferringDesc = 'The loan you\'re already transferring';
        } else {
            this.transferringHeader = 'Transferring (' + transferringLoanCount + ')';
            this.transferringDesc = 'The loans you\'re already transferring';
            this.isTransferringLoans = true;
        }

        if (eligibleLoanCount === 0) {
            this.displayEligible = false;
        } else if (eligibleLoanCount === 1) {
            this.availableToTransferHeader = 'Available to transfer (1)';
            this.availableToTransferDesc = 'Your other loan you could transfer';
        } else {
            this.availableToTransferHeader = 'Available to transfer (' + eligibleLoanCount + ')';
            this.availableToTransferDesc = 'Your other loans you could transfer';
        }

        if (notEligibleCount === 0) {
            this.displayNotEligible = false;
        } else if (notEligibleCount === 1) {
            this.notEligibleLoanSubtitle = 'Loan you can\'t transfer (1)';
        } else {
            this.notEligibleLoanSubtitle = 'Loans you can\'t transfer (' + notEligibleCount + ')';
        }
    }

    private setTotalLoanCountTitle() {
        const totalLoanCount = this.loans.length;

        let loanWord = 'loans';

        if (totalLoanCount === 1) {
            loanWord = 'loan';
        }

        this.totalLoanCountTitle = 'Your mortgage is made up of ' + totalLoanCount + ' ' + loanWord;
    }

    public clickContinueButton(): void {
        if (this.totalSelectedBalance === 0) {
            this.belowThreshold = true;

        }
        if (this.continueButtonEnabled) {
            if(this.transferringLoans.length > 0 && this.selectedTransferringLoans < this.transferringLoans.length) {
                this.adobeAnalyticsService.sendPageViewEvent('loan-selection-page-not-all-transferring-loans-selected', this.isPendingSwitch(), true, this.isMultiLoan);
                this.modalService.open('firstModalCustomerNotSelectingAllTransferringLoans');
            } else {
                this.customerJourney.retentionsPageObject.unselectedTransferringLoans = [];
                this.animationState = 'going-to-left';
            }
        }
    }

    private buildLoanEligibiltyLists(allLoans: Loan[]) {
        for (const loan of allLoans) {
            if (loan.eligibleToTransfer) {
                if(loan.productSwitchInfo?.pendingProductSwitch) {
                    this.transferringLoans.push(loan);
                }else {
                    this.eligibleLoans.push(loan);
                }
            } else {
                this.notEligibleLoans.push(loan);
            }
        }
    }

    private applyRedirects() {

        const previousPage = this.customerJourney.retentionsPageObject.previousPage;

        if (this.customerJourney.retentionsPageObject.isCTA && this.loans.length === 1 &&
            (previousPage === 'on-boarding' || previousPage === 'home')) {

            this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans[0].selected = true;
            this.customerJourney.retentionsPageObject.isCTA = true;
            this.animationState = 'going-to-left';
            this.router.navigate(['/retentions/deals-list']);
            this.continueButtonEnabled = true;
            return;
        } else if (this.loans.length === 1 && previousPage === 'deals-list') {
            this.animationState = 'going-to-right';
            this.router.navigate(['/retentions/on-boarding'], { queryParams: { from: 'back' } });
            this.continueButtonEnabled = true;
            return;
        }
    }

    public navigate() {
        if (this.animationState === 'going-to-left') {
            this.router.navigate(['/retentions/deals-list']);
        } else if (this.animationState === 'going-to-right') {
            this.router.navigate(['/retentions/on-boarding'], { queryParams: { from: 'back' } });
        }
    }

    public enableContinueButton(): boolean {
        if (this.belowThreshold) {
            return false;
        } else {
            return true;
        }
    }

    public nonEligiblModel() {
        this.modalService.open("notEligibleProduct");
    }

    public isPendingSwitch(): boolean {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.accountStatus === "PENDING_SWITCH";
    }


    getPendingSwitchDate() {
        const pendingSwitchDate = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.pendingSwitchDate;
        return this.fullDatePipe.transform(pendingSwitchDate);
    }
}
