import {DealLoanView} from '@core/model/retentions-service-deal-loans-response';
import {CollapsibleTableData} from '@components/shared/collapsible-table/types/collapsible-table-data';
import {HeaderBase} from '@components/shared/collapsible-table/types/header-base';
import {Header} from '@components/shared/collapsible-table/types/header';
import {Title} from '@components/shared/collapsible-table/types/title';
import {BodyLineBase} from '@components/shared/collapsible-table/types/body-line-base';
import {BodyLine} from '@components/shared/collapsible-table/types/body-line';
import {BodyLineCompound} from '@components/shared/collapsible-table/types/body-line-compound';
import {CurrencyPipe, DecimalPipe, PercentPipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {FullDatePipe} from '@core/pipes/full-date/full-date.pipe';
import {CustomerDetailsAuthenticationData} from '@core/model/customerDetailsAuthenticationResponse';
import {CommonService} from '@core/services/common/common.service';
import {TitleBase} from '@components/shared/collapsible-table/types/title-base';

@Injectable()
export class CollapsibleTableDataMapper {
    public eligibilityResponseView: CustomerDetailsAuthenticationData;
    private monthlyPayment: string;
    private isMultiLoan: boolean;
    private count: number = 0;
    private hasMultipleNotTransferring: boolean;


    constructor(private currencyPipe: CurrencyPipe,
                private percentagePipe: PercentPipe,
                private customerJourney: CustomerJourney,
                private fullDatePipe: FullDatePipe,
                private commonService: CommonService,
                private decimalPipe: DecimalPipe) {
    }

    private static getRepaymentType(repaymentType: string) {
        return repaymentType === 'REPAYMENT' ? 'Capital repayment' : 'Interest only';
    }

    private static getTransferringToday(loan: DealLoanView): string {
        return loan.transferring
            ? loan.previousProduct
            : 'Not transferring today';
    }

    public do(loan: DealLoanView): CollapsibleTableData {
        if (this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse !== undefined) {
            this.eligibilityResponseView = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView;
            this.isMultiLoan = this.eligibilityResponseView.loans.length > 1;
        }

        const collapsibleData = new CollapsibleTableData();
        collapsibleData.title = this.createTitle(loan);
        collapsibleData.header = this.createHeader(loan);
        collapsibleData.body = this.createBody(loan, this.isMultiLoan);

        return collapsibleData;
    }

    public getHeaderProductSubTitle(loan: DealLoanView): string {

        if (loan.variable && !(loan.productTitle.toLowerCase() === 'standard variable rate')) {
            const trackingRatePercentage = this.percentagePipe.transform(loan.trackingRate / 100, '1.2-2');
            return `Variable product tracks Bank of England base rate plus ${trackingRatePercentage}`;
        } else {
            return '';
        }
    }

    isPostalOffer(loan: DealLoanView) {
        return !this.customerJourney.retentionsFeeToggle && loan.productFee > 0;
    }

    private createTitle(loan: DealLoanView): TitleBase {
        this.hasMultipleNotTransferring = this.customerJourney.dealLoansResponse.response.loans.filter(dealLoan => !dealLoan.transferring).length > 1;

        const singleNotSwitchTitle = 'Loan part not changing today';
        const multipleNotSwitchTitle = 'Loan parts not changing today';
        if (!loan.transferring) {
            this.count += 1;
            if (this.count === 1) {
                if (this.hasMultipleNotTransferring) {
                    return new Title(
                        multipleNotSwitchTitle
                    )
                } else {
                    return new Title(
                        singleNotSwitchTitle
                    )
                }
            }
        }
        return '';
    }

    private createHeader(loan: DealLoanView): HeaderBase {
        const productTitle = loan.productTitle;
        const productSubTitle = this.getHeaderProductSubTitle(loan);
        const interestRateLabel = loan.variable ? 'Current interest rate' : 'Interest rate';
        const interestRate = this.percentagePipe.transform(loan.interestRate / 100, '1.2-2');

        if (this.customerJourney.retentionsFeeToggle) {
            this.monthlyPayment = this.currencyPipe.transform(loan.monthlyPaymentWithFee, 'GBP');
        } else {
            this.monthlyPayment = this.currencyPipe.transform(loan.monthlyPaymentWithoutFee, 'GBP');
        }

        const balanceLabel = this.isMultiLoan ? 'Loan balance' : 'Outstanding balance';
        const balance = this.currencyPipe.transform(loan.newOutstandingBalance, 'GBP');

        return new Header(
            productTitle, productSubTitle,
            interestRateLabel, interestRate,
            'Monthly payment', this.monthlyPayment,
            balanceLabel, balance
        );
    }

    private createBody(loan: DealLoanView, isMultiLoan: boolean): Array<BodyLineBase> {
        console.log(isMultiLoan);
        const bodyElements = new Array<BodyLineBase>();

        let transferringToday = CollapsibleTableDataMapper.getTransferringToday(loan);
        if (loan.transferring) {
            const interestRate = transferringToday.slice(transferringToday.indexOf('at ')+3, transferringToday.length-1 );
            const formattedInterestRate = this.decimalPipe.transform(interestRate,'1.2-2');
            transferringToday = transferringToday.replace(interestRate,formattedInterestRate);
        }
        bodyElements.push(new BodyLine('Transferring from', transferringToday));
        const retentionsFeeToggle = this.customerJourney.retentionsFeeToggle;


        if (loan.transferring) {
            if (loan.currentProductFixedTermFlag && loan.currentProductErcApplicable && this.isPostalOffer(loan)) {
                bodyElements.push(
                    new BodyLine('Deal starts', 'After your current deal ends'
                    ));
            } else if (loan.currentProductFixedTermFlag && !loan.currentProductErcApplicable && this.isPostalOffer(loan)) {
                bodyElements.push(
                    new BodyLine('Deal starts', 'After your current deal ends or in about 4 months\' time'
                    ));
            } else if (!loan.currentProductFixedTermFlag && this.isPostalOffer(loan)) {
                bodyElements.push(
                    new BodyLine('Deal starts', 'The day we get your signed acceptance'
                    ));
            } else if (loan.currentProductFixedTermFlag && loan.currentProductErcApplicable && !this.isPostalOffer(loan)) {
                bodyElements.push(
                    new BodyLine('Deal starts', 'After your current deal ends'
                    ));
            } else if (loan.currentProductFixedTermFlag && !loan.currentProductErcApplicable && !this.isPostalOffer(loan)) {
                bodyElements.push(
                    new BodyLine('Deal starts', 'After your current deal ends or in about 4 months\' time'
                    ));
            } else if (!loan.currentProductFixedTermFlag && !this.isPostalOffer(loan)) {
                bodyElements.push(
                    new BodyLine('Deal starts', 'The day you accept the deal'
                    ));
            }
        }

        if (loan.transferring || !this.isStandardVariableRateOrFollowOnRate(loan.productTitle)) {
            if (!loan.lifetimeTermFlag) {
                if (loan.variable && loan.transferring) {
                    if (loan.interestRateChange === 'HIGHER') {
                        bodyElements.push(new BodyLine('Deal end date', this.fullDatePipe.transform(loan.productEndDate)));
                    } else {
                        bodyElements.push(new BodyLine('Deal ends', loan.productEndDate + ' after your new deal starts'));
                    }
                } else {
                    bodyElements.push(new BodyLine('Deal end date', this.fullDatePipe.transform(loan.productEndDate)));
                }
            }
        }

        if (loan.variable) {
            const interestRateCompound = this.getBodyLineCompoundForInterestRate(loan);
            bodyElements.push(interestRateCompound);
        }

        if (retentionsFeeToggle) {
            if (loan.applyFeeInThisLoan) {
                const outStandingBalanceBodyLine = this.getBodyLineCompoundForOutstandingBalance(loan);
                bodyElements.push(outStandingBalanceBodyLine);
            }
        }

        const annualOverpaymentAllowance = this.getAnnualOverpaymentAllowance(loan);
        const earlyRepaymentCharge = this.getEarlyRepaymentCharge(loan);
        const santanderFollowOnRate = this.percentagePipe.transform(loan.santanderFollowOnRate / 100, '1.2-2');

        bodyElements.push(
            new BodyLine('Remaining term', loan.remainingTerm),
            new BodyLine('Repayment type', CollapsibleTableDataMapper.getRepaymentType(loan.repaymentType)),
            new BodyLine('Annual overpayment allowance', annualOverpaymentAllowance, 'product-review-allowance')
        )

        if (loan.ercSteps?.length > 1) {
            bodyElements.push(new BodyLine('Early Repayment Charge', earlyRepaymentCharge, 'has-erc-charge-with-stepping-down'));
        } else if (loan.ercSteps?.length === 1) {
            bodyElements.push(new BodyLine('Early Repayment Charge', earlyRepaymentCharge, 'has-erc-charge-without-stepping-down'));
        } else {
            bodyElements.push(new BodyLine('Early Repayment Charge', earlyRepaymentCharge, 'has-no-erc-charge'));
        }

        if (loan.reversionProduct === 'FoR') {
            bodyElements.push(new BodyLine('Santander Follow-on Rate', santanderFollowOnRate, 'product-review-for'));
        }
        if (loan.reversionProduct === 'SVR') {
            bodyElements.push(new BodyLine('Standard Variable Rate', santanderFollowOnRate, 'product-review-svr'));
        }
        return bodyElements;
    }

    private getBodyLineCompoundForOutstandingBalance(loan: DealLoanView): BodyLineCompound {

        const loanBalance = this.currencyPipe.transform(loan.loanBalance, 'GBP');
        const productFee = this.currencyPipe.transform(loan.productFee, 'GBP');
        const newOutstandingBalance = this.currencyPipe.transform(loan.newOutstandingBalance, 'GBP');

        if (this.isMultiLoan) {
            return new BodyLineCompound(
                [new BodyLine('Previous loan balance', loanBalance),
                    new BodyLine('Product fee added', productFee)],
                new BodyLine('New loan balance', newOutstandingBalance)
            );
        } else {
            return new BodyLineCompound(
                [new BodyLine('Outstanding balance', loanBalance),
                    new BodyLine('Product fee to be added', productFee)],
                new BodyLine('New outstanding balance', newOutstandingBalance)
            );
        }

    }

    private getBodyLineCompoundForInterestRate(loan: DealLoanView): BodyLineCompound {

        const bankOfEnglandBaseRate = this.percentagePipe.transform(loan.bankOfEnglandBaseRate / 100, '1.2-2');

        const trackingRate = this.getTrackingRate(loan);

        const interestRate = this.percentagePipe.transform(loan.interestRate / 100, '1.2-4');
        if (loan.productTitle.toLowerCase() === 'standard variable rate') {
            return new BodyLineCompound([],
                new BodyLine('Current interest rate', interestRate)
            );
        } else {
            return new BodyLineCompound(
                [new BodyLine('Bank of England base rate', bankOfEnglandBaseRate),
                    new BodyLine('Tracking the Bank of England base rate by', trackingRate)],
                new BodyLine('Current interest rate', interestRate)
            );
        }
    }

    private getTrackingRate(loan: DealLoanView): string {
        return Math.sign(loan.trackingRate) === 1
            ? `+${this.percentagePipe.transform(loan.trackingRate / 100, '1.2-2')}`
            : this.percentagePipe.transform(loan.trackingRate / 100, '1.2-2');
    }

    private getEarlyRepaymentCharge(loan: DealLoanView): string {
        console.log("loan response", loan)
        if (loan.transferring) {
            return loan.ercApplicable === false
                ? 'No charge'
                : this.percentagePipe.transform(this.commonService.calculateStepErcRate(loan.ercSteps) / 100, '1.2-2');
        } else {
            return loan.earlyRepaymentChargeAsAmount === 0
                ? 'No charge'
                : this.percentagePipe.transform(loan.earlyRepaymentChargeAsAmount / 100, '1.2-2');
        }

    }

    private getAnnualOverpaymentAllowance(loan: DealLoanView): string {
        return loan.annualOverpaymentAllowance === 0
            ? 'Unlimited'
            : this.percentagePipe.transform(loan.annualOverpaymentAllowance / 100, '1.2-2');
    }

    private isStandardVariableRateOrFollowOnRate(productTitle: string): boolean {
        const productTitleInLowercase = productTitle.toLowerCase();
        return productTitleInLowercase.includes('standard variable rate') || productTitleInLowercase.includes('santander\'s follow-on rate');
    }

}


