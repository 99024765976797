import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// import { HttpClient } from '@angular/common/http';
import { AccountValidationResponse } from '@core/model/accountValidationResponse';
import { CustomerDetailsAuthenticationResponse } from '@core/model/customerDetailsAuthenticationResponse';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { AccountData } from '@core/model/account-data';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public executeRecaptchaCall = new Subject<any>();
  public googleTokenSub = new Subject<string>();
  
  validationUrl: string;
  customerValidationUrl: string;

  public clientId = '';

  private selectedAccount = new BehaviorSubject<AccountData | null>(null);
  selectedAccount$ = this.selectedAccount.asObservable();

  setValidatedAccount(account: AccountData) {
    this.selectedAccount.next(account);
  }

  getValidatedAccount(): AccountData{
    return this.selectedAccount.getValue();
  }

  constructor(
          private http: HttpClient) {
     }

  validateAccountNumber(accountValidationUrl: string, accountNumber: string, token: string, recaptchaClientId: string, clientId: string): Observable<AccountValidationResponse> {

    console.log("This is the client id " + recaptchaClientId);
    this.clientId = clientId;
    
    this.validationUrl = (accountValidationUrl + accountNumber);
    const param = new HttpParams()
              .set("recaptchaResponse", token)
              .set("recaptchaClient", recaptchaClientId);
    
    return this.http.get<AccountValidationResponse>(this.validationUrl, this.buildHeaders(param));
  }

  validateCustomerDetails(customerDetailsUrl: string, accountNumber: string, surname: string, dateOfBirth: string, postcode: string, clientId: string): Observable<CustomerDetailsAuthenticationResponse> {
    this.customerValidationUrl = (customerDetailsUrl + accountNumber);
    this.clientId = clientId;
    // const headers = this.createHeaders(clientId);
    const param = new HttpParams()
              .set("surname", surname)
              .set("postCode", postcode)
              .set("dateOfBirth", dateOfBirth);
    
    return this.http.get<CustomerDetailsAuthenticationResponse>(this.customerValidationUrl, this.buildHeaders(param));

  }

public buildHeaders(queryParams) {
  const httpOptions = this.defaultHttpOptions;
  httpOptions.params = queryParams;
  return httpOptions as {};
}

  //  =========== properties ===================
  public get defaultHttpOptions() {
    // if (!this.config) { return null; }
    return {
      headers: {
          'Accept': 'application/json',
          'X-IBM-Client-Id': this.clientId,
          'Skip-Auth-Interceptor': 'Skip-Auth-Interceptor'
      },
      withCredentials: false,
      params: {},
      observe: 'response'
    };
  }
}
