import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-data-review-section-title',
    templateUrl: './data-review-section-title.component.html',
    styleUrls: ['./data-review-section-title.component.scss']
})
export class DataReviewSectionTitleComponent {

    @Input() title: string;
    @Input() qaAffix: string;


}
