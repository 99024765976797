import {Component, EventEmitter, HostListener, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {Deal, RetentionsServiceDealsResponse} from '@core/model/retentions-service-deals-response';
import {RetentionsService} from '@core/services/backend/retentions/retentions.service';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {ErrorHandler} from '@core/error-handler/error-handler';
import {CurrencyPipe, DatePipe} from '@angular/common';
import {FullDatePipe} from '@core/pipes/full-date/full-date.pipe';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {CookieService} from '@core/services/backend/cookie/cookie.service';
import {ViewTransition} from '@core/animations/view-transition/view-transition';
import {HintMessageTransition} from '@core/animations/hint-message-slide-down';
import {SelectedLoan, SelectedLoansRequest} from '@core/model/retentions-service-deals-request';
import {SortAnimation} from '@core/animations/deals-list-sorting';
import {AdobeAnalyticsService} from '@core/services/backend/analytics/adobe-analytics.service';
import {SortingMethod} from '@components/views/retentions/retentions-page-object';
import {StickyHeaderService} from '@core/services/common/sticky-header.service';
import {ShowCompareFooterAnimation} from '@core/animations/compare-footer-animation';
import {StickyFooterService} from '@core/services/common/sticky-footer.service';
import {AccountData} from '@core/model/account-data';
import {AccountService} from '@core/services/backend/retentions/account.service';
import {CallUsSideBarComponent} from '@components/shared/call-us-side-bar/call-us-side-bar.component';
import {CommonService} from '@core/services/common/common.service';
import {CustomerDetailsAuthenticationData,ProductSwitchInfo} from '@core/model/customerDetailsAuthenticationResponse';


type PageToNavigateTo = 'deal-details' | 'compare-deals';


@Component({
    selector: 'app-deals-list',
    templateUrl: './deals-list.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./deals-list.component.scss'],
    animations: [
        ViewTransition(),
        HintMessageTransition(),
        SortAnimation(),
        ShowCompareFooterAnimation()
    ]
})
export class DealsListComponent implements OnInit {

    deals: Deal[];
    retentionsDealsLoaded: boolean;
    showMonthlyPaymentWithFees: boolean;
    chevronOpen: boolean;
    downloadingPDF = false;
    imgSource: string;
    isMultiLoan = false;
    isMultiDeal = false;
    dealsCountMessage: string;
    availableDealsMessage: string;
    currentDate: string;
    pendingSwitchDate: string;
    animationState: string;
    isMenuHeaderStuck: boolean;
    isCompareFooterStuck: boolean;
    pageToNavigateTo: PageToNavigateTo;
    maximumSelectedDeals = false;
    dealsDataLoaded = false;
    MAXIMUM_ALLOWED_MOBILE = 2;
    MAXIMUM_ALLOWED_DESKTOP = 3;
    compareFooterAnimationState = 'hideCompareFooter';
    showCompareFooterDiv = false;
    firstTimeAnimationDone = true;
    phoneIconPixelsToTop = 0;
    accountData: AccountData;
    validatedAccount: string;
    selectedMultiLoan = false;
    pendingProduct: ProductSwitchInfo;
    eligibilityResponseView: CustomerDetailsAuthenticationData;
    @ViewChild('tooltipWaysToPay', {static: false}) waysToPayTooltip: NgbTooltip;
    @ViewChild('monthlyCost', {static: false}) monthlyCostTooltip: NgbTooltip;
    @ViewChild('tooltipCurrentDeal', {static: false}) currentDealTooltip: NgbTooltip;
    @ViewChild('tooltipCompareDeal', {static: false}) compareDealsTooltip: NgbTooltip;
    @ViewChild('callUsSideBar')
    callUsSideBarComponent: CallUsSideBarComponent;
    @Output() openChange = new EventEmitter<boolean>();
    counto: {};
    auxiliarDeals: Deal[];
    public showWarning: boolean;
    private tooltipsAlreadyShown = false;
    private arrayUrlsChrevronsImages = Array<string>();
    private previousOffsetTop: number;
    private lowestOffset = 9999;
    private hasProductFeesToggleBeenClicked: boolean;
    private dealsLengthWhenLoaded: number;

    constructor(
        private retentionsService: RetentionsService,
        private accountService: AccountService,
        private errorHandler: ErrorHandler,
        private customerJourney: CustomerJourney,
        private fullDatePipe: FullDatePipe,
        private datePipe: DatePipe,
        private router: Router,
        private cookieService: CookieService,
        private adobeAnalyticsService: AdobeAnalyticsService,
        private currencyPipe: CurrencyPipe,
        private stickyHeaderService: StickyHeaderService,
        private stickyFooterService: StickyFooterService,
        private commonService: CommonService
    ) {
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        this.commonService.setHideCallusSideBarFlag(false);
        this.callUsSideBarComponent.onClick();
    }

    ngOnInit() {
        this.accountData = this.accountService.getValidatedAccount();
        this.validatedAccount = this.accountData.accountNumber;
        this.eligibilityResponseView = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView;

        // on page load, scroll to top.
        window.scroll(0, 1);

        this.counto = {};
        if (!this.dealsExistsInCustomerJourney()) {
            this.customerJourney.retentionsFeeToggle = false;
            this.showMonthlyPaymentWithFees = false;
            this.retentionsService.fetchDealDataASync(this.customerJourney.config.retentionsServiceBaseUrl,
                this.validatedAccount,
                this.buildDealsRequest(),
                this.customerJourney.config.clientId,
                this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.access_token)
                .subscribe(
                    responseFromDealsService => {
                        console.log('response from retentions-deals-list received : ' + JSON.stringify(responseFromDealsService));
                        if (responseFromDealsService.info.status == 'ko') {
                            this.errorHandler.redirectToErrorView()
                        }

                        this.removeSvrProductIfCurrentlyOnSvrAndSingleLoan(responseFromDealsService, this.eligibilityResponseView);
                        this.customerJourney.retentionsDealsResponse = responseFromDealsService;
                        this.errorHandler.checkErrorsToHandle();
                        this.retentionsDealsLoaded = true;
                        this.deals = responseFromDealsService.response.deals;
                        this.dealsLengthWhenLoaded = this.deals.length;
                        this.sortDealsByInterest();
                        this.setDealCountMessage();
                        this.setAvailableDealMessage();
                        this.setCurrentDate();
                        this.animationState = 'fade-content-in';
                        this.initialiseArrayUrlsChrevronsImages();
                        this.initialiseCheckBoxStatus();
                        this.dealsDataLoaded = true;
                        this.resetAvailableToSelect();
                        if (responseFromDealsService.response.deals.length > 1) {
                            this.isMultiDeal = true;
                        }
                        console.log("Multideal is : " + this.isMultiDeal);
                        this.customerJourney.productsAvailableWithSteppedErcs = responseFromDealsService.response.productsAvailableWithSteppedErcs;
                    },
                    err => {
                        if (err.status == '406') {
                            this.errorHandler.redirectToHomePage();
                        } else {
                            this.errorHandler.redirectToErrorView();
                        }
                    }
                );
        } else {
            this.showMonthlyPaymentWithFees = this.customerJourney.retentionsFeeToggle;
            this.hasProductFeesToggleBeenClicked = this.customerJourney.retentionsFeeToggle;
            this.deals = this.customerJourney.retentionsDealsResponse.response.deals;
            if (this.numberOfCheckboxesSelected() >= this.maximumNumberOfDealsAllowed()) {
                this.maximumSelectedDeals = true;
            }
            this.dealsLengthWhenLoaded = this.deals.length;
            this.fixSortingByPreviouslySelected();
            this.setDealCountMessage();
            this.setAvailableDealMessage();
            this.setCurrentDate();
            this.initialiseArrayUrlsChrevronsImages();
            this.animationState = 'fade-content-in';
            this.dealsDataLoaded = true;
            this.retentionsDealsLoaded = true;
            this.isMultiDeal = this.customerJourney.retentionsDealsResponse.response.deals.length > 1;
        }
        this.isMultiLoan = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.length > 1;

        this.cookieService.setPageViewCookie('MORTGAGE_DEALS_' + this.customerJourney.fullAccountNumber);
        if (this.chevronOpen) {
            this.imgSource = 'assets/collapse-button.png';
        } else {
            this.imgSource = 'assets/expand-button.png';
        }
        this.customerJourney.retentionsPageObject.previousPage = 'deals-list';
        this.customerJourney.retentionsPageObject.isCTA = false;
        this.getAndSetPendingSwitchProduct();

        let selectedLoans = 0;
        for (const loan of this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans) {
            if (loan.selected) {
                selectedLoans++;
                const currentSelectedTerm = this.customerJourney.selectedLoansTerm;
                if (currentSelectedTerm < loan.remainingTerm) {
                    this.customerJourney.selectedLoansTerm = loan.remainingTerm;
                }
            }
        }
        this.selectedMultiLoan = (selectedLoans > 1);
        this.customerJourney.selectedMultiLoan = this.selectedMultiLoan;
        if (this.showCompareFooter()) {
            this.compareFooterAnimationState = 'showCompareFooter';
            this.showCompareFooterDiv = true;
        } else {
            this.compareFooterAnimationState = 'hideCompareFooter';
            this.showCompareFooterDiv = false;
        }

        this.adobeAnalyticsService.sendPageViewEvent('deals-table', this.isPendingSwitch(), true, this.isMultiLoan);
        this.adobeAnalyticsService.sendCTAAnalyticsEvent('Deals List Selected Loans: ' + selectedLoans);

        this.phoneIconPixelsToTop = this.calculatePixelsToTop();
        //window.onresize;
    }

    @HostListener('window:resize', ['$event'])
    onresize() {
        this.lowestOffset = 9999;
        this.phoneIconPixelsToTop = this.calculatePixelsToTop();
    }

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll() {
        const stickyModelHeader = this.stickyHeaderService.identifyIfStuckToTop('sticky-header', this.lowestOffset, this.previousOffsetTop);
        this.previousOffsetTop = stickyModelHeader.offsetTop;
        this.lowestOffset = stickyModelHeader.lowestOffset;
        this.isMenuHeaderStuck = stickyModelHeader.isMenuHeaderStuck;

        //Not used currently. It is to remove the border-shadow of the sticky compare-footer if needed.
        this.isCompareFooterStuck = this.stickyFooterService.identifyIfStuckToBottom('phone-icon', this.phoneIconPixelsToTop);
    }

    public calculatePixelsToTop(): number {
        const phoneIconHtmlElement: HTMLElement = document.getElementById('phone-icon');
        if (phoneIconHtmlElement !== null) {
            return phoneIconHtmlElement.getBoundingClientRect().top;
        } else {
            return 0;
        }
    }


    public changeShowCompareFooterStatus() {
        if (this.firstTimeAnimationDone) {
            this.firstTimeAnimationDone = false;
        } else {
            if (this.compareFooterAnimationState === 'hideCompareFooter') {
                this.showCompareFooterDiv = !this.showCompareFooterDiv;
            }
        }
        this.phoneIconPixelsToTop = this.calculatePixelsToTop();
    }

    public showCompareFooter(): boolean {
        return this.dealsDataLoaded && this.numberOfDeals() > 1 && this.numberOfDealsSelected() > 0;
    }

    public downloadDealsPDF() {
        this.adobeAnalyticsService.sendCTAAnalyticsEvent('Creating pdf');

        this.downloadingPDF = true;
        this.retentionsService.fetchDealPDFAsync(this.customerJourney.config.retentionsServiceBaseUrl,
            this.validatedAccount,
            this.buildDealsRequest(),
            this.customerJourney.config.clientId,
            this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.access_token);

        this.retentionsService.isDownloadPdfFinished$.subscribe(
            isDownloadPdfFinished => {
                this.downloadingPDF = !isDownloadPdfFinished;
            });
    }

    public getInterestRateLabel(i: number): string {
        if (this.deals[i].product.type === 'Tracker' || this.deals[i].product.type === 'Variable'){
            return 'Current interest rate';
        } else {
            return 'Interest rate';
        }
    }

    public setCurrentDate() {
        const timestamp = this.customerJourney.retentionsDealsResponse.info.timestamp;
        const date = this.datePipe.transform(timestamp, 'dd/MM/yyyy');
        this.currentDate = this.fullDatePipe.transform(date);
    }

    public sortDealsByInterest(listenToClicks?: string) {
        if (listenToClicks === 'customer clicked') {
            this.adobeAnalyticsService.sendCTAAnalyticsEvent('Retentions deals sort by interest');
        }

        this.customerJourney.retentionsPageObject.sortingMethod = 'INTEREST_RATE';

        this.rearrangePanelWithSorting(() => {
                this.deals = this.auxiliarDeals.sort((a, b) => {
                    if (a.product.rate < b.product.rate) {
                        return -1;
                    }
                    if (b.product.rate < a.product.rate) {
                        return 1;
                    }
                    return 0;
                });
            }
        );
    }

    public sortDealsByMonthlyPayment(listenToClicks?: string): void {
        if (listenToClicks === 'customer clicked') {
            this.adobeAnalyticsService.sendCTAAnalyticsEvent('Retentions deals sort by monthly payment');
        }

        this.customerJourney.retentionsPageObject.sortingMethod = 'MONTHLY_PAYMENT';

        this.rearrangePanelWithSorting(
            () => {
                this.deals = this.auxiliarDeals.sort((a, b) => {
                    // Change this two lines for picking with or without fee dependency in customer choice
                    const aMonthlyPayment = this.showMonthlyPaymentWithFees ? a.calculations.monthlyPaymentWithFee : a.calculations.monthlyPaymentWithoutFee;
                    const bMonthlyPayment = this.showMonthlyPaymentWithFees ? b.calculations.monthlyPaymentWithFee : b.calculations.monthlyPaymentWithoutFee;
                    if (aMonthlyPayment < bMonthlyPayment) {
                        return -1;
                    }
                    if (bMonthlyPayment < aMonthlyPayment) {
                        return 1;
                    }
                    return 0;
                });
            }
        );
    }

    public sortDealsByRemainingTerm(listenToClicks?: string): void {
        if (listenToClicks === 'customer clicked') {
            this.adobeAnalyticsService.sendCTAAnalyticsEvent('Retentions deals sort by remaining term');
        }

        this.customerJourney.retentionsPageObject.sortingMethod = 'TERM';

        this.rearrangePanelWithSorting(
            () => {
                this.deals = this.auxiliarDeals.sort((a, b) => {
                    // Change this two lines for picking with or without fee dependency in customer choice
                    const numberOfYearsA = this.extractNumberOfYears(a.product.term);
                    const numberOfYearsB = this.extractNumberOfYears(b.product.term);
                    if (numberOfYearsA < numberOfYearsB) {
                        return -1;
                    }
                    if (numberOfYearsB < numberOfYearsA) {
                        return 1;
                    }
                    return 0;
                });
            }
        );
    }

    public currentSortOrder(): string {
        switch (this.customerJourney.retentionsPageObject.sortingMethod) {
            case 'INTEREST_RATE':
                return 'Lowest interest rate';
            case 'MONTHLY_PAYMENT':
                return 'Lowest monthly payment';
            case 'TERM':
                return 'Shortest term';
            default:
                return '';
        }
    }

    public goToDetailsPage(deal: Deal): void {

        deal.feesToggle = this.showMonthlyPaymentWithFees;
        this.customerJourney.retentionsPageObject.selectedDeal = deal;
        this.animationState = 'going-to-left';
        this.pageToNavigateTo = 'deal-details';
    }

    public toggleMortgageFees(): void {
        this.customerJourney.retentionsFeeToggle = !this.customerJourney.retentionsFeeToggle;
        this.hasProductFeesToggleBeenClicked = true;
        if (this.customerJourney.retentionsFeeToggle) {
            this.adobeAnalyticsService.sendCTAAnalyticsEvent('Retentions deals table toggled fee-added');
        } else {
            this.adobeAnalyticsService.sendCTAAnalyticsEvent('Retentions deals table toggled fee-upfront');
        }
        this.showMonthlyPaymentWithFees = !this.showMonthlyPaymentWithFees;
        if (this.customerJourney.retentionsPageObject.sortingMethod === 'MONTHLY_PAYMENT') {
            this.sortDealsByMonthlyPayment();
        }
    }

    public addSelectedConditionally(type: SortingMethod): string {
        return type === this.customerJourney.retentionsPageObject.sortingMethod ? 'selected' : '';
    }

    public changeSwitchCheckedValue(): boolean {
        return this.customerJourney.retentionsFeeToggle;
    }

    isTrackerProduct(index: number) {
        return this.deals[index].product.type === 'Tracker';
    }

    isFeesSelectedTextColour() {
        return this.showMonthlyPaymentWithFees ? 'product-fee-colour' : '';
    }

    public isDataLoaded(): boolean {
        if (this.retentionsDealsLoaded && !this.tooltipsAlreadyShown) {
            this.initialiseTooltips();
        }
        return this.retentionsDealsLoaded;
    }

    public nextButtonTipOneToTwo(): void {
        this.waysToPayTooltip.autoClose = false;
        this.currentDealTooltip.close();
        if (this.monthlyCostTooltip) {
            this.monthlyCostTooltip.autoClose = false;
        }
        this.waysToPayTooltip.open();
    }

    public nextButtonTipTwoToThree(): void {

        if (this.isMultiDeal) {
            this.waysToPayTooltip.close();
            this.compareDealsTooltip.open();
        } else if (this.isMultiLoan) {
            this.waysToPayTooltip.close();
            this.monthlyCostTooltip.open();
        } else {
            this.waysToPayTooltip.close();
        }
    }

    public nextButtonTipThreeToFour(): void {
        this.compareDealsTooltip.close();
        this.monthlyCostTooltip.open();
    }

    leaveTour() {
        this.currentDealTooltip.close();
        this.waysToPayTooltip.close();
        this.compareDealsTooltip.close();
        this.monthlyCostTooltip.close();
    }

    public getChevronImage(i: number) {
        return this.arrayUrlsChrevronsImages[i];
    }

    public mouseOverChevronPosition(i: number) {

        this.arrayUrlsChrevronsImages[i] = 'assets/red-right-chevron.svg';
    }

    public mouseOutChevronPosition(i: number) {

        this.arrayUrlsChrevronsImages[i] = 'assets/grey-right-chevron.svg';
    }

    public getAccountMonthlyPayment(): string {

        let total = 0;

        try {
            total = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans
                .map(loan => loan.monthlyPayment)
                .reduce((totalIteration: number, currentValue: number) => totalIteration + currentValue, 0);
        } catch (e) {
        }

        return this.currencyPipe.transform(total, 'GBP');
    }

    public clickBackButton(): void {
        this.animationState = 'going-to-right';
    }

    public navigate() {
        this.customerJourney.retentionsPageObject.comingFromCompareDeals = false;
        if (this.animationState === 'going-to-left') {
            if (this.pageToNavigateTo === 'deal-details') {
                this.router.navigate(['/retentions/deal-details']);
            } else if (this.pageToNavigateTo === 'compare-deals') {
                this.router.navigate(['/retentions/compare-deals']);
            }
        } else if (this.animationState === 'going-to-right') {
            this.router.navigate(['/retentions/loan-selection'], {queryParams: {from: 'back'}});
        }
    }

    navigateToCompareDeals() {
        if (this.numberOfDealsSelected() > 1) {

            const deals = this.customerJourney.retentionsDealsResponse.response.deals.filter(deal => deal.compareCheckboxSelected);
            const selectedProduct = deals.map(selectedDeals => this.getSelectedProductInfo(selectedDeals));
            this.adobeAnalyticsService.sendCTAAnalyticsEvent('no. of products selected to compare: ' + this.numberOfDealsSelected().toString());
            this.adobeAnalyticsService.sendCTAAnalyticsEvent('selected products: ' + selectedProduct);

            this.animationState = 'going-to-left';
            this.pageToNavigateTo = 'compare-deals';
        }
    }

    fetchTooltipCount(): string {
        //console.log("Is  Multiloan selected: " + this.selectedMultiLoan + "  Multideal: " + this.isMultiDeal );

        if (this.isMultiLoan) {
            return '4';
        } else {
            return '3';
        }
    }

    hasProductFee(deal: Deal): boolean {
        return deal.product.productFee !== 0;
    }

    showProductFeeToggle() {
        let showProductFee = false;
        for (const deal of this.customerJourney.retentionsDealsResponse.response.deals) {
            if (deal.product.productFee !== 0) {
                showProductFee = true;
            }
        }
        return showProductFee;
    }

    getMonthlyPaymentStartValue(index: number): number {
        if (!this.hasProductFeesToggleBeenClicked) {
            return this.deals[index].calculations.monthlyPaymentWithoutFee;
        }

        if (this.showMonthlyPaymentWithFees) {
            return this.deals[index].calculations.monthlyPaymentWithoutFee;
        } else {
            return this.deals[index].calculations.monthlyPaymentWithFee;
        }
    }

    getMonthlyPaymentEndValue(index: number): number {
        if (!this.hasProductFeesToggleBeenClicked) {
            return this.deals[index].calculations.monthlyPaymentWithoutFee;
        }

        if (this.showMonthlyPaymentWithFees) {
            return this.deals[index].calculations.monthlyPaymentWithFee;
        } else {
            return this.deals[index].calculations.monthlyPaymentWithoutFee;
        }
    }

    async addDealToSelectedList(deal: Deal, mouseEvent: MouseEvent) {

        this.resetAvailableToSelect();

        if (this.maximumSelectedDeals && !deal.compareCheckboxSelected) {
            mouseEvent.preventDefault();
            this.showWarning = true;
            deal.availableToSelect = false;
            await this.delay(1000);
            this.resetAvailableToSelect();

        } else {
            deal.compareCheckboxSelected = !deal.compareCheckboxSelected;
            this.showWarning = false;
            deal.availableToSelect = true;
        }
    }

    numberOfCheckboxesSelected(): number {
        return this.customerJourney.retentionsDealsResponse.response.deals.filter(deal => deal.compareCheckboxSelected).length;
    }

    moreThanOneDeal(): boolean {
        return this.customerJourney.retentionsDealsResponse.response.deals.length > 1;

    }

    checkboxDisabled(deal: Deal) {

        if (this.numberOfCheckboxesSelected() >= this.maximumNumberOfDealsAllowed()) {
            if (deal.compareCheckboxSelected) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    labelStyle(deal: Deal): string {

        if (deal.compareCheckboxSelected) {
            return 'black-text';
        } else if (this.checkboxDisabled(deal)) {
            return 'label-disabled';
        } else {
            return 'graphite-text';
        }
    }

    public getDealsListMinHeight(): string {
        const totalHeight = this.dealsLengthWhenLoaded * 170;
        const adjustment = (this.dealsLengthWhenLoaded - 1) * 10;
        return totalHeight - adjustment + 'px';
    }

    async changeInDealsSelection() {
        await this.delay(50);
        const selected = this.numberOfCheckboxesSelected();
        if (this.showCompareFooter()) {
            this.compareFooterAnimationState = 'showCompareFooter';
            this.showCompareFooterDiv = true;
        } else {
            this.compareFooterAnimationState = 'hideCompareFooter';
        }
        this.maximumSelectedDeals = selected >= this.maximumNumberOfDealsAllowed();

    }

    public disabledCheckbox(deal: Deal): boolean {

        return this.maximumSelectedDeals && !deal.compareCheckboxSelected;
    }

    public maximumNumberOfDealsAllowed(): number {
        if (this.customerJourney.browserInfo.isMobile) {
            return this.MAXIMUM_ALLOWED_MOBILE;
        } else {
            return this.MAXIMUM_ALLOWED_DESKTOP;
        }
    }

    public isPendingSwitch() {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.accountStatus === "PENDING_SWITCH";
    }

    getPendingSwitchDate() {
        const pendingSwitchDate = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.pendingSwitchDate;
        return this.fullDatePipe.transform(pendingSwitchDate);
    }

    private numberOfDealsSelected(): number {
        return this.customerJourney.retentionsDealsResponse.response.deals
            .filter(deal => deal.compareCheckboxSelected).length;
    }

    private numberOfDeals(): number {
        return this.customerJourney.retentionsDealsResponse.response.deals.length;
    }

    private dealsExistsInCustomerJourney(): boolean {
        try {
            return this.customerJourney.retentionsDealsResponse.response.deals.length > 0;
        } catch (e) {
            return false;
        }
    }

    private initialiseArrayUrlsChrevronsImages() {
        this.arrayUrlsChrevronsImages = Array<string>(this.deals.length);
        this.arrayUrlsChrevronsImages.fill('assets/grey-right-chevron.svg');
    }

    private setDealCountMessage() {
        const length = this.deals.length;
        if (length === 1) {
            this.dealsCountMessage = '1 deal available to you';
        } else {
            this.dealsCountMessage = length + ' deals available to you';
        }
    }

    private setAvailableDealMessage() {
        const length = this.deals.length;
        if (length === 1) {
            this.availableDealsMessage = 'Available deal (1)';
        } else {
            this.availableDealsMessage = 'Available deals (' + length + ')';
        }
    }

    private extractNumberOfYears(term: string): number {

        const product_term = term.match(/\d+/g);
        if (product_term != null) {
            return +product_term[0];
        } else {
            return 99;
        }

    }

    private buildDealsRequest(): SelectedLoansRequest {

        const selectedLoans = [];

        this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.forEach(function (loan) {
            if (loan.selected) {
                const selectedLoan = new SelectedLoan();
                selectedLoan.loanScheme = loan.scheme;
                selectedLoan.sequenceNumber = loan.sequenceNumber;
                selectedLoans.push(selectedLoan);
            }

        });

        const loansRequest = new SelectedLoansRequest();
        loansRequest.loansSelected = selectedLoans;
        return loansRequest;
    }

    private initialiseTooltips(): void {
        try {
            this.currentDealTooltip.autoClose = false;

            const tooltipViews = this.cookieService.getPageViewCookieValue('MORTGAGE_DEALS_' + this.customerJourney.fullAccountNumber);

            const maxViews = this.customerJourney.config.tooltipsCount;

            if (tooltipViews <= maxViews) {
                this.currentDealTooltip.open();
                this.tooltipsAlreadyShown = true;
            }
        } catch (e) {
        }
    }

    private getSelectedProductInfo(selectedDeals: Deal): string {
        const title = ' ' + selectedDeals.product.term + ' ' + selectedDeals.product.type;
        const productFee = selectedDeals.product.productFee;

        return title + ' with product fee ' + productFee;
    }

    private resetAvailableToSelect() {
        this.deals.forEach(deal => {
            deal.availableToSelect = true;
        });
    }

    private hideDeals(): void {
        this.auxiliarDeals = this.deals;
        this.deals = [];
    }

    private rearrangePanelWithSorting(sortingFunction: Function): void {
        setTimeout(() => this.hideDeals(), 0);
        setTimeout(() => sortingFunction(), this.deals.length * 30);
    }

    private initialiseCheckBoxStatus(): void {
        this.customerJourney.retentionsDealsResponse.response.deals =
            this.customerJourney.retentionsDealsResponse.response.deals.map(deal => this.fixUndefined(deal));
    }

    private fixUndefined(deal: Deal): Deal {
        if (deal.compareCheckboxSelected === undefined) {
            deal.compareCheckboxSelected = false;
        }
        return deal;
    }

    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    private fixSortingByPreviouslySelected(): void {
        switch (this.customerJourney.retentionsPageObject.sortingMethod) {
            case 'INTEREST_RATE':
                this.sortDealsByInterest();
                break;
            case 'MONTHLY_PAYMENT':
                this.sortDealsByMonthlyPayment();
                break;
            case 'TERM':
                this.sortDealsByRemainingTerm();
                break;
        }
    }

    private getAndSetPendingSwitchProduct() {
        if (this.isPendingSwitch()) {
            const pendingProduct = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse?.eligibilityResponseView?.loans?.filter(a => a.productSwitchInfo?.pendingProductSwitch);
            this.pendingProduct = pendingProduct[0]?.productSwitchInfo;
        }
    }

    public formatDealDescription(term: string, type: string): string {
        if (term.toLowerCase() === 'lifetime' && type.toLowerCase() === 'variable') {
            return 'Standard Variable Rate';
        } else {
            return `${term} ${type}`;
        }
    }


    private removeSvrProductIfCurrentlyOnSvrAndSingleLoan(responseFromDealsService: RetentionsServiceDealsResponse, data: CustomerDetailsAuthenticationData) {
        if (data.loans.length === 1 && data.loans[0].productDescription === "Standard Variable Rate") {
            const deals = responseFromDealsService.response.deals;
            for(let i = deals.length - 1; i >= 0; i--){
                if (deals[i].product.type === 'Variable' && deals[i].product.term === 'Lifetime') {
                    deals.splice(i, 1);
                }
            }
        }
    }

}
