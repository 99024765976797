export class Config {
    expiredUrl: string;
    clientId: string;
    adobeAnalyticsUrl: string;
    retentionsServiceBaseUrl: string;
    idleTimeOut: number;
    logOffTimeout: number;
    tooltipsCount: number;
    numberOfMonthsTransferWindow: number;
    inSessionFlag: boolean;
    featureFlags: FeatureFlags;
    recaptchaKey: string;
    urls: Urls;
    adobeAnalyticsProp:AdobeAnalyticsProp;
    excludedBands: string[];
}

export interface Urls {
    santanderHome: string;
    homeInsurance: string;
    lifeInsurance: string;
    accountValidationURL: string;
    customerAuthenticationUrl: string;
    recaptchaUrl: string;
}

interface FeatureFlags {
    showRemainingBalance: boolean;
    isCaptchaLoad: boolean
}

interface AdobeAnalyticsProp {
    channel: string,
    subsection1: string,
    subsection2: string
}
