import {Component, OnInit, HostListener, ViewChild} from '@angular/core';
import {AdobeAnalyticsService} from '@core/services/backend/analytics/adobe-analytics.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {ViewTransition} from '@core/animations/view-transition/view-transition';
import {CommonService} from '@core/services/common/common.service';
import {CallUsSideBarComponent} from '@components/shared/call-us-side-bar/call-us-side-bar.component';
import {ConfigService} from '@core/services/backend/config/config.service';

type AnimationStates = 'coming-from-left' | 'coming-from-right' | 'going-to-left' | 'going-to-right';


@Component({
    selector: 'app-on-boarding',
    templateUrl: './on-boarding.component.html',
    styleUrls: ['./on-boarding.component.scss', '../../../../../scss/common.scss'],
    animations: [ViewTransition()]
})
export class OnBoardingComponent implements OnInit {

    dealPoints = [];
    animationState: AnimationStates = 'coming-from-right';
    numberOfMonthsTransferWindow: number;
    dataLoaded = false;
    @ViewChild('callUsSideBar')

    callUsSideBarComponent: CallUsSideBarComponent;

    constructor(
        private adobeAnalyticsService: AdobeAnalyticsService,
        private router: Router,
        private route: ActivatedRoute,
        private customerJourney: CustomerJourney,
        private commonService: CommonService,
        private configService: ConfigService
    ) {
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        this.commonService.setHideCallusSideBarFlag(false);
        this.callUsSideBarComponent.onClick();
    }

    ngOnInit() {
        // on page load, scroll to top.
        window.scroll(0, 0);


        this.configService.fetchConfigASync().subscribe(configResponse => {

            this.customerJourney.config = configResponse;
            this.numberOfMonthsTransferWindow = this.customerJourney.config.numberOfMonthsTransferWindow;
            this.setUpNavigation();

            this.dealPoints.push('No legal or valuation fees.');
            this.dealPoints.push('No credit searches or income checks.');
            this.dealPoints.push('No application forms or new Direct Debit set up.');
            this.dealPoints.push('The same offers online as over the phone.');
            this.dealPoints.push(`Once you’ve accepted your new deal, you can transfer to a new one with us or cancel up to 14 days before your new deal starts.`);
            this.adobeAnalyticsService.sendPageViewEvent('onboarding', false);
            this.customerJourney.retentionsPageObject.isCTA = false;
            this.customerJourney.retentionsPageObject.previousPage = 'on-boarding';
            this.commonService.setCallusSideBarFlag(false);
            this.dataLoaded = true;
        });

        setTimeout(() => {
            this.adobeAnalyticsService.sendPageViewEvent('onboarding', false);
        }, 3000);
    }

    public isDataLoaded(): boolean {
        return this.dataLoaded;
    }

    setUpNavigation() {
        this.route.queryParams.subscribe(params => {
            if (params.from !== null && params.from === 'back') {
                this.animationState = 'coming-from-left';
            }
        });

    }

    goToLoanPartSelection() {
        this.customerJourney.retentionsPageObject.isCTA = true;
        this.animationState = 'going-to-left';
    }

    public clickBackButton(): void {
        this.animationState = 'going-to-right';
    }

    public navigate() {
        if (this.animationState === 'going-to-left') {
            this.router.navigate(['/retentions/account-search']);
        } else if (this.animationState === 'going-to-right') {
            this.customerJourney = undefined;
            this.router.navigate(['/home'], {queryParams: {from: 'back'}});
        }
    }

    initialiseConfig() {
        this.configService.fetchConfigASync().subscribe(configResponse => {
            this.customerJourney.config = configResponse;
            this.numberOfMonthsTransferWindow = this.customerJourney.config.numberOfMonthsTransferWindow;
        });
    }
}
