import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountService } from '@core/services/backend/retentions/account.service';
import { AccountData } from '@core/model/account-data';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewTransition } from '@core/animations/view-transition/view-transition';
import { AdobeAnalyticsService } from '@core/services/backend/analytics/adobe-analytics.service';
import { Config } from '@core/model/config-response';
import { CustomerJourney } from '@core/model/customer-journey.model';
import { FormGroup } from '@angular/forms';
import { CustomerDetailsAuthenticationResponse } from '@core/model/customerDetailsAuthenticationResponse';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { ModalService } from '@core/services/modal-service/modal.service';
import { ErrorHandler } from '@core/error-handler/error-handler';
import { CommonService } from '@core/services/common/common.service';
import { CallUsSideBarComponent } from '@components/shared/call-us-side-bar/call-us-side-bar.component';


type AnimationStates = 'coming-from-left' | 'coming-from-right' | 'going-to-left' | 'going-to-right' | 'going-to-accountsearch';

@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss', '../../../../../scss/common.scss'],
  animations: [ViewTransition(),
  trigger('openClose', [
    // ...
    state('open', style({
      height: '!'
    })),
    state('closed', style({
      height: 0,
      paddingBottom: 0,
      paddingTop: 0
    })),
    transition('open => closed', [
      animate('0.25s')
    ]),
    transition('closed => open', [
      animate('0.25s')
    ]),
  ]),
  trigger('openCloseO', [
    // ...
    state('openO', style({
      opacity: 1
    })),
    state('closedO', style({
      opacity: 0
    })),
    transition('openO => closedO', [
      animate('0.25s')
    ]),
    transition('closedO => openO', [
      animate('0.25s')
    ]),
  ])]
})
export class CustomerDetailsComponent implements OnInit {
  accountSubscription: Subscription;
  accountData: AccountData;
  validatedAccount;
  config: Config;
  surnameValue: string;
  dateOfBirth: string;
  reformatDateOfBirth: string;
  dayOfBirth: string;
  monthOfBirth: string;
  yearOfBirth: string;
  postCode: string;
  customerAuthenticationData: CustomerDetailsAuthenticationResponse;
  public _authenticatedStatus: string;
  public _validAnmf: boolean;
  showMessagePostcodeEmpty = false;
  showMessagePostcodeInvalid: boolean;
  showMessageSurnameEmpty: boolean;
  showMessageSurnameInvalid = false;
  showMessageDOBEmpty = false;
  showMessageDOBInvalid: boolean;
  showMessageDayOfBirthInvalid: boolean;
  showMessageMonthOfBirthInvalid: boolean;
  validationFeedback = '';
  clickOnContinue = false;
  disabledContinueSurname: boolean;
  disabledContinuePostcode: boolean;
  disabledContinueDOB: boolean;
  disabledCTAForInvalidAccount: boolean;
  showDOBEmptyMessage: boolean;
  showInvalidAccountMessage: boolean;
  showInvalidAccountMessageNextAttempt: boolean;
  numberOfRemainingLoginAttempts: string;
  disabledInputFields: boolean = false;

  hintMessageType = 'warning';
  validMonth: string;

  count: number = 0;
  reviewCount: string;
  letConsent: boolean = false;
  clickedSubmit: boolean = false;
  isLoader: boolean = false;

  animationState: AnimationStates = 'coming-from-right';
  @ViewChild('tooltipAccountSearch', { static: false }) accountSearchTooltip: NgbTooltip;

  @ViewChild('callUsSideBar')
  callUsSideBarComponent: CallUsSideBarComponent;

  constructor(
    private adobeAnalyticsService: AdobeAnalyticsService,
    private accountService: AccountService,
    private router: Router,
    private customerJourney: CustomerJourney,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandler,
    private modalService: ModalService,
    private commonService: CommonService) { }


  @Input() customerDetailsForm: FormGroup;

  ngOnInit() {
    if (this.accountService === null || this.accountService.getValidatedAccount() === null){
      console.log("This page has been refreshed");
      this.animationState = 'going-to-accountsearch';
    } else {
      console.log(this.accountService.getValidatedAccount());
      this.accountData = this.accountService.getValidatedAccount();
      if (this.accountService !== null && this.accountService.getValidatedAccount() !== null) {
        this.validatedAccount = this.accountService.getValidatedAccount().accountNumber;
      }
      this.setUpNavigation();
      this.config = this.customerJourney.config;
      this.postCode = '';
      this.surnameValue = '';
      this.disabledContinueSurname = false;
      this.showInvalidAccountMessage = false;
      if ('CONSENT_TO_LET' === this.customerJourney.accountValidationResponse.accountValidationResponse.accountValidationStatus) {
        this.letConsent = true;
      }
      this.adobeAnalyticsService.sendPageViewEvent('account-verification', false);
    }
  }

  setUpNavigation() {
    this.route.queryParams.subscribe(params => {
      if (params.from !== null && params.from === 'back') {
        this.animationState = 'coming-from-left';
      }
    });
  }

  onFocus() {
    this.clickedSubmit = false;
  }


  doValidateCustomerDetails() {
    this.clickOnContinue = true;
   if (!(this.disabledInputFields)) {
    this.getReviewCount();
    this.isSurnameValid();
    this.isPostcodeValid();
    this.isDOBValid();
   }

    if (this.count > 0) {
      window.scroll(0, 0);
    }
    if (this.clickedSubmit) {
      return;
    }
    this.clickedSubmit = true;
    console.log("This is the url!!!!!" + this.config.urls.customerAuthenticationUrl);
    this.dateOfBirth = this.dayOfBirth + "/" + this.monthOfBirth + "/" + this.yearOfBirth;
    this.reformatDateOfBirth = this.yearOfBirth + "-" + this.monthOfBirth + "-" + this.dayOfBirth;

    console.log("This is the the date of birth " + this.dayOfBirth);
    if (this.count === 0 && this.disabledCTAForInvalidAccount) {
      this.isLoader = true;
      window.scroll(0, 0);
      this.accountService.validateCustomerDetails(this.config.urls.customerAuthenticationUrl, this.validatedAccount, this.surnameValue, this.reformatDateOfBirth, this.postCode, this.config.clientId).subscribe(
        response => {
          console.log("I am here 1!!");
          this.isLoader = false;
          this._authenticatedStatus = response['body'].customerDetailsAuthenticationResponse.authenticationStatus.accountStatus;
          this.customerJourney.customerDetailsAuthenticationResponse = response['body'];
          console.log(this.customerJourney.customerDetailsAuthenticationResponse);
          const numberOfFailedLoginAttempts = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.numberOfFailedLoginAttempts;
          console.log("I am here 2 and this is the response for min value!!!", JSON.stringify(response.customerDetailsAuthenticationResponse));
          console.log(this._authenticatedStatus);
          if (this.count === 0) {
            if (this._authenticatedStatus === "VALID" || this._authenticatedStatus === 'PENDING_SWITCH') {
              console.log("Customer Authenticated correctly continue");
              this.animationState = 'going-to-left';
            }
            if (this._authenticatedStatus === "CLOSE_PENDING_SWITCH") {
              this.modalService.open("closePendingSwitch");
              this.disableAndClearInput();
            }
            if (this._authenticatedStatus === "INVALID") {
              window.scroll(0, 0);
              if (numberOfFailedLoginAttempts > 0 && numberOfFailedLoginAttempts < 5) {

                  this.showInvalidAccountMessage = true;
                  this.disabledCTAForInvalidAccount = false;
                  if (numberOfFailedLoginAttempts > 1) {
                    const remainingLoginAttempts = 5 - numberOfFailedLoginAttempts;
                    this.numberOfRemainingLoginAttempts = remainingLoginAttempts === 1 ? `${remainingLoginAttempts} attempt` : `${remainingLoginAttempts} attempts`;
                    this.showInvalidAccountMessageNextAttempt = true;
                  }
                if (numberOfFailedLoginAttempts === 3 || numberOfFailedLoginAttempts === 4) {
                  this.modalService.open("incorrectAttempt");
                }
                this.adobeAnalyticsService.sendCTAAnalyticsEvent("Incorrect login details 1-4th attempt");
              }
            }
            if (this._authenticatedStatus === "LOCKED") {
              this.adobeAnalyticsService.sendCTAAnalyticsEvent("Incorrect login details 5th attempt Account Locked")
              this.modalService.open("incorrectAttemptFifth");
              this.disableAndClearInput();
            } else {
              console.log("Invalid customer try again please");
            }
          }
        },
        err => {
          console.log("Error in customer validation service:");
          if (err.status == '406') {
            this.errorHandler.redirectToHomePage();
          } else {
            this.errorHandler.redirectToErrorView();
          }
        }
      )
    }
  }

  public disableAndClearInput() {
    this.disabledCTAForInvalidAccount = false;
    this.disabledInputFields = true;
    this.surnameValue = '';
    this.postCode = '';
    this.dayOfBirth = '';
    this.monthOfBirth = '';
    this.yearOfBirth = '';
  }

  public getReviewCount() {
    this.reviewCount = this.count === 1 ? `is ${this.count} thing` : `are ${this.count} things`;
    return this.reviewCount
  }

  clickContinueButton() {
    this.showDOBEmptyMessage = true;
    this.count = 0;
    if (this.showMessageSurnameEmpty || this.showMessageSurnameInvalid) {
      this.count++;
    }
    if (this.showMessagePostcodeEmpty || this.showMessagePostcodeInvalid) {
      this.count++;
    }
    if (this.showMessageDOBEmpty || this.showMessageDayOfBirthInvalid || this.showMessageMonthOfBirthInvalid || this.showMessageDOBInvalid) {
      this.count++;
    }
  }

  public clickBackButton(): void {
    this.animationState = 'going-to-right';
  }

  public navigate() {
    if (this.animationState === 'going-to-left') {
      this.commonService.setCallusSideBarFlag(true);
      this.router.navigate(['retentions/loan-selection']);

    } else if (this.animationState === 'going-to-right') {
      this.router.navigate(['/home'], { queryParams: { from: 'back' } });
    } else if (this.animationState === 'going-to-accountsearch'){
      this.router.navigate(['retentions/account-search']);
    }
  }

  isPostcodeValid() {
    const regex = /(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z]\d{1,2})|([A-Za-z][A-HJ-Ya-hj-y]?\d{1,2})|([A-Za-z]\d[A-Za-z])|([A-Za-z][A-HJ-Ya-hj-y]?\d?[A-Za-z]))[ ]?\d[A-Za-z]{2}))$/;
    if (this.postCode === '') {
      this.showMessagePostcodeEmpty = true;
      this.showMessagePostcodeInvalid = false;
      this.adobeAnalyticsService.sendCTAAnalyticsEvent("Postcode not entered at all");
      if (this.clickOnContinue) {
        this.clickContinueButton();
      }
    } else {
      this.showMessagePostcodeEmpty = false;
    }

    if (!(this.postCode === '')) {
      regex.test(this.postCode) ? this.showMessagePostcodeInvalid = false : this.showMessagePostcodeInvalid = true;

    }
    if (this.clickOnContinue) {
      this.clickContinueButton();
    }
    if(this.showMessagePostcodeInvalid) {
      this.adobeAnalyticsService.sendCTAAnalyticsEvent("Postcode  entered incorrectly");
    }
  }

  isSurnameValid() {
    if (this.surnameValue === '') {
      this.showMessageSurnameEmpty = true;
      this.showMessageSurnameInvalid = false;
      this.adobeAnalyticsService.sendCTAAnalyticsEvent("Surname not entered at all");
      if (this.clickOnContinue) {
        this.clickContinueButton();
      }
    } else {
      this.showMessageSurnameEmpty = false;
    }
  }

  showValidationMessagePostcodeEmpty() {
    return this.showMessagePostcodeEmpty;
  }

  showValidationMessagePostcodeInvalid() {
    return this.showMessagePostcodeInvalid;
  }

  showValidationMessageSurnameEmpty() {
    return this.showMessageSurnameEmpty;
  }

  showValidationMessageDOBEmpty() {
    return this.showMessageDOBEmpty;
  }

  showValidationMessageDOBInvalid() {
    if (this.showMessageDOBInvalid || this.showMessageDayOfBirthInvalid || this.showMessageMonthOfBirthInvalid) {
      return true;
    }
    return false;
  }

  showValidationMessageDayOfBirthInvalid() {
    return this.showMessageDayOfBirthInvalid;
  }

  showValidationMessageSurnameInvalid() {
    return this.showMessageSurnameInvalid;
  }

  onKeySurname(event: any) {
    event.stopImmediatePropagation();
    const regex = /^[A-Za-z '-]+$/;
    this.surnameValue = event.target.value;
    this.showInvalidAccountMessage = false;
    this.showInvalidAccountMessageNextAttempt = false;
    this.disabledCTAForInvalidAccount = true;
    if (!(event.target.value === '')) {
      this.showMessageSurnameEmpty = false;
      regex.test(this.surnameValue) ? this.showMessageSurnameInvalid = false : this.showMessageSurnameInvalid = true;
      if (!(this.showMessageSurnameInvalid) && (!this.showMessageSurnameInvalid)) {
        this.disabledContinueSurname = true;
      } else {
        this.adobeAnalyticsService.sendCTAAnalyticsEvent("Surname entered incorrectly");
      }
    }
    if (event.target.value === '') {
      this.disabledContinueSurname = false;
    }
    if (this.clickOnContinue) {
      this.clickContinueButton();
    }
  }

  onKeyDOB(event: any) {
    this.showInvalidAccountMessage = false;
    this.showInvalidAccountMessageNextAttempt = false;
    if (event.target.value === '') {
      this.disabledContinueDOB = false;
    } else {
      this.disabledContinueDOB = true;
      this.showMessageDOBEmpty = false;
      this.showMessageDOBInvalid = false;
      this.showMessageDayOfBirthInvalid = false;
      this.showMessageMonthOfBirthInvalid = false;
      this.disabledCTAForInvalidAccount = true;
    }
  }

  onKeyPostcode(event: any) {
    event.stopImmediatePropagation();
    this.showInvalidAccountMessage = false;
    this.showInvalidAccountMessageNextAttempt = false;
    const regexPostcode = /^[A-Za-z 0-9]+$/
    if (!(event.target.value === '')) {
      this.postCode = event.target.value;
      this.showMessagePostcodeEmpty = false;
      regexPostcode.test(event.target.value) ? this.showMessagePostcodeInvalid = false : this.showMessagePostcodeInvalid = true;
      if (!(this.showMessageSurnameInvalid)) {
        this.disabledContinuePostcode = true;
        if (this.disabledContinueDOB) {
           this.disabledCTAForInvalidAccount = true;;
        }
      }

      if (this.clickOnContinue) {
        this.clickContinueButton();
      }

    }

    if (event.target.value === '') {
      this.disabledContinuePostcode = false;
    }
  }

  isDOBValid() {
    if (this.showDOBEmptyMessage) {
      this.doValidateDateOfBirth();
      }
  }

  doValidateDateOfBirth() {
    if (this.dayOfBirth === undefined || this.monthOfBirth === undefined || this.yearOfBirth === undefined) {
      this.showMessageDOBEmpty = true;
      this.showMessageDOBInvalid = false;
    }
    else {
      this.showMessageDOBEmpty = false;
    }

    if (!(this.dayOfBirth === undefined) && !(this.monthOfBirth === undefined) && !(this.yearOfBirth === undefined)) {
      if(this.dayOfBirth === '' && this.monthOfBirth === ''&& this.yearOfBirth === ''){
      this.showMessageDOBEmpty = true;
      this.showMessageDOBInvalid = false;
      this.showMessageDayOfBirthInvalid = false;
      this.showMessageMonthOfBirthInvalid = false;
      } else {
      this.showMessageDOBEmpty = false;
      const yearOfBirthReg =  /^[1-9][0-9]{3}$/;
      const dayOfBirthReg = /^([0]?[1-9]|[1-2][0-9]|3[01])$/;
      const monthOfBirthReg = /^(0?[1-9]|1[012])$/;
      const dateOfBirthValue = this.yearOfBirth + "-" + this.monthOfBirth + "-" + this.dayOfBirth;
      const todaysDate = moment(new Date());
      const invalidDOB = moment(todaysDate).subtract(18, 'years').format('YYYY-MM-DD');

      yearOfBirthReg.test(this.yearOfBirth) ? this.showMessageDOBInvalid = false : this.showMessageDOBInvalid = true;
      dayOfBirthReg.test(this.dayOfBirth) ? this.showMessageDayOfBirthInvalid = false : this.showMessageDayOfBirthInvalid = true;
      monthOfBirthReg.test(this.monthOfBirth) ? this.showMessageMonthOfBirthInvalid = false : this.showMessageMonthOfBirthInvalid = true;
      if(!(this.showMessageDOBInvalid) && !(this.showMessageDayOfBirthInvalid) && !(this.showMessageMonthOfBirthInvalid)) {
        if (dateOfBirthValue > invalidDOB) {
          if (moment(dateOfBirthValue).isAfter(invalidDOB, 'days')) {
            this.showMessageDOBInvalid = true;
            this.showMessageDayOfBirthInvalid = true;
            this.showMessageMonthOfBirthInvalid = true;
          }
        }

        const month: number = +this.monthOfBirth;
        const date: number = +this.dayOfBirth;

        if (month === 4 || month === 6 || month === 9 || month === 11) {
          if (date > 30) {
            this.displayDayValidateMsg();
          }
        }
        if (month === 2) {
          if (this.getLeapYear() < date) {
            this.displayDayValidateMsg();
            }
          }
        }
        if (this.clickOnContinue) {
          this.clickContinueButton();
        }
      }
    }
  }

  onTabValidation() {
    this.doValidateDateOfBirth();
    if (this.showMessageDOBEmpty) {
      this.adobeAnalyticsService.sendCTAAnalyticsEvent("Date of Birth not entered at all");
    }
    if ((this.showMessageDOBInvalid) || (this.showMessageDayOfBirthInvalid) || (this.showMessageMonthOfBirthInvalid)) {
      this.adobeAnalyticsService.sendCTAAnalyticsEvent("Date of Birth entered incorrectly");
    }
  }

  getLeapYear(): number {
    const y: number = +this.yearOfBirth;
    return (y % 4 == 0 && y % 100) || (y % 400 == 0) ? 29 : 28;
  }

  displayDayValidateMsg() {
    this.showMessageDayOfBirthInvalid = true;
  }

  validateAnmfOnClick() {
    this._validAnmf = false;
  }

  numberOnly(event: any): boolean {
    if (this.clickOnContinue) {
      this.clickContinueButton();
    }
     const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
     return true;

  }

  public stopClickEvent($event: any) {
    this.showDOBEmptyMessage = true;
    $event.stopPropagation();
  }

  public enableContinueButton(): boolean {
    if (this.disabledContinueDOB &&
      this.disabledContinuePostcode &&
      this.disabledContinueSurname &&
      !this.showMessageSurnameInvalid &&
      !this.showMessagePostcodeInvalid &&
      !this.showMessageDayOfBirthInvalid &&
      !this.showMessageMonthOfBirthInvalid &&
      !this.showMessageDOBInvalid &&
      this.disabledCTAForInvalidAccount) {
      return true;
    }
    return false;
  }

  public isLocked() {
    console.log("is locked: " + this._authenticatedStatus);
    return this._authenticatedStatus === "LOCKED";
  }

  public isClosePending() {
    return this._authenticatedStatus === "CLOSE_PENDING_SWITCH";
  }

  @HostListener('document:click', ['$event'])
  clickout() {
    if (!(this.disabledInputFields)) {
    this.isDOBValid();
    }
    if (this.showMessageDOBEmpty) {
      this.adobeAnalyticsService.sendCTAAnalyticsEvent("Date of Birth not entered at all");
    }
    if ((this.showMessageDOBInvalid) || (this.showMessageDayOfBirthInvalid) || (this.showMessageMonthOfBirthInvalid)) {
      this.adobeAnalyticsService.sendCTAAnalyticsEvent("Date of Birth entered incorrectly");
    }
    this.commonService.setHideCallusSideBarFlag(false);
    this.callUsSideBarComponent.onClick();
  }

  doNotDisplayValidateMsg() {
    this.showMessageDOBInvalid = false;
    this.showMessageDayOfBirthInvalid = false;
    this.showMessageMonthOfBirthInvalid = false;
    this.clickedSubmit = false;
  }
}
