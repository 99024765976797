import {BodyLineBase} from '@components/shared/collapsible-table/types/body-line-base';
import {BodyLine} from '@components/shared/collapsible-table/types/body-line';

export class BodyLineIndented extends BodyLineBase {
    indentedValues: Array<BodyLine>;
    headValue: BodyLine;


    constructor(indentedValues?: Array<BodyLine>, headValue?: BodyLine)
    constructor(indentedValues: Array<BodyLine>, headValue: BodyLine) {
        super();
        this.indentedValues = indentedValues;
        this.headValue = headValue;
    }
}
