import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class StickyHeaderService {

    
    public identifyIfStuckToTop(elementId:string, lowestOffset: number, previousOffsetTop : number): StickyModel {

        const subHeader: HTMLElement = document.getElementById(elementId);

        const stickyModel = new StickyModel();

        if (subHeader !== null) {
            const currentOffsetTop = subHeader.offsetTop;
            if (currentOffsetTop < lowestOffset) {
                stickyModel.lowestOffset = currentOffsetTop;

            }

            const isStuck = (previousOffsetTop != undefined && currentOffsetTop !== previousOffsetTop) || currentOffsetTop > lowestOffset;
            stickyModel.isMenuHeaderStuck = isStuck;
            stickyModel.offsetTop = currentOffsetTop;
        }
        return stickyModel;
    }
}

export class StickyModel {

    isMenuHeaderStuck:boolean;
    offsetTop: number;
    lowestOffset: number;
}
