import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-data-review-field',
    templateUrl: './data-review-field.component.html',
    styleUrls: ['./data-review-field.component.scss']
})
export class DataReviewFieldComponent {

    @Input() label: string;
    @Input() value: string;
    @Input() editOption: string;
    @Input() qaAffix: string;

    @Output() panelClickedChange = new EventEmitter<string>();

    public goTo(): void {
        this.panelClickedChange.emit(this.editOption);
    }
}
