import {Component, OnInit} from '@angular/core';
import {UserIdleService} from 'angular-user-idle';
import {AuthDataService} from '@core/services/backend/auth-data/auth-data.service';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {ConfigService} from '@core/services/backend/config/config.service';
// import { Timestamp } from 'rxjs-compat';

@Component({
    selector: 'app-timeout',
    templateUrl: './timeout.component.html',
    styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent implements OnInit {
    type: string;
    configData: any;
    countdown: number;
    timedOut = false;
    intervalId: any;
    entryTime: any;
    setTimeoutId: any;

    constructor(private idleService: UserIdleService,
                private authDataService: AuthDataService,
                private customerJourney: CustomerJourney,
                private configService: ConfigService ) {
        this.authDataService = authDataService;
    }

    ngOnInit() {
        this.configService.fetchConfigASync().subscribe(configResponse => {
            this.configData = configResponse;
            this.customerJourney.config = configResponse;
            this.countdown = this.configData.logOffTimeout;
            const idleTimeout = this.configData.idleTimeOut;

            this.idleService.setConfigValues({idle: idleTimeout, timeout: this.countdown, ping: 5});
            this.idleService.startWatching();

            this.idleService.onTimerStart().subscribe(() => {
                this.countdown--;

                if (!this.timedOut) {
                    this.timedOut = true;
                    this.setTimeoutId = setTimeout(() => this.logOff(), this.configData.logOffTimeout * 1000);
                
                    // this.resetCountdown();
                }
            });

            this.idleService.onTimeout().subscribe(() => {
                this.logOff();

            });
        });
    }

    clickStillHere() {
        this.idleService.resetTimer();
        this.timedOut = false;
        this.countdown = this.configData.logOffTimeout;
        clearTimeout(this.setTimeoutId);
    }

    logOff() {
        this.authDataService.revokeCurrentAccessToken();
        window.location.href = this.configData.expiredUrl;
    }


}
