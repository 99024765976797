import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class StickyFooterService {

    public identifyIfStuckToBottom(id: string, previousOffsetTop : number): boolean {
        const htmlElement: HTMLElement = document.getElementById(id);
        if (htmlElement !== null) {
            const top = htmlElement.getBoundingClientRect().top;
            if (top >= previousOffsetTop) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }
}

