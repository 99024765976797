import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from '@components/main/footer/footer.component';
import {ContentComponent} from '@components/main/content/content.component';
import {BreadcrumbsComponent} from '@components/shared/navigation/breadcrumbs/breadcrumbs.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BackButtonComponent} from '@components/shared/buttons/back-button/back-button.component';
import {StartupService} from '@core/services/backend/startup/startup.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CurrencyPipe, DatePipe, DecimalPipe, PercentPipe} from '@angular/common';
import {HeaderComponent} from '@components/main/header/header.component';
import {HttpAuthInterceptor} from '@core/services/backend/interceptor/http-auth.interceptor';
import {AuthDataService} from '@core/services/backend/auth-data/auth-data.service';
import {HintMessageComponent} from '@components/shared/messages/hint-message/hint-message.component';
import {ContinueButtonComponent} from '@components/shared/buttons/continue-button/continue-button.component';
import {MonthFromDatePipe} from '@core/pipes/month-from-date/month-from-date.pipe';
import {FullDatePipe} from '@core/pipes/full-date/full-date.pipe';
import {DataReviewFrameComponent} from '@components/shared/data-review/data-review-frame/data-review-frame.component';
import {DataReviewTitleComponent} from '@components/shared/data-review/data-review-title/data-review-title.component';
import {DataReviewSectionComponent} from '@components/shared/data-review/data-review-section/data-review-section.component';
import {DataReviewFieldComponent} from '@components/shared/data-review/data-review-field/data-review-field.component';
import {DataReviewFieldHighlightComponent} from '@components/shared/data-review/data-review-field-highlight/data-review-field-highlight.component';
import {DataReviewSectionTitleComponent} from '@components/shared/data-review/data-review-section-title/data-review-section-title.component';


import {
    NgbAccordionModule,
    NgbActiveModal,
    NgbDropdownModule,
    NgbModule,
    NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {MonthYearFromDatePipe} from '@core/pipes/month-and-year-from-date/month-year-from-date.pipe';
import {AdobeAnalyticsService} from '@core/services/backend/analytics/adobe-analytics.service';
import {CallUsSideBarComponent} from '@components/shared/call-us-side-bar/call-us-side-bar.component';
import {UserIdleModule} from 'angular-user-idle';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {SpinnerComponent} from '@components/shared/navigation/spinner/spinner.component';
import {ModalComponent} from '@components/shared/modal/modal.component';
import {TimeoutComponent} from '@components/shared/timeout/timeout.component';
import {ErrorComponent} from '@components/views/error/error.component';
import {StaticContentListComponent} from '@components/shared/static-content-list/static-content-list.component';
import {ErrorHandler} from '@core/error-handler/error-handler';
import {OnBoardingComponent} from '@components/views/retentions/on-boarding/on-boarding.component';
import {StaticContentListStackComponent} from '@components/shared/static-content-list-stack/static-content-list-stack.component';
import {LoanSelectionComponent} from '@components/views/retentions/loan-selection/loan-selection.component';
import {DealsListComponent} from '@components/views/retentions/deals-list/deals-list.component';
import {DealDetailsComponent} from '@components/views/retentions/deal-details/deal-details.component';
import {LoanListComponent} from '@components/views/retentions/loan-selection/loan-list/loan-list.component';
import {UiSwitchModule} from 'ngx-ui-switch';
import {LoanPartComponent} from '@components/views/retentions/loan-selection/loan-list/loan-part/loan-part.component';
import {environment} from '@environments/environment';
import {CurrentDealSingleLoanComponent} from '@components/views/retentions/deals-list/current-deal-single-loan/current-deal-single-loan.component';
import {CurrentDealMultiLoanComponent} from '@components/views/retentions/deals-list/current-deal-multi-loan/current-deal-multi-loan.component';
import {ReadyToChangeYourMortgageComponent} from '@components/views/retentions/deal-details/ready-to-change-your-mortgage/ready-to-change-your-mortgage.component';
import {DealDetailsMoreInfoExpandableComponent} from '@components/views/retentions/deal-details/deal-details-more-info-expandable/deal-details-more-info-expandable.component';
import {CountoModule} from 'angular2-counto';
import {SmallerPencePipe} from '@core/pipes/SmallerPence/smaller-pence.pipe';
import {CompareDealsComponent} from '@components/views/retentions/compare-deals/compare-deals.component';
import {CompareFooterComponent} from '@components/views/retentions/deals-list/compare-footer/compare-footer.component';
import {MortgageIllustrationInfoComponent} from '@components/views/retentions/deal-details/mortgage-illustration-info/mortgage-illustration-info.component';
import {ThingsToKnowComponent} from '@components/views/retentions/things-to-know/things-to-know.component';
import {ContactDetailsComponent} from '@components/views/retentions/contact-details/contact-details.component';
import {ProductReviewComponent} from '@components/views/retentions/product-review/product-review.component';
import {DealDetailsNeedHelpComponent} from '@components/views/retentions/deal-details/deal-details-need-help/deal-details-need-help.component';
import {TransferHeaderComponent} from '@components/views/retentions/commons/transfer-header/transfer-header.component';
import {ProgressIndicatorComponent} from '@components/shared/progress-indicator/progress-indicator.component';
import {ContactUsBodyComponent} from '@components/views/retentions/commons/contact-us-body/contact-us-body.component';
import {TextWithHeaderComponent} from '@components/shared/texts/text-with-header/text-with-header.component';
import {CollapsibleTableComponent} from '@components/shared/collapsible-table/collapsible-table.component';
import {CollapsibleTableBodyComponent} from '@components/shared/collapsible-table/collapsible-table-body/collapsible-table-body.component';
import {CollapsibleTableHeaderComponent} from '@components/shared/collapsible-table/collapsible-table-header/collapsible-table-header.component';
import {SeparatorComponent} from '@components/shared/separators/separator.component';
import {FullSeparatorComponent} from '@components/shared/separators/full-separator/full-separator.component';
import { ComparableItemComponent } from '@components/views/retentions/compare-deals/comparable-item/comparable-item.component';
import { ComparableTableComponent } from '@components/views/retentions/compare-deals/comparable-table/comparable-table.component';
import { ComparableLineComponent } from '@components/views/retentions/compare-deals/comparable-line/comparable-line.component';
import { WantHelpChoosingComponent } from '@components/views/retentions/commons/want-help-choosing/want-help-choosing.component';
import { CrossFadingElementComponent } from '@components/shared/cross-fading-element/cross-fading-element.component';
import {BrowserInfo} from '@core/model/browser-info';
import { InSessionOfferComponent } from '@components/views/retentions/in-session-offer/in-session-offer.component';
import { HintMessageHeaderBodyComponent } from '@components/shared/messages/hint-message-header-body/hint-message-header-body.component';
import { DayOfMonthFromDatePipe } from '@core/pipes/day-of-month/day-of-month-from-date.pipe';
import { OrdinalPipe } from '@core/pipes/ordinal/ordinal.pipe';
import { AccountSearchComponent } from '@components/views/retentions/account-search/account-search.component';
import { CustomerDetailsComponent } from '@components/views/retentions/customer-details/customer-details.component';
import { ErrorLabelComponent } from './util/validators/error-label/error-label.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { InputFrameComponent } from '@components/shared/inputs/input-frame/input-frame.component';
import { FooterCardComponent } from '@components/shared/footer-card/footer-card.component';
import { CollapsibleTableTitleComponent } from '@components/shared/collapsible-table/collapsible-table-title/collapsible-table-title.component';
import { MainButtonCtaComponent } from '@components/shared/buttons/main-button-cta/main-button-cta.component';


export function startupServiceFactory(startupService: StartupService): () => void {
    return () => startupService.initialise();
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        ContentComponent,
        BreadcrumbsComponent,
        BackButtonComponent,
        HintMessageComponent,
        ContinueButtonComponent,
        MonthFromDatePipe,
        FullDatePipe,
        DataReviewFrameComponent,
        DataReviewTitleComponent,
        DataReviewSectionComponent,
        DataReviewFieldComponent,
        DataReviewFieldHighlightComponent,
        DataReviewSectionTitleComponent,
        MonthYearFromDatePipe,
        CallUsSideBarComponent,
        SpinnerComponent,
        ModalComponent,
        TimeoutComponent,
        ErrorComponent,
        StaticContentListComponent,
        OnBoardingComponent,
        StaticContentListStackComponent,
        LoanSelectionComponent,
        DealsListComponent,
        DealDetailsComponent,
        LoanListComponent,
        LoanPartComponent,
        CurrentDealSingleLoanComponent,
        CurrentDealMultiLoanComponent,
        ReadyToChangeYourMortgageComponent,
        DealDetailsMoreInfoExpandableComponent,
        SmallerPencePipe,
        CompareDealsComponent,
        CompareFooterComponent,
        MortgageIllustrationInfoComponent,
        ThingsToKnowComponent,
        ContactDetailsComponent,
        ProductReviewComponent,
        DealDetailsNeedHelpComponent,
        TransferHeaderComponent,
        ProgressIndicatorComponent,
        ContactUsBodyComponent,
        TextWithHeaderComponent,
        CollapsibleTableComponent,
        CollapsibleTableBodyComponent,
        CollapsibleTableHeaderComponent,
        SeparatorComponent,
        FullSeparatorComponent,
        ComparableItemComponent,
        ComparableTableComponent,
        ComparableLineComponent,
        WantHelpChoosingComponent,
        CrossFadingElementComponent,
        InSessionOfferComponent,
        HintMessageHeaderBodyComponent,
        DayOfMonthFromDatePipe,
        OrdinalPipe,
        AccountSearchComponent,
        CustomerDetailsComponent,
        ErrorLabelComponent,
        InputFrameComponent,
        FooterCardComponent,
        CollapsibleTableTitleComponent,
        MainButtonCtaComponent
    ],
    imports: [
        NgbModule,
        BrowserModule,
        environment.animations ? BrowserAnimationsModule : NoopAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        NgxCaptchaModule,
        ReactiveFormsModule,
        FormsModule,
        NgbAccordionModule,
        NgbTooltipModule,
        UserIdleModule,
        NgbDropdownModule,
        CountoModule,
        UiSwitchModule.forRoot({
            // Global configuration for the ui-switch. Will override any local configuration
            // For information about this component https://www.npmjs.com/package/ngx-ui-switch
            size: 'small',
            color: 'rgba(27, 179, 188, 1)',
            defaultBoColor: '#B7BBBC',
            defaultBgColor: '#CCCCCC',
        }),
    ],
    providers: [
        StartupService,
        {
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true
        },
        CurrencyPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpAuthInterceptor,
            multi: true
        },
        DecimalPipe,
        PercentPipe,
        MonthFromDatePipe,
        FullDatePipe,
        DatePipe,
        MonthYearFromDatePipe,
        AuthDataService,
        AdobeAnalyticsService,
        CustomerJourney,
        ErrorHandler,
        BrowserInfo,
        OrdinalPipe,
        NgbActiveModal
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
