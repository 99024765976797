import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';

import {SkipAuthInterceptorHeader} from '../../../../../lib/constants';
import {AuthDataService} from '@core/services/backend/auth-data/auth-data.service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

    constructor(private authDataService: AuthDataService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = req.headers;
        headers = this.addNoCacheHeaders(headers);
        if (headers.has(SkipAuthInterceptorHeader)) {
            headers = headers.delete(SkipAuthInterceptorHeader);
            return next.handle(req.clone({headers}));
        } else {
            return this.authDataService.authData$.pipe(
                take(1),mergeMap(accessToken => {
                    if (accessToken !== null) {
                        headers = headers.append('Authorization', 'Bearer ' + accessToken);
                        return next.handle(req.clone({headers}));
                    } else {
                        return null;
                    }
                })
            );
        }
    }

    private addNoCacheHeaders(headers: HttpHeaders): HttpHeaders {
        headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0');
        headers = headers.append('Pragma', 'no-cache');
        headers = headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
        return headers;
    }
}
