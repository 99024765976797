import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@environments/environment';
import {Observable} from 'rxjs';
import {SkipAuthInterceptorHeader} from '../../../../../lib/constants';
import {Config} from '@core/model/config-response';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    constructor(private http: HttpClient) {
    }

    public fetchConfigASync(): Observable<Config> {
        const configUrl = environment.configUrl;
        const headers = this.createHeaders();
        return this.http.get<any>(configUrl, {headers});
    }
    private createHeaders(): HttpHeaders {
        return new HttpHeaders().set(SkipAuthInterceptorHeader, 'Skip-Auth-Interceptor');
    }
}
