import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer-card',
  templateUrl: './footer-card.component.html',
  styleUrls: ['./footer-card.component.scss']
})
export class FooterCardComponent {

  @Input() imageSrc: string;

  @Input() cardHeader: string;
  @Input() cardText: string;
  @Input() goToUrl: string;
  @Input() buttonBoxClass: string;


  public clickContinueButton() {
      console.log("Going to " + this.goToUrl);
      window.open(this.goToUrl, '_blank');
}

}
