import {BodyLineBase} from '@components/shared/collapsible-table/types/body-line-base';


export class BodyLine extends BodyLineBase{
    label: string;
    value: string;
    info: string;

    constructor(label?: string, value?: string, info?: string)
    constructor(label: string, value: string, info?: string)
    constructor(label: string, value: string, info: string) {
        super();
        this.label = label;
        this.value = value;
        this.info = info;
    }
}
