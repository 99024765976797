import {HeaderBase} from "@components/shared/collapsible-table/types/header-base";

export class Header extends HeaderBase {

    productTitle: string;
    productSubTitle: string;
    leftValue: string;
    leftLabel: string;
    centreValue: string;
    centreLabel: string;
    rightValue: string;
    rightLabel: string;


    constructor(productTitle: string, productSubTitle: string, leftLabel: string, leftValue: string,  centreLabel: string, centreValue: string,  rightLabel: string, rightValue: string) {
        super();
        this.productTitle = productTitle;
        this.productSubTitle = productSubTitle;
        this.leftValue = leftValue;
        this.leftLabel = leftLabel;
        this.centreValue = centreValue;
        this.centreLabel = centreLabel;
        this.rightValue = rightValue;
        this.rightLabel = rightLabel;
    }
}
