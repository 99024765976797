import {Injectable} from '@angular/core';
import {BrowserInfo} from "@core/model/browser-info";
import {CookieService} from "@core/services/backend/cookie/cookie.service";
import {CustomerJourney} from '@core/model/customer-journey.model';


@Injectable()
export class AdobeAnalyticsService {
    public browserInfo: BrowserInfo;
    public TRACK_ERROR = 'track_error';

    constructor(private customerJourney: CustomerJourney) {
        this.browserInfo = new BrowserInfo(new CookieService());
    }

    public sendPageViewEvent(pageName: string, anyProductPendingSwitch = false, isRetentionsJourney = false, isMultiLoan = false): void {
        try {
            this.updatePageIdentifier(pageName);
            if (isRetentionsJourney) {this.updateMultiLoan(isMultiLoan);}
            if (anyProductPendingSwitch) {this.updateProductPendingSwitch();}
            this.pushTrackEvent();
        } catch (e) {
        }
    }

    public sendCTAAnalyticsEvent(clickName: string): void {
        try {
            this.updateClickIdentifier(clickName);
            this.pushClickEvent();
        } catch (e) {
        }
    }

    public updateErrorMessages(messageObject: Object) {
        (<any>window).adobeContextData.errorMsg = messageObject;
        if ((<any>window)._satellite) {
          (<any>window)._satellite.track(this.TRACK_ERROR);
        }
      }

    private updateClickIdentifier(clickName: string): void {
        (<any>window).adobeContextData.clickIdentifier = clickName;
    }

    public updatePageIdentifier(pageName: string): void {
         (<any>window).adobeContextData.channel =  this.customerJourney.config.adobeAnalyticsProp.channel;
         (<any>window).adobeContextData.subsection1 = this.customerJourney.config.adobeAnalyticsProp.subsection1;
         (<any>window).adobeContextData.subsection2 = this.customerJourney.config.adobeAnalyticsProp.subsection2;
         (<any>window).adobeContextData.pageIdentifier = pageName;
    }

    private updateProductPendingSwitch(): void {
        (<any>window).adobeContextData.productPendingSwitch = true;
    }

    private updateMultiLoan(isMultiLoan: boolean): void {
        (<any>window).adobeContextData.isMultiLoan = isMultiLoan;
    }

    private pushTrackEvent(): void {
        this.setNativeFlag();
        (<any>window)._satellite.track('pageView');
    }

    private pushClickEvent(): void {
        this.setNativeFlag();
        (<any>window)._satellite.track('CTA_Click');
    }

    private setNativeFlag() {
        if (this.browserInfo.isNative()) {
            (<any>window).adobeContextData.mobileAppView = true;
        } else {
            (<any>window).adobeContextData.mobileAppView = false;
        }
    }
}
