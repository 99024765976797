import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-comparable-item',
    templateUrl: './comparable-item.component.html',
    styleUrls: ['./comparable-item.component.scss']
})
export class ComparableItemComponent {

    @Input() dataQaId: string;

}
