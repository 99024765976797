import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CTA_ARROW, MainButton, MainButtonStyle } from './main-button';

@Component({
    selector: 'scui-main-button-cta',
    templateUrl: './main-button-cta.component.html',
    styleUrls: ['./main-button-cta.component.scss']
})
export class MainButtonCtaComponent {


    @Input() mainButton: MainButton;
    @Output() mainButtonCtaClicked: EventEmitter<void> = new EventEmitter<void>();
    public readonly CTA_ARROW = CTA_ARROW;

    public getClasses(): string {
        switch (this.mainButton.style) {

            case MainButtonStyle.GREY_DISABLED:
                return 'style-grey-disabled';
            case MainButtonStyle.CTA_PRIMARY:
                return 'style-red-cta-primary';
            case MainButtonStyle.CTA_SECONDARY:
                return 'style-cta-secondary';
            case MainButtonStyle.CTA_TERTIARY:
                return 'style-cta-tertiary';
            case MainButtonStyle.CTA_TERTIARY_DISABLED:
                return 'style-grey-disabled-tertiary';
            default:
                return 'style-red-cta-primary';
        }
    }

    public getDataQaId(): string {
        const baseDataQaId = 'scui-main-button-cta';
        return this.mainButton.dataQaId
            ? baseDataQaId + '-' + this.mainButton.dataQaId
            : baseDataQaId;
    }

    public buttonClicked(): void {
        this.mainButtonCtaClicked.emit();
    }

    public isDisabled(): boolean {
        switch (this.mainButton.style) {
            case MainButtonStyle.GREY_DISABLED:
            case MainButtonStyle.CTA_TERTIARY_DISABLED:
                return true;
            default:
                return false;
        }
    }

}
