import { Injectable } from '@angular/core';
import { ConfigService } from '@core/services/backend/config/config.service';
import { CustomerJourney } from '@core/model/customer-journey.model';

@Injectable()
export class StartupService {

    constructor(private configService: ConfigService,
        private customerJourney: CustomerJourney) {
    }

    initialise() {
        this.configService.fetchConfigASync().subscribe(response => {
            this.customerJourney.config = response;
        });
    }

}
