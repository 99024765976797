import { BrowserInfo } from '@core/model/browser-info';
import { CookieService } from '@core/services/backend/cookie/cookie.service';

export default class NativeUtils {
  static browserInfo =  new BrowserInfo(new CookieService());

  static downloadPdfNative(blob: Blob, fileName: string): void {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadstart = () => {
        if (this.browserInfo.isAndroid) {
            (window as any).webView.openDocumentStart();
        }
    };
    reader.onloadend = () => {
        const base64data = `${fileName};${reader.result}`;
        if (this.browserInfo.isIOS) {
            (window as any).webkit.messageHandlers.openDocument.postMessage(base64data);
        }

        if (this.browserInfo.isAndroid) {
            (window as any).webView.openDocument(base64data);
        }
    };
    reader.onerror = () => {
        const err = 'Error on downloading the pdf!';
        if (this.browserInfo.isIOS) {
            (window as any).webkit.messageHandlers.jsError.postMessage(err);
        }

        if (this.browserInfo.isAndroid) {
            (window as any).webView.openDocumentError(err);
        }

        reader.abort();
    };
  }

}
