import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  private readonly formSubmitted: Subject<any>;
  private calledBack: boolean;

  constructor() {
    this.formSubmitted = new Subject();
    this.calledBack = false;
  }

  submit(value: any) {
    this.resetCalledBack();
    this.formSubmitted.next(value);
  }

  formSubmits(): Observable<any> {
    return this.formSubmitted;
  }

  isCalledBack(): boolean {
    return this.calledBack;
  }

  setCalledBack() {
    this.calledBack = true;
  }

  private resetCalledBack() {
    this.calledBack = false;
  }
  
}
