import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-text-with-header',
  templateUrl: './text-with-header.component.html',
  styleUrls: ['./text-with-header.component.scss']
})
export class TextWithHeaderComponent {

    @Input() headers: string[];
    @Input() texts: string[];
    @Input() qaAffix: string;
    @Input() subHeaders: string[];


}
