export interface MainButton {
    text: string;
    style?: MainButtonStyle,
    icon?: MainButtonIcon;
    dataQaId?: string;
    ctaArrow?: CTA_ARROW;
}

export enum MainButtonIcon {
    DEFAULT = 'DEFAULT',
    SPINNER = 'SPINNER'
}

export enum CTA_ARROW {
    RIGHT = 'right',
    LEFT = 'left'
}

export enum MainButtonStyle {
    DEFAULT = 'DEFAULT',
    GREY = 'GREY',
    RED = 'RED',
    RED_DISABLED = 'RED_DISABLED',
    WHITE = 'WHITE',
    WHITE_WITHOUT_BORDER = 'WHITE_WITHOUT_BORDER',
    GREY_DISABLED = 'GREY_DISABLED',
    CTA_PRIMARY = 'CTA_PRIMARY',
    CTA_SECONDARY = 'CTA_SECONDARY',
    CTA_TERTIARY = 'CTA_TERTIARY',
    CTA_TERTIARY_DISABLED = 'CTA_TERTIARY_DISABLED'

}
