import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
// import { ErcStep } from '@core/model/retentions-service-deals-response';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private _callusSidebar = new BehaviorSubject<boolean>(false);
  getCallusSideBarFlag = this._callusSidebar.asObservable();
  earliestDate: any;

  private _hideCallusSidebar = new BehaviorSubject<any>(0);
  getHideCallusSideBarFlag = this._hideCallusSidebar.asObservable();

  setCallusSideBarFlag(val: boolean) {
    this._callusSidebar.next(val);
  }

  setHideCallusSideBarFlag(val: boolean) {
    this._hideCallusSidebar.next(val);
  }

  calculateStepErcRate(list) {
    const moments = list.map(d => moment(d.until));
    this.earliestDate = moment.min(moments);
    const rate = list.filter(x => x.until === this.earliestDate._i);
     return rate[0].rate;
  }
}