import { Injectable } from "@angular/core";
import { RetentionsServiceDealLoansRequest } from "@core/model/retentions-service-deal-loans-request";
import { Loan } from "@core/model/retentions-service-eligibility-response";
import { SelectedLoan } from "@core/model/retentions-service-deals-request";
import { RetentionsServiceDealLoansResponse } from "@core/model/retentions-service-deal-loans-response";
import { CustomerJourney } from "@core/model/customer-journey.model";
import { RetentionsService } from "@core/services/backend/retentions/retentions.service";
import { ErrorHandler } from '@core/error-handler/error-handler';

@Injectable()
export class LoadingLoanDeals {

    constructor(private customerJourney: CustomerJourney,
        private retentionService: RetentionsService,
        private errorHandler: ErrorHandler
    ) {
    }

    public callLoanDeals(): void {
        const retentionsServiceBaseUrl = this.customerJourney.config.retentionsServiceBaseUrl;
        const accountNumber = this.customerJourney.fullAccountNumber;
        const productCode = this.customerJourney.retentionsPageObject.selectedDeal.product.productCode;
        const clientId = this.customerJourney.config.clientId;

        const request = this.createDealLoansRequest();


        this.retentionService.fetchDealLoansDataASync(
            retentionsServiceBaseUrl,
            accountNumber,
            productCode,
            request,
            clientId,
            this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.access_token
        ).subscribe(response => {
            //console.log('response from retentions-deal-loans received : ' + JSON.stringify(response));
            if(response.info.status == 'ko'){
                this.errorHandler.redirectToErrorView()
            }
            this.saveDealLoansResponse(response)
        },
            err => {
                console.log("Error from loan deals service");
                if (err.status == '406') {
                    this.errorHandler.redirectToHomePage();
                } else {
                    this.errorHandler.redirectToErrorView();
                }
            }
        );

    }

    private createDealLoansRequest(): RetentionsServiceDealLoansRequest {
        const request = new RetentionsServiceDealLoansRequest();
        let productFee = new Number();

        request.loansSelected = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans
            .filter(loan => loan.selected)
            .map(loan => this.convertToSelectedLoans(loan));
        productFee = this.customerJourney.retentionsPageObject.selectedDeal.product.productFee;

        request.feeAddedInMortgage = (this.customerJourney.retentionsFeeToggle && productFee > 0);

        return request;
    }

    private convertToSelectedLoans(loan: Loan): SelectedLoan {
        const selectedLoan = new SelectedLoan();
        selectedLoan.loanScheme = loan.scheme;
        selectedLoan.sequenceNumber = loan.sequenceNumber;
        return selectedLoan;
    }

    private saveDealLoansResponse(response: RetentionsServiceDealLoansResponse): void {
        this.customerJourney.dealLoansResponse = response;
    }
}
