import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-data-review-section',
    templateUrl: './data-review-section.component.html',
    styleUrls: ['./data-review-section.component.scss']
})
export class DataReviewSectionComponent {


    @Input() avoidFinalHR !: false;


}
