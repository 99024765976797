import {Pipe, PipeTransform} from '@angular/core';

const ordinals: string[] = ['th', 'st', 'nd', 'rd'];

@Pipe({
    name: 'ordinal'
})
export class OrdinalPipe implements PipeTransform {

    transform(number: any): string {
        if (this.isNumeric(number)) {
            const asNumber = +number;
            const modulo = asNumber % 100;
            return asNumber + (ordinals[(modulo - 20) % 10] || ordinals[modulo] || ordinals[0]);
        } else {
            return 'Nan';
        }
    }

    private isNumeric(value: any): boolean {
        return !isNaN(value) && !isNaN(parseFloat(value));
    }

}
