import {animate, style, transition, trigger} from '@angular/animations';

export function ModalAnimation() {
    return trigger('modalAnimation', [
        transition('* => *', [
            style({ opacity: 0 }),
            animate(250, style({opacity: 1}))
        ])
    ]);
}
