import {AfterViewInit, Component, ElementRef, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import {CustomerJourney} from "@core/model/customer-journey.model";

@Component({
  selector: 'app-comparable-line',
  templateUrl: './comparable-line.component.html',
  styleUrls: ['./comparable-line.component.scss']
})
export class ComparableLineComponent implements AfterViewInit {

    @Input() dataQaId: string;
    @Input() hideElementSeparators: boolean = false;

    @ViewChild('title', {static: false}) title: ElementRef;
    public showTitle: boolean = true;

    @ViewChild('subtitle', {static: false}) subtitle: ElementRef;
    public showSubtitle: boolean = true;

    constructor(private customerJourney: CustomerJourney, private ref: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.updateTitleAndSubtitleVisibility();
    }

    public showThird(): boolean {
        return this.customerJourney.retentionsDealsResponse.response.deals
            .filter(deal => deal.compareCheckboxSelected)
            .length > 2;
    }

    private updateTitleAndSubtitleVisibility(): void {
        // If title or subtitle ng-content is empty we remove the parent to avoid the mb
        this.showTitle = this.title.nativeElement.children.length != 0;
        this.showSubtitle = this.subtitle.nativeElement.children.length != 0;
        this.ref.detectChanges();
    }
}
