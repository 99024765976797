import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AdobeAnalyticsService} from '@core/services/backend/analytics/adobe-analytics.service';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

    /*
        Send with the router the parameter if you want to show a different error.
        In html you can create the different errors.

        Examples:
        1 - /mortgage-hub/error      ----> will go to the generic message
        2 - /mortgage-hub/error/404  ----> You can create in html a *ngIf="id=='404'"> and will go there
     */

    // This variable will be loading from the routing and will help us to select a different error if needed
    id: string;

    constructor(
        private route: ActivatedRoute,
        private adobeAnalyticsService: AdobeAnalyticsService) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.id = params['id'];
        });
        this.adobeAnalyticsService.sendPageViewEvent('error');
    }

}
