import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-static-content-list-stack',
    templateUrl: './static-content-list-stack.component.html',
    styleUrls: ['./static-content-list-stack.component.scss', '../../../../scss/common.scss']
})
export class StaticContentListStackComponent {

    @Input() content: string[];
    @Input() title: string;


    isTitlePresent() {
        if (this.title === '' || this.title === 'undefined') {
            return false;
        } else {
            return true;
        }
    }
}
