import {Info} from '@core/model/info';


export class RetentionsServiceEligibilityResponse {
    authenticated?: boolean;
    customerDetailsAuthenticationResponse: RetentionsServiceData;
    serviceInfo: Info;
}

class Blockers {
    accountIneligible: boolean;
    allLoansIneligible: boolean;
    allLoansERC: boolean;
    notOnline: boolean;
    financialDifficulties: boolean;
    accountBalanceBelowThreshold: boolean;
    flexi: boolean;
    outsideDealWindow: boolean;
    termRemaining: boolean;
    consentToLet: boolean;
    eligibleInArrears: boolean;
}

class RetentionsServiceData {
    blockers: Blockers;
    loans: Loan[];
    minimumBalanceForSwitching: number;
}

class LoanBlockers {
    termRemaining: boolean;
    outsideDealWindow: boolean;
}

export class Loan {
    loanId: number;
    loanBalance: number;
    loanType: string;
    remainingTerm: number;
    monthlyPayment: number;
    productEndDate: string;
    loanEndDate: string;
    isOnReversionRate: boolean;
    productDescription: string;
    blockers: LoanBlockers;
    selected: boolean;
    eligibleToTransfer: boolean;
    interestRate: number;
    followOnRate: number;
    sequenceNumber: number;
    scheme: string;
    view: View;
    telephonyOnly: boolean;
    regularOverpayment: boolean;
}

export class View {
    headerView: string;
    bodyView: string;
}

