import {Info} from '@core/model/info';
import {Router} from '@angular/router';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandler {

    // This is a list of error codes in the info block
    // that are allow and won't redirect the app to the error view
    private whiteListedCodes = [
        'PAYMENTS_MAINTENANCE_REGION_W'
    ];

    constructor(
        private router: Router,
        private customerJourney: CustomerJourney
    ) {
    }

    public redirectToErrorView() {
        this.router.navigate(['/error']);

    }

    public redirectToHomePage() {
        this.router.navigate(['/']);
    }

    public redirectToErrorViewWithResponse(response) {
        if (this.productEndTodayMaintenanceInfoBlock(response)) {
            this.goToProductEndTodayMaintenanceErrorView();
        } else {
            this.router.navigate(['/error']);
        }
    }

    public checkErrorsToHandle(): void {
        if (this.anmfIsInMaintenance()) {
            this.goToMaintenanceErrorView();
        } else if (this.thereIsMessageInInfoBlock()) {
            this.goToGenericErrorView();
        }
    }

    public productEndTodayMaintenanceInfoBlock(response): boolean {
        const info = response.error.info;
        if (info && info.code && info.code === 'TODAY_IS_PRODUCT_END_DATE_AND_SUNDAY_OR_BANK_HOLIDAY') {
            return true;
        } else {
            return false;
        }
    }
    public goToGenericErrorView() {
        this.router.navigate(['/error']);
    }

    public thereErrorInInfoBlock(info: Info): boolean {
        if (info && info.code && info.code.length > 0 && !this.codeIsInWhiteList(info.code)) {
            return true;
        } else {
            return false;
        }
    }

    // public InfoBlockWithProductEndTodayMaintenanceCode(info: Info): boolean {
    //     if (info && info.code && info.code === 'TODAY_IS_PRODUCT_END_DATE_AND_SUNDAY_OR_BANK_HOLIDAY') {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    private codeIsInWhiteList(code: string): boolean {
        if (code === '') {
            return true;
        } else {
            const isWhiteListed = this.whiteListedCodes.indexOf(code) >= 0;
            return isWhiteListed;
        }
    }

    private anmfIsInMaintenance(): boolean {
        const codes = [];

        codes.push(this.getCode(this.customerJourney.accountDetailsResponse.info));
        codes.push(this.getCode(this.customerJourney.customerDetailsAuthenticationResponse.serviceInfo));

        return codes.indexOf('MAINTENANCE_REGION_X') >= 0;
    }

    private getCode(info: Info): string {
        return (info && info.code) ? info.code : '';
    }

    private goToMaintenanceErrorView() {
        this.router.navigate(['/error/maintenance']);
    }

    private goToProductEndTodayMaintenanceErrorView() {
        this.router.navigate(['/error/product-end-today-maintenance']);
    }

    private thereIsMessageInInfoBlock() {

        const errorInAccountInfo = this.thereErrorInInfoBlock(this.customerJourney.accountDetailsResponse.info);

        return errorInAccountInfo;
    }

}
