import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ViewTransition} from '@core/animations/view-transition/view-transition';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {TransferHeaderType} from '@components/views/retentions/commons/transfer-header/transfer-header-type';
import {ProgressIndicatorItem} from '@components/shared/progress-indicator/progress-indicator.component';
import {Deal} from '@core/model/retentions-service-deals-response';
import {FullDatePipe} from '@core/pipes/full-date/full-date.pipe';
import {CurrencyPipe, DatePipe} from '@angular/common';
import {CollapsibleTableData} from '@components/shared/collapsible-table/types/collapsible-table-data';
import {CollapsibleTableDataMapper} from '@components/views/retentions/product-review/collapsible-table-mapper';
import {AdobeAnalyticsService} from '@core/services/backend/analytics/adobe-analytics.service';
import {CustomerDetailsAuthenticationData} from '@core/model/customerDetailsAuthenticationResponse';
import {RetentionsServiceDealLoansData} from '@core/model/retentions-service-deal-loans-response';
import {CallUsSideBarComponent} from '@components/shared/call-us-side-bar/call-us-side-bar.component';
import {CommonService} from '@core/services/common/common.service';
import {ModalService} from '@core/services/modal-service/modal.service';
import {ProductReviewService} from '@core/services/common/product-review.service'

type AnimationStates = 'coming-from-left' | 'coming-from-right' | 'going-to-left' | 'going-to-right';

@Component({
    selector: 'app-product-review',
    templateUrl: './product-review.component.html',
    styleUrls: ['./product-review.component.scss'],
    animations: [ViewTransition()],
    providers: [CollapsibleTableDataMapper]
})
export class ProductReviewComponent implements OnInit {

    animationState: AnimationStates = 'coming-from-right';

    progressIndicator: ProgressIndicatorItem[];
    productDescription: string;

    selectedDeal: Deal;
    multiLoanTransfer: boolean;
    singleLoanSwitch: boolean;
    switchDate: string;
    hasRegularOverpayment: boolean;
    currentTransferringProductFixedWithErc: boolean;
    productFeeHeader: string;
    newOutstandingBalanceText: string;
    newOutstandingBalance: string;
    productFee: boolean;
    isMultiLoan: boolean;
    newMonthlyPayment: number;

    collapsibleDataLoanTransferring: Array<CollapsibleTableData>;
    collapsibleData: Array<CollapsibleTableData>;
    textForTransferringLoan: string;
    newInterestRateChange: string;
    currentProductFixedTermFlag: boolean;
    newInterestRateChangeInLoanWithFee: string;
    loanBalanceWithFee: string;
    isLoader: boolean = false;
    clickedSubmit: boolean;
    retentionsFeeToggle: boolean;
    selectedDealHasFee: boolean;
    public eligibilityResponseView: CustomerDetailsAuthenticationData;
    public dealLoansData: RetentionsServiceDealLoansData;

    @ViewChild('callUsSideBar')
    callUsSideBarComponent: CallUsSideBarComponent;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private customerJourney: CustomerJourney,
                private fullDatePipe: FullDatePipe,
                private currencyPipe: CurrencyPipe,
                private collapsibleTableMapper: CollapsibleTableDataMapper,
                private datePipe: DatePipe,
                private adobeAnalyticsService: AdobeAnalyticsService,
                private commonService: CommonService,
                private modalService: ModalService,
                private productReviewService: ProductReviewService) {
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        this.commonService.setHideCallusSideBarFlag(false);
        this.callUsSideBarComponent.onClick();
    }

    ngOnInit() {
        // on page load, scroll to top.
        window.scroll(0, 1);

        this.setUpNavigation();
        this.decorateProgressIndicator();

        if (this.customerJourney.retentionsPageObject.selectedDeal !== undefined) {
            this.selectedDeal = this.customerJourney.retentionsPageObject.selectedDeal;
            const product = this.selectedDeal.product;
            if (product.productFee > 0) {
                this.selectedDealHasFee = true;
            }
            this.productDescription = product.term + ' ' + product.type;
        }
        if (this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse !== undefined) {
            this.eligibilityResponseView = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView;
            this.multiLoanTransfer = this.eligibilityResponseView.loans.length > 1;
            this.isMultiLoan = this.eligibilityResponseView.loans.length > 1;

            this.hasRegularOverpayment = this.eligibilityResponseView.loans
                .filter(loan => loan.selected)
                .filter(loan => loan.regularOverpayment)
                .length > 0;

            this.currentTransferringProductFixedWithErc = this.eligibilityResponseView.loans
                .filter(loan => loan.selected).filter(loan => loan.ercApplicable).length > 0;
        }

        if (this.customerJourney.dealLoansResponse !== undefined) {
            this.dealLoansData = this.customerJourney.dealLoansResponse.response;
            this.productFee = this.dealLoansData.productFee > 0;
            this.retentionsFeeToggle = this.customerJourney.retentionsFeeToggle;
            this.newInterestRateChange = this.dealLoansData.loans.find(loan => loan.transferring)?.interestRateChange;
            this.newInterestRateChangeInLoanWithFee = this.checkInterestRateForLoanWithFee();
            this.singleLoanSwitch = this.dealLoansData.singleLoanSwitch;
            this.currentProductFixedTermFlag = this.dealLoansData.loans.find(loan => loan.transferring)?.currentProductFixedTermFlag;
            this.switchDate = this.fullDatePipe.transform(this.dealLoansData.switchDate);
            this.newMonthlyPayment = this.dealLoansData.monthlyPayment;

            this.loanBalanceWithFee = this.currencyPipe.transform(this.getLoanBalanceWithFee(), 'GBP');
            this.getProductFeeHeadersAndText();
            this.initialiseDataForCollapsibleTables();
        }

        this.adobeAnalyticsService.sendPageViewEvent('product-transfer-review', this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.accountStatus === "PENDING_SWITCH", true, this.isMultiLoan);
    }

    public checkInterestRateForLoanWithFee() {
        let interestRateChange = '';
        this.dealLoansData.loans.forEach(function (loan) {
            if (loan.productWithFee) {
                interestRateChange = loan.interestRateChange;
            }
        });
        return interestRateChange;
    }

    setUpNavigation() {
        this.route.queryParams.subscribe(params => {
            if (params.from !== null && params.from === 'back') {
                this.animationState = 'coming-from-left';
            }
        });
    }

    public clickBackButton(): void {
        this.animationState = 'going-to-right';
    }

    public navigate() {

        if (this.animationState === 'going-to-left') {
            this.router.navigate(['/retentions/view-offer']);
        } else if (this.animationState === 'going-to-right') {
            this.router.navigate(['/retentions/contact-details'], {queryParams: {from: 'back'}});
        }
    }

    goToSwitchConfirm() {
        if (this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.accountStatus === "PENDING_SWITCH") {
            this.modalService.open('cancelling-your-pending-deal');
            return;
        }

        if (this.customerJourney.config.inSessionFlag) {
            this.animationState = 'going-to-left';
            return;
        }

        if (this.clickedSubmit) {
            return;
        }

        this.clickedSubmit = true;
        this.isLoader = true;
        this.scrollToTop();
        this.productReviewService.submitTransferCase().subscribe(
            () => {
                this.isLoader = false;
            }
        );
    }

    scrollToTop(): void {
        window.scroll(0, 1);
    }

    getType() {
        return TransferHeaderType.REVIEW;
    }

    public getProductFeeHeadersAndText() {
        const dealsLoanResponse = this.dealLoansData;
        this.newOutstandingBalance = this.currencyPipe.transform(dealsLoanResponse.newOutstandingBalance, 'GBP');
        this.productFeeHeader = `About the £${dealsLoanResponse.productFee} product fee`;
        this.newOutstandingBalanceText = `You've chosen to add the product fee to your mortgage balance and pay interest on it for the rest of your mortgage term. Your new outstanding balance will be ${this.newOutstandingBalance}.`;
    }

    public switchDateMinusOneDay(): string {
        const switchDateElements = this.dealLoansData.switchDate.split('/');
        const switchDateMinusOneDay = new Date(+switchDateElements[2], +switchDateElements[1] - 1, +switchDateElements[0]);
        switchDateMinusOneDay.setDate(switchDateMinusOneDay.getDate() - 1); // Minus one day
        const formatted = this.datePipe.transform(switchDateMinusOneDay, 'dd/MM/yyyy');
        return this.fullDatePipe.transform(formatted);
    }

    getSubmitText() {
        return 'Continue';
    }

    public decorateProgressIndicator() {
        this.progressIndicator = [{label: 'Contact details', status: 'ok'},
            {label: 'Transfer review', status: 'second-selected'},
            {label: 'Confirmation', status: 'third'}];
    }

    public getOutstandingBalanceText() {
        const retentionsFeeToggle = this.retentionsFeeToggle;
        const productFee = this.customerJourney.hasProductFee;
        if (!retentionsFeeToggle || !productFee) {
            return 'Outstanding balance';
        } else {
            return 'New outstanding balance';
        }
    }

    public formatDealDescription(term: string, type: string): string {
        if (term.toLowerCase() === 'lifetime' && type.toLowerCase() === 'variable') {
            return 'Standard Variable Rate';
        } else {
            return `${term} ${type}`;
        }
    }

    postalOffer() {
        return !this.retentionsFeeToggle && this.selectedDeal.product.productFee > 0;
    }

    private initialiseDataForCollapsibleTables() {

        this.collapsibleData = this.dealLoansData.loans
            .map(loan => this.collapsibleTableMapper.do(loan));

        this.collapsibleDataLoanTransferring = this.dealLoansData.loans.filter(item => item.transferring === true)
            .map(loan => this.collapsibleTableMapper.do(loan));


        this.getTextForTransferring();
    }

    private getTextForTransferring() {
        this.textForTransferringLoan = this.collapsibleDataLoanTransferring.length == 1 ? 'Loan part changing today' : 'Loan parts changing today'
    }

    private getLoanBalanceWithFee(): number {
        return this.dealLoansData.loans
            .filter(loan => loan.applyFeeInThisLoan)
            .map(loanWithFee => loanWithFee.newOutstandingBalance).shift();
    }
}
