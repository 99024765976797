import {Component, ElementRef} from '@angular/core';
import {FormValidationService} from '../form-validation.service';
import {ErrorBaseComponent} from '../../validators/error-base/error-base.component';

@Component({
  selector: 'app-error-label',
  templateUrl: './error-label.component.html',
  styleUrls: ['./error-label.component.scss']
})
export class ErrorLabelComponent extends ErrorBaseComponent {
  constructor(elRef: ElementRef,
              formValidationService: FormValidationService) {
    super(elRef, formValidationService);
  }
}
