import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-continue-button',
    templateUrl: './continue-button.component.html',
    styleUrls: ['./continue-button.component.scss']
})
export class ContinueButtonComponent implements OnInit {

    @Input() continueButtonEnabled: boolean;
    @Input() continueButtonText: string;
    @Input() style: string;
    @Input() showExternalLinkIcon = false;
    @Input() accountEnteredValid: boolean;

    externalLinkIconPath: string;
    private EXTERNAL_LINK_PATH = 'assets/continue-button-external-link.svg';
    private EXTERNAL_LINK_INVERTED_PATH = 'assets/continue-button-external-link-inverted.svg';

    
    ngOnInit() {
        this.initialExternalLinkStatus();
    }


    generateClasses() {
        return {
            inverted: this.style === 'inverted',
            regular: this.style !== 'inverted',
            active: this.continueButtonEnabled,
            inactive: !this.continueButtonEnabled,
            fullColour: this.accountEnteredValid,
            halfColour: !this.accountEnteredValid
        };
    }

    public getText(): string {
        return this.continueButtonText === undefined
            ? 'Continue'
            : this.continueButtonText;
    }

    public mouseOver(): void {
        this.externalLinkIconPath = this.EXTERNAL_LINK_PATH;
    }

    public mouseOut(): void {
        this.initialExternalLinkStatus();
    }

    private initialExternalLinkStatus() {
        this.externalLinkIconPath = this.style === undefined || this.style !== 'inverted'
            ? this.EXTERNAL_LINK_PATH
            : this.EXTERNAL_LINK_INVERTED_PATH;
    }
}
