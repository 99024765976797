import {Component, Input} from '@angular/core';
import {HeaderBase} from '@components/shared/collapsible-table/types/header-base';
import {Header} from '@components/shared/collapsible-table/types/header';

@Component({
    selector: 'app-collapsible-table-header',
    templateUrl: './collapsible-table-header.component.html',
    styleUrls: ['./collapsible-table-header.component.scss']
})
export class CollapsibleTableHeaderComponent {

    @Input() qaAffix: string;
    @Input() headerElement: HeaderBase;

    public getProductTitle(): string {
        return (this.headerElement as Header).productTitle;
    }

    public getProductSubTitle(): string {
        return (this.headerElement as Header).productSubTitle;
    }

    public getValue(position: string): string {
        switch (position) {
            case 'left':
                return (this.headerElement as Header).leftValue;
            case 'center':
                return (this.headerElement as Header).centreValue;
            case 'right':
                return (this.headerElement as Header).rightValue;
            default:
                return '';
        }
    }

    public getLabel(position: string): string {
        switch (position) {
            case 'left':
                return (this.headerElement as Header).leftLabel;
            case 'center':
                return (this.headerElement as Header).centreLabel;
            case 'right':
                return (this.headerElement as Header).rightLabel;
            default:
                return '';
        }
    }

    public showHeaderValues(): boolean {
        return !((this.headerElement as Header).leftValue === '' ||
            (this.headerElement as Header).centreValue === '' ||
            (this.headerElement as Header).rightValue === '');
    }
    public showSubtitle(): boolean {
        return (this.headerElement as Header).productSubTitle !== '';
    }
}
