import {Info} from '@core/model/info';

export class RetentionsServiceDealsData {
    deals: Deal[];
    productsAvailableWithSteppedErcs: boolean;
}

export class RetentionsServiceDealsResponse {
    response: RetentionsServiceDealsData;
    info: Info;
}

export class Calculations {
    monthlyPaymentWithFee: number;
    monthlyPaymentWithoutFee: number;
    remainingBalanceWithoutFee: number;
    remainingBalanceWithFee: number;
    dealInterestWithoutFee: number;
    dealInterestWithFee: number;
    outstandingBalanceWithFee: number;
    outstandingBalanceWithoutFee: number;
    productTermCostWithFee: number;
    productTermCostWithoutFee: number;
    numberOfPayments: number;
}

export class Deal {
    calculations: Calculations;
    product: Product;
    feesToggle: boolean;
    compareCheckboxSelected: boolean;
    availableToSelect: boolean;
    loans: Loan[];
}

export class Loan {
    loanBalance: number;
    calculations: Calculations;
}

export class ErcStep {
    until: string;
    rateType: string;
    rate: number;
}

export class Product {
    chargeEndDate: string;
    endDate: string;
    productFee: number;
    type: string;
    productCode: string;
    rate: number;
    term: string;
    termInMonths: number;
    mortgageTermInMonthsAfterProduct: number;
    startDate: string;
    bankOfEnglandRate: number;
    bankOfEnglandRateDifference: number;
    ercApplicable: boolean;
    ercSteps: ErcStep[];
    annualOverpaymentAllowancePercentage: number;
    santanderRevisionaryRate: number;
    initialTermCost: number;
    reversionProduct: string;
}
