import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {ReplaySubject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SkipAuthInterceptorHeader} from '../../../../../lib/constants';

export interface AuthData {
    accessToken: string | null;
}

@Injectable()
export class AuthDataService {

    constructor(private httpClient: HttpClient) {
    }

    private previousToken = '';
    private accessTokenSubject = new ReplaySubject<string | null>(1);

    public authData$: Observable<string> = this.accessTokenSubject.asObservable();

    private revokeUrl: string;

    public revokeCurrentAccessToken() {

        if (this.previousToken !== '') {
            const headers = this.createRevokeHeaders(this.previousToken);
            const optionsRevoke = {headers: headers};
            this.httpClient.post(
                this.revokeUrl,
                null,
                optionsRevoke).subscribe();

            this.accessTokenSubject.next(null);
            this.previousToken = '';
        }
    }

    private createRevokeHeaders(tokenToRevoke: string): HttpHeaders {
        return new HttpHeaders()
            .set(SkipAuthInterceptorHeader, 'Skip-Auth-Interceptor')
            .set('Content-Type', 'application/json')
            .set('accessToken', tokenToRevoke);
    };
}
