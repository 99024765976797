import {animate, state, style, transition, trigger} from "@angular/animations";

export function CollapsibleTableAnimation() {

    return trigger('openClose', [
        state('false', style({
            height: '0px'
        })),
        state('true', style({
            // this should be a wildcard (*) but for some reason angular has a bug, if it stops working return back to wildcard
            height: '!'
        })),
        transition('true <=> false', [
            animate(300)
        ]),
    ]);
}
