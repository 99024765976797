import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-back-button',
    templateUrl: './back-button.component.html',
    styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

    private _route: string;
    private _fadeOut: boolean;
    @Input() dataQaId = '';
    imgSrc: string;

    ngOnInit() {
        this.imgSrc = 'assets/back-button.svg';
    }

    @Input() set route(value: string) {
        this._route = value;
    }

    @Input() set fadeOut(value: boolean) {
        this._fadeOut = value;
    }

    get route(): string {
        return this._route;
    }

    get fadeOut(): boolean {
        return this._fadeOut;
    }

}
