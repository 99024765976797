import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

    onIE: boolean;


    ngOnInit() {
        this.onIE = this.isIEBrowser();
    }

    private isIEBrowser(): boolean {

        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE ');

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            return true;
        } else {
            return false;
        }
    }
}
