import {Info} from '@core/model/info';

export class AccountDetailsResponse {
    data: AccountDetailsData;
    info: Info;
}

export class AccountDetailsData {
    sortCode: string;
    accountNumber: string;
    firstLineOfAddress: string;
    monthlyPayments: number;
    outstandingBalance: number;
    loans: LoanParts[];
    blockers: Blockers;
    region: string;
    consentToLet: boolean;
    minimumOverpaymentAmount: number;
    hasArrearsBalance: boolean;
    hasReceivableBalance: boolean;
    buyToLet: boolean;
    nextRegularPaymentDate: string;
    nextMonthlyPayment: number;
    secondMonthlyPayment: number;
    borrowers: Borrower[];
    numberOfOverpaymentsAlreadyDoneToday: number;
}

export class Borrower {
    name: string;
    deceased: boolean;
    emailId: string;
    mobileNumber: string;
    loggedInBorrower: boolean;
}

export class LoanParts {
    id: number;
    startDate: number;
    productDescription: string;
    productEndDate: string;
    productEndDateEpoch: number;
    interestRate: string;
    baseRate: string;
    diffRate: string;
    variableInterestRate: boolean;
    balance: number;
    remainingTerm: number;
    remainingTermPresentation: string;
    repaymentType: string;
    followOnRate: string;
    overpaymentAllowancePercentage: string;
    hasErc: boolean;
    ercAllowance: string;
    redemptionAllowanceCharge: number;
    goesToReversion: boolean;
    revisionaryProductDescription: string;
    fixedTerm: boolean;
    productType: string;
    transferWindow: TransferWindow;
    loanScheme: string;
    applicationSequenceNumber: number;
    loanType: string;
    view: View;
}

export class View {
    headerView: string;
    bodyView: string;
}

export class TransferWindow {
    productDealStatus: string;
}

export class Blockers {
    accountActive: boolean;
    equityRelease: boolean;
    pastEndOfTerm: boolean;
    flexiIndicator: boolean;
    lifeStyleFlexiMortgage: boolean;
    isleOfMan: boolean;
    linkedSecuredPersonalLoan: boolean;
    inArrears: boolean;
    allPending: boolean;
    closeToRedemptionDate: boolean;
    linkedRoc: boolean;
    multiLoan: boolean;
    moreThan4Loans: boolean;
    hasErcButNoAllowanceLeft: boolean;
    regionWAndImpendingPayment: boolean;
    deceasedBorrower: boolean;
    numberOfBorrowers: boolean;
    consentToLet: boolean;
    numberOfDailyOverpaymentsReached: boolean;

}
