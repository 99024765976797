import {Component, Input} from '@angular/core';

export interface ProgressIndicatorItem {
    label: string;
    status?: 'ok' | 'selected' | 'error' | 'second' | 'third' | 'second-selected' | 'third-selected' | null;
}

@Component({
    selector: 'app-progress-indicator',
    templateUrl: './progress-indicator.component.html',
    styleUrls: ['./progress-indicator.component.scss']
})
export class ProgressIndicatorComponent {

    @Input() items: ProgressIndicatorItem[];

    findSelected(): ProgressIndicatorItem | undefined {
        return this.items && this.items.find((item: ProgressIndicatorItem) => item.status === 'selected');
    }
}
