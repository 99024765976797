import {Component, OnInit, ViewChild, HostListener} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ViewTransition} from '@core/animations/view-transition/view-transition';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {TransferHeaderType} from '@components/views/retentions/commons/transfer-header/transfer-header-type';
import {Deal} from '@core/model/retentions-service-deals-response';
import {ProgressIndicatorItem} from '@components/shared/progress-indicator/progress-indicator.component';
import {LoadingLoanDeals} from '@components/views/retentions/things-to-know/loading-loan-deals';
import {AdobeAnalyticsService} from '@core/services/backend/analytics/adobe-analytics.service';
import { CallUsSideBarComponent } from '@components/shared/call-us-side-bar/call-us-side-bar.component';
import { CommonService } from '@core/services/common/common.service';
type AnimationStates = 'coming-from-left' | 'coming-from-right' | 'going-to-left' | 'going-to-right';

@Component({
    selector: 'app-things-to-know',
    templateUrl: './things-to-know.component.html',
    styleUrls: ['./things-to-know.component.scss'],
    animations: [ViewTransition()],
    providers: [LoadingLoanDeals]
})
export class ThingsToKnowComponent implements OnInit {

    animationState: AnimationStates = 'coming-from-right';
    selectedDeal: Deal;
    isMultiLoan = false;
    newProductFee: number;
    showPropertyValueInfo: boolean;
    excludedBands: string[] = [];

    progressIndicator: ProgressIndicatorItem[];
    multiBorrowers: boolean;

    @ViewChild('callUsSideBar')
    callUsSideBarComponent: CallUsSideBarComponent;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private customerJourney: CustomerJourney,
                private loadingLoanDeals: LoadingLoanDeals,
                private adobeAnalyticsService: AdobeAnalyticsService,
                private commonService: CommonService
    ) {
        this.selectedDeal = this.customerJourney.retentionsPageObject.selectedDeal;
    }

    @HostListener('document:click', ['$event'])
    clickout() {
        this.commonService.setHideCallusSideBarFlag(false);
        this.callUsSideBarComponent.onClick();
      }

    ngOnInit() {
        // on page load, scroll to top.
        window.scroll(0, 1);
        this.setUpNavigation();
        this.progressIndicator = [{label: 'Summary', status: 'ok'},
            {label: 'Reminder', status: 'selected'},
            {label: 'Review', status: null},
            {label: 'Confirm', status: null},
            {label: 'Mortgage Offer', status: null}];
        //TO-DO need to get the account details sent through
        // if (this.customerJourney.accountDetailsResponse.data.borrowers.length > 1) {
            this.multiBorrowers = true;
        // }

        this.loadingLoanDeals.callLoanDeals();
        this.isMultiLoan = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.length > 1;
        this.adobeAnalyticsService.sendPageViewEvent('things-to-know', this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.accountStatus === "PENDING_SWITCH", true, this.isMultiLoan);
        this.initializeExcludedBandsAndPropertyValueInfo();    
    }

    setUpNavigation() {
        this.route.queryParams.subscribe(params => {
            if (params.from !== null && params.from === 'back') {
                this.animationState = 'coming-from-left';
            }
        });
    }

    public clickBackButton(): void {
        this.animationState = 'going-to-right';
    }

    public navigate() {

        if (this.animationState === 'going-to-left') {
            this.router.navigate(['/retentions/contact-details']);
        } else if (this.animationState === 'going-to-right') {
            this.router.navigate(['/retentions/deal-details'], {queryParams: {from: 'back'}});
        }
    }

    goToProductReview() {
        this.customerJourney.retentionsPageObject.isCTA = true;
        this.animationState = 'going-to-left';
    }

    public getType(): TransferHeaderType {
        return TransferHeaderType.THINGS_TO_KNOW;
    }

    public getContactDetails() {
        if (!this.customerJourney.hasProductFee || this.customerJourney.retentionsFeeToggle) {
            return `We'll take your email address so we can send you your mortgage offer to accept`;
        } else {
            return `We'll take your email address so we can keep you up-to-date about your mortgage offer`;
        }
    }

    public isPendingSwitch() {
        const pendingProduct = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse?.eligibilityResponseView?.loans?.filter(a => a.productSwitchInfo?.pendingProductSwitch);
        this.newProductFee = pendingProduct[0]?.productSwitchInfo.newProductFee;
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.authenticationStatus.accountStatus === "PENDING_SWITCH" && this.newProductFee > 0;
    }

    public formatDealDescription(term: string, type: string): string {
        if (term.toLowerCase() === 'lifetime' && type.toLowerCase() === 'variable') {
            return 'Standard Variable Rate';
        } else {
            return `${term} ${type}`;
        }
    }

    private initializeExcludedBandsAndPropertyValueInfo() {
        // Fetch excluded bands from the config and set showPropertyValueInfo to true if the band ID is not in the excluded list
        this.excludedBands = this.customerJourney.config.excludedBands;
        this.showPropertyValueInfo = !this.excludedBands.includes(this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.bandId);
    }      

}
