import {Component} from '@angular/core';
import {CustomerJourney} from '@core/model/customer-journey.model';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalAnimation} from '@components/shared/modal/modal-animation';
import {Deal} from '@core/model/retentions-service-deals-response';
import { ProductReviewService } from '@core/services/common/product-review.service'
import { CTA_ARROW, MainButton, MainButtonStyle } from '../buttons/main-button-cta/main-button';
import { Loan } from '@core/model/customerDetailsAuthenticationResponse';
import {AdobeAnalyticsService} from '@core/services/backend/analytics/adobe-analytics.service';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    animations: [ModalAnimation()]
})
export class ModalComponent {

    type: string;
    message: string;
    minimumBalanceThreshold: number;
    accountNumber: string;
    selectedDeal: Deal;
    numberOfMonthsTransferWindow: number;
    clickedSubmit: boolean;
    pendingSwitchCount: number;
    transferringLoans: Loan[];
    isLoader: boolean = false;

    continueButton1: MainButton = {
        text: 'See deals',
        style: MainButtonStyle.CTA_PRIMARY,
        dataQaId: 'see-deals',
        ctaArrow: CTA_ARROW.RIGHT,
    };
    continueButton2: MainButton = {
        text: 'Go back',
        style: MainButtonStyle.CTA_SECONDARY,
        dataQaId: 'go-back',
        ctaArrow: CTA_ARROW.LEFT,
    };
    backToLoansButton: MainButton = {
        text: 'Go back to your loans',
        style: MainButtonStyle.CTA_PRIMARY,
        dataQaId: 'go-back-to-loans-selection',
        ctaArrow: CTA_ARROW.LEFT
    };

    constructor(
        private customerJourney: CustomerJourney,
        private router: Router,
        private ngbActiveModal: NgbActiveModal,
        private adobeAnalyticsService: AdobeAnalyticsService,
        private productReviewService: ProductReviewService) {

        this.minimumBalanceThreshold = this.customerJourney.minimumBalanceForSwitching;
        this.selectedDeal = this.customerJourney.retentionsPageObject.selectedDeal;
        this.numberOfMonthsTransferWindow = this.customerJourney.config.numberOfMonthsTransferWindow;

    }
    ngOnInit() {
        const loans = this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans;
        this.transferringLoans = loans.filter(loan => loan.eligibleToTransfer).filter(eligibleLoan => eligibleLoan.productSwitchInfo?.pendingProductSwitch);
        this.pendingSwitchCount = this.transferringLoans.length;
    }

    isMultiLoan() {
        return this.customerJourney.customerDetailsAuthenticationResponse.customerDetailsAuthenticationResponse.eligibilityResponseView.loans.length > 1;
    }

    public getSelectedMultiLoan(): string {
        if (this.customerJourney.selectedMultiLoan) {
            return 'loans';
        } else {
            return 'loan';
        }
    }


    public continueToCustomerDetailPage(): void {
        this.router.navigate(['retentions/customer-details']);
        this.closeModal();
    }

    public closeProductTransferPopup(): void {
        this.closeModal();
    }

    public closeIncorrectAttemptPopup(): void {
        this.ngbActiveModal.close();
    }

    public getEvaluatedMortgageAccount(): string {
        this.accountNumber = this.customerJourney.accountValidationResponse.accountValidationResponse.evaluatedAccountNumber;

        return this.accountNumber;
    }

    public continueToThingsToKnow() {
        this.router.navigate(['/retentions/things-to-know']);
        this.closeModal();
    }

    public closeModal(): void {
        this.ngbActiveModal.close();
    }

    public submitTransferCase() {
        if (this.clickedSubmit) {
            return;
        }

        this.clickedSubmit = true;
        this.isLoader = true;
        this.productReviewService.submitTransferCase().subscribe(
            () => {
                this.isLoader = false;
                this.closeModal();
            }
        );
    }

    public goToDealsList() {
        this.adobeAnalyticsService.sendCTAAnalyticsEvent('loan-selection-modal-see-deals-cta');
        this.router.navigate(['/retentions/deals-list']);
        this.closeModal();
    }

    public goBackButton(): void {
        this.adobeAnalyticsService.sendCTAAnalyticsEvent('loan-selection-modal-go-back-cta');
        this.ngbActiveModal.close();
    }

    public goToLoanSelection() {
        this.adobeAnalyticsService.sendCTAAnalyticsEvent('deal-details-modal-cta-go-back-to-loans-selection');
        this.router.navigate(['/retentions/loan-selection'], {queryParams: {from: 'back'}});
        this.closeModal();
    }

}
