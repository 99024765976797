import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-contact-us-body',
    templateUrl: './contact-us-body.component.html',
    styleUrls: ['./contact-us-body.component.scss']
})
export class ContactUsBodyComponent {


    @Input() header: string;
    @Input() body: string;
    @Input() qaAffix: string;


}
